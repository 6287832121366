import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';
import { Module } from './../models/module';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingMenuShowOptions } from 'src/app/shared/components/models/setting-menu-show-options';
import { ModuleService } from '../services/module.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/common-services/shared-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { Subscription } from 'rxjs';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';
import { AngularGridInstance, Column, FieldType, Formatters, GridOption } from 'angular-slickgrid';
import { ExcelExportService } from '@slickgrid-universal/excel-export';
@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements  OnInit, OnDestroy, AfterViewInit  {

	currnetUrl: any;
	updateUrl: string = '/security/module/update-module/';
	listUrl: string = '/security/module';
	toolbarPathData: ToolbarPath = {
	  listPath: this.listUrl,
	  addPath : '',
	  updatePath: this.updateUrl,
	  componentList:this.translate.instant("modules.Available-systems"),
	  componentAdd: '',

	};
	sortByCols: any[] = [];
	groupByCols: string[] = [];
	direction: string = 'ltr';
	lang = localStorage.getItem("language");
	menuOptions: SettingMenuShowOptions = {
		showDelete: false,
		showEdit: true,};
	panelId: number = 1;
	searchFilters: any;
	currentBtn!: string;
    subsList: Subscription[] = [];
     //for slick grid prepartion
     columnNames : Column []= [];
	 gridOptions!: GridOption;
	 isAutoEdit : boolean  = true;
	 updatedObject: any;
	 angularGrid!: AngularGridInstance;
	 modules: any[] = [];

  constructor(
	private translate : TranslateService,
	private moduleService: ModuleService,
    private router: Router,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
  ) { }
  ngOnDestroy() {
    this.subsList.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }
	ngAfterViewInit(): void {

	}

  ngOnInit(): void {
	this.spinner.show();
	this.sharedService.changeButton({ action: 'ListOnly' } as ToolbarData);
	this.sharedService.changeToolbarPath(this.toolbarPathData);
	this.listenToClickedButton();
	Promise.all([this.prepareGird()])
      .then(a => {
		  this.spinner.hide();
		 }).catch(err => {
        this.spinner.hide();
      })
  }

  prepareGird()
  {
	this.gridOptions = {
		asyncEditorLoading: true,
		autoEdit: this.isAutoEdit,
		autoCommitEdit: true,
		autoResize: {
		  container: '#editorGrid',
		  rightPadding: 25
		},
		createPreHeaderPanel: true,
		showPreHeaderPanel: true,
		preHeaderPanelHeight: 40,
		cellHighlightCssClass: 'changed',
		gridWidth: "auto",
		editable: true,
		enableCellNavigation: true,
		enableColumnPicker: true,
		enableFiltering: true,
		enableExcelExport: true,
		enableExcelCopyBuffer: true,
		excelExportOptions: {
				  exportWithFormatter: true,
				  customColumnWidth: 20,
				  // you can customize how the header titles will be styled (defaults to Bold)
				  columnHeaderStyle: { font: { bold: true, italic: true } }
			  },

		registerExternalResources: [new ExcelExportService()],
		enableTranslate: true,
		i18n:this.translate,

	  };

	 this.getModules()




  }


  getModules()
  {
	return new Promise<void>((resolve, reject) => {
		let name = this.translate.instant("modules.name")
		let edit = this.translate.instant("modules.edit")
		let MonthlySubscriptionPrice = this.translate.instant("modules.MonthlySubscriptionPrice")
		let yearlyQuarterSubscriptionPrice = this.translate.instant("modules.YearlyQuarterSubscriptionPrice")
		let otherUserYearlyQuarterSubscriptionPrice = this.translate.instant("modules.OtherUserYearlyQuarterSubscriptionPrice")
		let yearlyHalfSubscriptionPrice = this.translate.instant("modules.YearlyHalfSubscriptionPrice")
		let otherUserYearlyHalfSubscriptionPrice = this.translate.instant("modules.otherUserYearlyHalfSubscriptionPrice")

		let YearlySubscriptionPrice = this.translate.instant("modules.YearlySubscriptionPrice")
		let FullBuyingSubscriptionPrice = this.translate.instant("modules.FullBuyingSubscriptionPrice")
		let OtherUserMonthlySubscriptionPrice = this.translate.instant("modules.OtherUserMonthlySubscriptionPrice")
		let OtherUserYearlySubscriptionPrice = this.translate.instant("modules.OtherUserYearlySubscriptionPrice")
		let OtherUserFullBuyingSubscriptionPrice = this.translate.instant("modules.OtherUserFullBuyingSubscriptionPrice")

		let sub = this.moduleService.allModules(undefined, undefined, undefined, undefined, undefined).subscribe({
		  next: (res) => {

			this.toolbarPathData.componentList =this.translate.instant("modules.Available-systems");
			if (res.success) {
			  this.modules = res.response;

			  for (let index = 0; index < this.modules.length; index++) {
				this.modules[index].code= index+1;

			  }
			}

			 //if the modules list has values
			if(this.modules.length > 0)
				{
					this.columnNames = [
						{
						id: "id",
						name: "#",
						field:"code",
						filterable: false,
						sortable: false,
						type: FieldType.string,
						formatter : Formatters.center,
						minWidth: 15,
						maxWidth:50
						},
						{
							id: this.lang == 'ar' ? "nameAr" : "nameEn",
							name: name,
							field:this.lang == 'ar' ? "nameAr" : "nameEn",
							filterable: true,
							sortable: true,
							type: FieldType.string,
							formatter : Formatters.center,
							minWidth: 100,
							maxWidth :175
						},

						]

					//for the monthly subscription
					if(this.modules[0].monthlySubscriptionPrice != null )
						{
							this.columnNames.push({
							id: "monthlySubscriptionPrice",
							name: MonthlySubscriptionPrice,
							field:"monthlySubscriptionPrice",
							filterable: true,
							sortable: true,
							type: FieldType.float,
							formatter : Formatters.center,
							minWidth: 100,
							maxWidth :175

							})
							this.columnNames.push({
								id: "otherUserMonthlySubscriptionPrice",
								name: OtherUserMonthlySubscriptionPrice,
								field:"otherUserMonthlySubscriptionPrice",
								filterable: true,
								sortable: true,
								type: FieldType.float,
								formatter : Formatters.center,
								minWidth: 175,
								maxWidth :175
							})
						}

					//for the quarter subscription
					if(this.modules[0].yearlyQuarterSubscriptionPrice != null)
						{
							this.columnNames.push({
							id: "yearlyQuarterSubscriptionPrice",
							name: yearlyQuarterSubscriptionPrice,
							field:"yearlyQuarterSubscriptionPrice",
							filterable: true,
							sortable: true,
							type: FieldType.float,
							formatter : Formatters.center,
							minWidth: 100,
							maxWidth :175

							})
							this.columnNames.push({
								id: "otherUserYearlyQuarterSubscriptionPrice",
								name: otherUserYearlyQuarterSubscriptionPrice,
								field:"otherUserYearlyQuarterSubscriptionPrice",
								filterable: true,
								sortable: true,
								type: FieldType.float,
								formatter : Formatters.center,
								minWidth: 175,
								maxWidth :175
							})
						}

					//for the half subscription
					if(this.modules[0].yearlyHalfSubscriptionPrice != null)
						{
							this.columnNames.push({
							id: "yearlyHalfSubscriptionPrice",
							name: yearlyHalfSubscriptionPrice,
							field:"yearlyHalfSubscriptionPrice",
							filterable: true,
							sortable: true,
							type: FieldType.float,
							formatter : Formatters.center,
							minWidth: 100,
							maxWidth :175

							})
							this.columnNames.push({
								id: "otherUserYearlyHalfSubscriptionPrice",
								name: otherUserYearlyHalfSubscriptionPrice,
								field:"otherUserYearlyHalfSubscriptionPrice",
								filterable: true,
								sortable: true,
								type: FieldType.float,
								formatter : Formatters.center,
								minWidth: 175,
								maxWidth :175
							})
						}

				//for the yearly subscription
					if(this.modules[0].yearlySubscriptionPrice != null)
						{
							this.columnNames.push({
								id: "yearlySubscriptionPrice",
								name: YearlySubscriptionPrice,
								field:"yearlySubscriptionPrice",
								filterable: true,
								sortable: true,
								type: FieldType.float,
								formatter : Formatters.center,
								minWidth: 100,
								maxWidth :175
							})
							this.columnNames.push({
								id: "otherUserYearlySubscriptionPrice",
								name: OtherUserYearlySubscriptionPrice,
								field:"otherUserYearlySubscriptionPrice",
								filterable: true,
								sortable: true,
								type: FieldType.float,
								formatter : Formatters.center,
								minWidth: 175,
								maxWidth :175
							})
						}

					//for the full subscription
					if(this.modules[0].fullBuyingSubscriptionPrice != null)
						{
							this.columnNames.push({
								id: "fullBuyingSubscriptionPrice",
								name: FullBuyingSubscriptionPrice,
								field:"fullBuyingSubscriptionPrice",
								filterable: true,
								sortable: true,
								type: FieldType.float,
								formatter : Formatters.center,
								minWidth: 100,
								maxWidth :175

							})
							this.columnNames.push({
								id: "otherUserFullBuyingSubscriptionPrice",
								name: OtherUserFullBuyingSubscriptionPrice,
								field:"otherUserFullBuyingSubscriptionPrice",
								filterable: true,
								sortable: true,
								type: FieldType.float,
								formatter : Formatters.center,
								minWidth: 100,
								maxWidth :175

							})
						}


					// for edit the row
						this.columnNames.push(  {
							id: "edit",
							name: edit,
							field:"id",
							filterable: false,
							sortable: false,
							type: FieldType.string,
							formatter : Formatters.editIcon,
							cssClass:'text-center',
							minWidth: 25,
							maxWidth:50
						});


				}

			else
			{
				this.columnNames = [
				{
				id: "id",
				name: "#",
				field:"code",
				filterable: false,
				sortable: false,
				type: FieldType.string,
				formatter : Formatters.center,
				minWidth: 15,
				maxWidth:50
				},
				{
					id: this.lang == 'ar' ? "nameAr" : "nameEn",
					name: name,
					field:this.lang == 'ar' ? "nameAr" : "nameEn",
					filterable: true,
					sortable: true,
					type: FieldType.string,
					formatter : Formatters.center,
					minWidth: 100,
					maxWidth :175
				},
				{
					id: "monthlySubscriptionPrice",
					name: MonthlySubscriptionPrice,
					field:"monthlySubscriptionPrice",
					filterable: true,
					sortable: true,
					type: FieldType.float,
					formatter : Formatters.center,
					minWidth: 100,
					maxWidth :175
				},
				{
					id: "yearlySubscriptionPrice",
					name: YearlySubscriptionPrice,
					field:"yearlySubscriptionPrice",
					filterable: true,
					sortable: true,
					type: FieldType.float,
					formatter : Formatters.center,
					minWidth: 100,
					maxWidth :175
				},
				{
					id: "fullBuyingSubscriptionPrice",
					name: FullBuyingSubscriptionPrice,
					field:"fullBuyingSubscriptionPrice",
					filterable: true,
					sortable: true,
					type: FieldType.float,
					formatter : Formatters.center,
					minWidth: 100,
					maxWidth :175
				},
				{
					id: "otherUserMonthlySubscriptionPrice",
					name: OtherUserMonthlySubscriptionPrice,
					field:"otherUserMonthlySubscriptionPrice",
					filterable: true,
					sortable: true,
					type: FieldType.float,
					formatter : Formatters.center,
					minWidth: 175,
					maxWidth :175
				},
				{
					id: "otherUserYearlySubscriptionPrice",
					name: OtherUserYearlySubscriptionPrice,
					field:"otherUserYearlySubscriptionPrice",
					filterable: true,
					sortable: true,
					type: FieldType.float,
					formatter : Formatters.center,
					minWidth: 175,
					maxWidth :175
				},
				{
					id: "otherUserFullBuyingSubscriptionPrice",
					name: OtherUserFullBuyingSubscriptionPrice,
					field:"otherUserFullBuyingSubscriptionPrice",
					filterable: true,
					sortable: true,
					type: FieldType.float,
					formatter : Formatters.center,
					minWidth: 100,
					maxWidth :175
				},
				{
					id: "edit",
					name: edit,
					field:"id",
					filterable: false,
					sortable: false,
					type: FieldType.string,
					formatter : Formatters.editIcon,
					cssClass:'text-center',
					minWidth: 25,
					maxWidth:50
				},
				]

			}

			resolve();
		  },
		  error: (err: any) => {
			reject(err);
		  },
		  complete: () => {
			console.log('complete');
		  },
		});
		this.subsList.push(sub);
	  });

  }

  listenToClickedButton()
  {

	let sub = this.sharedService.getClickedbutton().subscribe({
		next: (currentBtn: ToolbarData) => {
		  //currentBtn;
		  if (currentBtn != null) {
			if (currentBtn.action == ToolbarActions.List) {

			} else if (currentBtn.action == ToolbarActions.Update) {
			  this.router.navigate([this.updateUrl]);
			}
		  }
		},
	  });
	  this.subsList.push(sub);
  }


  onCheck(event : any)
  {

  }

  onEdit(event : any)
  {

    if (event != undefined) {
		this.edit(event);
		this.sharedService.changeButton({
		  action: 'Update',
		  componentName: 'List',
		  submitMode: false
		} as ToolbarData);

		this.sharedService.changeToolbarPath(this.toolbarPathData);
		this.router.navigate(['/security/modules/edit-module' + event]
		)
	  }
  }

  edit(id: string) {
    this.router.navigate([
      '/security/modules/edit-module',
      id,
    ]);
  }

  showConfirmDeleteMessage(event : any)
  {

  }

  editRow(e:any , args : any)
  {
			let id = this.modules[args.row].id+'' as string;
			this.edit(id);

  }

  onSearchTextChange(searchTxt: string) {
    this.searchFilters = [
      [
        { field: 'nameEn', type: 'like', value: searchTxt },
        { field: 'nameAr', type: 'like', value: searchTxt },
        { field: 'code', type: 'like', value: searchTxt },
        ,
      ],
    ];
  }

  angularGridReady(angularGrid: AngularGridInstance) {
	this.angularGrid = angularGrid;
	//this.gridObj = angularGrid.slickGrid;
  }

}
