<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading'|translate}}... </p>
</ngx-spinner>
<section class="inner-page-wrapper inner-page-wrapper-units">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-3">
					<div class="card-body">
						<form [formGroup]="subscriptionTypeForm" id="BusinessSubmit" class="tabs-form">
							<div class="main-customer">
								<div class="wrapper-title">
									<h3>{{'subscription-type.subscription-types'|translate}}</h3>
									<hr>
									<div class="row align-items-center">
										<div class="col-md-6 col-12">
											<label for="nameAr" class="label-name">
												{{'general.nameAr'|translate}}</label><span class="text-danger">*</span>
											<div class="form-group position-relative">
 												<input type="text" class="form-control icon-user"
													[ngClass]="{'is-invalid':f['nameAr'].errors || f['nameAr'].invalid}"
													name="nameAr" formControlName="nameAr" disabled>
												<div *ngIf="(f['nameAr'].touched || f['nameAr'].dirty ) && f['nameAr'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['nameAr'].errors && f['nameAr'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12">
											<label for="nameEn"
												class="label-name">{{'general.nameEn'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="nameEn"
												[ngClass]="{'is-invalid':f['nameEn'].errors || f['nameEn'].invalid}"
												formControlName="nameEn" disabled>
												<div *ngIf="(f['nameEn'].touched || f['nameEn'].dirty ) && f['nameEn'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['nameEn'].errors && f['nameEn'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12">
											<label for="period"
												class="label-name">{{'subscription-type.period'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">
												<input type="text" class="form-control icon-user" name="period"
												[ngClass]="{'is-invalid':f['period'].errors || f['period'].invalid}"
												formControlName="period" disabled>
												<div *ngIf="(f['period'].touched || f['period'].dirty ) && f['period'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['period'].errors && f['period'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>
											</div>
										</div>

										<div class="col-md-6 col-12 d-flex align-items-end">
											<div class="media">
												<label class="label-name"> {{'general.status'|translate}}
												</label>
												<div class="icon-state media-body mx-5">
													<label class="mx-2 switch">
														<input type="checkbox" class="form-control" name="isActive"
															formControlName="isActive">
														<span class="switch-state"></span>
													</label>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</form>

					</div>
				</div>
			</div>
		</div>

		<!--
        <div id="ex-table-div"></div> -->

	</div>
</section>
