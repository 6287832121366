import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../shared/common-services/user.service';
import { RegisterService } from './services/register.service';

@Component({
	selector: 'kt-landing-page',
	templateUrl: './landing-page.component.html',
	styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
	isLogin: boolean= false;
	isLoggedIn : boolean = false;
	userName : string = '';
	constructor(
		private router: Router,
		private translate: TranslateService,
		private loggedUser : UserService,
		private registerService : RegisterService
	) {

		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
			  if (event.url === '/landing-page/login-customer') {
				this.isLogin= true;
			  } else {
				this.isLogin= false;
			  }
			}
		  });

		//   this.registerService.getIsLoggedIn.subscribe(
		// 	(res)=>
		// 	{	if(res== true )
		// 		{
		// 			this.isLoggedIn =  true
		// 			this.userName = this.loggedUser.getUserName();
		// 		}})

		  translate.setDefaultLang('ar');
		  translate.use('ar');

	}

	ngOnInit() {
		// this.router.events.subscribe((event: any) => {
		// 	if (event instanceof NavigationEnd) {
		// 	  if (event.url === '/landing-page/login-customer') {
		// 		this.isLogin= true;
		// 	  } else {
		// 		this.isLogin= false;
		// 	  }
		// 	}
		//   });

		  //debugger;
        // this.registerService.getIsLoggedIn.subscribe(
		// 	(res)=>
		// 	{	if(res== true )
		// 		{
		// 			this.isLoggedIn =  true
		// 			this.userName = this.loggedUser.getUserName();
		// 		}})
	}

}
