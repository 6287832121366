import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../../shared/common-services/shared-service';
import { ToolbarPath } from '../../../../shared/interfaces/toolbar-path';
import { CustomerServiceProxy } from '../../services/customer-service'
import { ToolbarData } from '../../../../shared/interfaces/toolbar-data';
import { Subscription } from 'rxjs';
import { MessageModalComponent } from '../../../../shared/components/message-modal/message-modal.component'
import { SettingMenuShowOptions } from '../../../../shared/components/models/setting-menu-show-options';
import { ToolbarActions } from '../../../../shared/enum/toolbar-actions'
import { NgxSpinnerService } from 'ngx-spinner';
import { DeleteListCustomerCommand, ICustomerDto } from '../../models/customer';
import { AngularGridInstance, Column, GridOption } from 'angular-slickgrid';
@Component({
	selector: 'app-customers-list',
	templateUrl: './customers-list.component.html',
	styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent implements OnInit, OnDestroy, AfterViewInit {
	angularGrid!: AngularGridInstance;
	gridOptions!: GridOption;
	columnDefinitions: Column[] = [];
	dataset: any[] = [];
	isMobile: any;
	defineGrid() {
		// the columns field property is type-safe, try to add a different string not representing one of DataItems properties
		this.columnDefinitions = [
			{ id: 'code', name: this.lang == 'ar' ? 'الكود' : 'Code', field: 'code', filterable: true, sortable: true, minWidth: 65 },
			{
				id: 'nameAr', name: this.lang == 'ar' ? 'الاسم العربى' : 'Name In Arabic', field: 'nameAr', filterable: true, sortable: true, minWidth: 65
			},
			{
				id: 'nameEn', name: this.lang == 'ar' ? 'الاسم الإنجليزي' : 'Name In English', field: 'nameEn', filterable: true, sortable: true, minWidth: 65
			},
			{
				id: 'fullName', name: this.lang == 'ar' ? ' اسم المستخدم ' : 'User Name', field: 'fullName', filterable: true, sortable: true, minWidth: 65
			},
			{
				id: 'email', name: this.lang == 'ar' ? ' البريد الالكتروني ' : 'Email ', field: 'email', filterable: true, sortable: true, minWidth: 65
			},

			{
				id: 'phoneNumber', name: this.lang == 'ar' ? ' رقم الجوال ' : 'Phone Number ', field: 'phoneNumber', filterable: true, sortable: true, minWidth: 65
			},

		]
		if (this.isMobile) {
			if (this.lang == 'ar') {
				this.columnDefinitions = this.columnDefinitions.filter(column => column.id !== 'nameEn');
			}
			else {
				this.columnDefinitions = this.columnDefinitions.filter(column => column.id !== 'nameAr');
			}
		}
	}
	//#region Main Declarations
	customer: ICustomerDto[] = [];
	currnetUrl: any;
	addUrl: string = '/security/customer/add-customer';
	updateUrl: string = '/security/customer/update-customer/';
	listUrl: string = '/security/customer';
	toolbarPathData: ToolbarPath = {
		listPath: '',
		updatePath: this.updateUrl,
		addPath: this.addUrl,
		componentList: this.translate.instant("component-names.customer"),
		componentAdd: '',

	};
	//#endregion

	//#region Constructor
	constructor(
		private customerService: CustomerServiceProxy,
		private router: Router,
		private spinner: NgxSpinnerService,
		private sharedServices: SharedService, private translate: TranslateService,
		private modalService: NgbModal) {

	}


	//#endregion

	//#region ngOnInit
	ngOnInit(): void {
		this.spinner.show();
		this.defineGrid();
		this.spinner.show();
	
		Promise.all([this.getCustomers()])
			.then(a => {
				this.spinner.hide();
				this.sharedServices.changeButton({ action: 'List' } as ToolbarData);
				this.sharedServices.changeToolbarPath(this.toolbarPathData);
				this.listenToClickedButton();
			}).catch(err => {
				this.spinner.hide();
			})
	}

	ngAfterViewInit(): void {

	}


	//#endregion

	//#region ngOnDestroy
	ngOnDestroy() {
		this.subsList.forEach((s) => {
			if (s) {
				s.unsubscribe();
			}
		});
	}
	//#endregion

	//#region Authentications

	//#endregion

	//#region Permissions

	//#endregion

	//#region  State Management
	//#endregion

	//#region Basic Data
	///Geting form dropdown list data
	getCustomers() {
		return new Promise<void>((resolve, reject) => {
			let sub = this.customerService.allCustomers(undefined, undefined, undefined, undefined, undefined).subscribe({
				next: (res) => {

					this.toolbarPathData.componentList = this.translate.instant("component-names.customer");
					if (res.success) {
						this.customer = res.response?.items;
					}


					resolve();

				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});

			this.subsList.push(sub);
		});

	}

	//#endregion

	//#region CRUD Operations
	delete(id: any) {
		this.customerService.deleteCustomer(id).subscribe((resonse) => {
			console.log('delet response', resonse);
			this.getCustomers();
		});
	}
	edit(id: string) {
		this.router.navigate([
			'/security/customer/update-customer',
			id,
		]);
	}

	//#endregion



	showConfirmDeleteMessage(id) {
		const modalRef = this.modalService.open(MessageModalComponent);
		modalRef.result.then((rs) => {
			console.log(rs);
			if (rs == 'Confirm') {
				this.spinner.show();
				let sub = this.customerService.deleteCustomer(id).subscribe(
					() => {
						//reloadPage()
						this.getCustomers();

					});
				this.subsList.push(sub);
				this.spinner.hide();

			}
		});
	}
	//#endregion
	//#region Tabulator

	panelId: number = 1;
	sortByCols: any[] = [];
	searchFilters: any;
	groupByCols: string[] = [];
	lang = localStorage.getItem("language");
	columnNames = [
		{
			title: this.lang == 'ar' ? ' الكود' : 'code ',
			field: 'code',
		},
		this.lang == 'ar'
			? { title: ' الاسم', field: 'nameAr' } : { title: ' Name  ', field: 'nameEn' },


		{
			title: this.lang == 'ar' ? ' البريد الالكتروني' : 'Email ',
			field: 'email',
		},
		// {
		//   title: this.lang == 'ar' ? ' اسم المستخدم ' : 'Customer Name ',
		//   field: 'fullName',
		// },
		{
			title: this.lang == 'ar' ? ' رقم الجوال  ' : 'Phone Number ',
			field: 'phoneNumber',
		},
	];

	menuOptions: SettingMenuShowOptions = {
		showDelete: true,
		showEdit: true,
	};

	direction: string = 'ltr';

	onSearchTextChange(searchTxt: string) {
		this.searchFilters = [
			[
				{ field: 'nameEn', type: 'like', value: searchTxt },
				{ field: 'nameAr', type: 'like', value: searchTxt },
				{ field: 'code', type: 'like', value: searchTxt },
				{ field: 'phoneNumber', type: 'like', value: searchTxt },
				{ field: 'email', type: 'like', value: searchTxt },
				,
			],
		];
	}

	openCustomerss() { }
	onEdit(id) {

		if (id != undefined) {
			this.edit(id);
			this.sharedServices.changeButton({
				action: 'Update',
				componentName: 'List',
				submitMode: false
			} as ToolbarData);

			this.sharedServices.changeToolbarPath(this.toolbarPathData);
			this.router.navigate(['security/customer/update-customer/' + id]
			)
		}

	}

	//#endregion



	//#region Toolbar Service
	currentBtn!: string;
	subsList: Subscription[] = [];
	listenToClickedButton() {

		let sub = this.sharedServices.getClickedbutton().subscribe({
			next: (currentBtn: ToolbarData) => {
				//currentBtn;
				if (currentBtn != null) {
					if (currentBtn.action == ToolbarActions.List) {

					} else if (currentBtn.action == ToolbarActions.New) {
						this.router.navigate([this.addUrl]);
					}
					else if (currentBtn.action == ToolbarActions.DeleteCheckList) {
						this.onDelete();
					}
				}
			},
		});
		this.subsList.push(sub);
	}
	onCheck(id) {


		this.listIds.push(id);
		this.sharedServices.changeButton({
			action: 'Delete',
			componentName: 'List',
			submitMode: false
		} as ToolbarData);
	}
	listIds: any[] = [];
	onDelete() {

		let item = new DeleteListCustomerCommand();
		item.ids = this.listIds;
		let sub = this.customerService.deleteListCustomer(item).subscribe(
			(resonse) => {

				//reloadPage()
				this.getCustomers();
				this.listIds = [];
			});
		this.subsList.push(sub);
	}
	//#endregion
}

