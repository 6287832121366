import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ModuleService } from '../services/module.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SettingMenuShowOptions } from 'src/app/shared/components/models/setting-menu-show-options';
import { TranslateService } from '@ngx-translate/core';
import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';
import { SharedService } from 'src/app/shared/common-services/shared-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-order-modules',
  templateUrl: './order-modules.component.html',
  styleUrls: ['./order-modules.component.scss']
})
export class OrderModulesComponent implements OnInit , OnDestroy, AfterViewInit  {

	currnetUrl: any;
	updateUrl: string = '/security/module/update-module/';
	listUrl: string = '/security/module';
	toolbarPathData: ToolbarPath = {
	  listPath: '',
	  addPath : '',
	  updatePath: this.updateUrl,
	  componentList:this.translate.instant("modules.Available-systems"),
	  componentAdd: '',

	};

    subsList: Subscription[] = [];
	sortByCols: any[] = [];
	groupByCols: string[] = [];
	direction: string = 'ltr';
	lang = localStorage.getItem("language");
	menuOptions: SettingMenuShowOptions = {
		showDelete: false,
		showEdit: true,};
	panelId: number = 1;
	searchFilters: any;
	currentBtn!: string;
	 modules: any[] = [];

  constructor(private moduleService : ModuleService ,
	        private translate : TranslateService,
			private spinner: NgxSpinnerService,
			private sharedService: SharedService,
			private taoastService : ToastrService
  ) { }
  ngOnDestroy() {
    this.subsList.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }
	ngAfterViewInit(): void {

	}



  ngOnInit(): void {
	this.spinner.show();
	this.sharedService.changeButton({ action: 'EditOnly' } as ToolbarData);
	this.sharedService.changeToolbarPath(this.toolbarPathData);
	this.listenToClickedButton();
	let sub = this.moduleService.allModules(null , null , null ,null , null)
	.subscribe(
		(res)=> {
			this.modules = res.response;
			this.modules =  this.modules.sort((a, b) => a.order - b.order)
			this.spinner.hide();
		}
	)

	this.subsList.push(sub)
  }


  listenToClickedButton()
  {

	let sub = this.sharedService.getClickedbutton().subscribe({
		next: (currentBtn: ToolbarData) => {
		  //currentBtn;
		  if (currentBtn != null) {
			if (currentBtn.action == ToolbarActions.List) {

			} else if (currentBtn.action == ToolbarActions.Update) {
			    this.updateList()
			}
		  }
		},
	  });
	  this.subsList.push(sub);
  }

  updateList()
  {
	    let body = [];
		this.modules.forEach(element=>{
			body.push({
				id : element.id ,
				inputDto : element
			})
		})
		let sub = this.moduleService.updateOrderModule(body).subscribe(
			(res)=>{
				if(res.response == true )
				{
					this.taoastService.success(this.translate.instant("editSuccessfully"))
				}
		}
		);
  }

  drop(event: CdkDragDrop<any>) {

	debugger;

	moveItemInArray(this.modules, event.previousIndex, event.currentIndex);
	for (let index = event.currentIndex; index < this.modules.length; index++) {
		this.modules[index].order = index+1;

	}



	console.log(this.modules);
  }
}
