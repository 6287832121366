import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class SubscriptionTypeService {

	private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = environment.apiUrl
    }


    updateSubscriptionType(id : any): Observable<any> {
        return this.http.post<any>(environment.apiUrl + "/api/SubscriptionType/updateSubscriptionType",id);
    }


    getAllSubscriptionTypes(): Observable<any> {

        return this.http.get<any>(this.baseUrl + "/api/SubscriptionType/getAllSubscriptionType");
    }


	getSubscriptionType(id : number): Observable<any> {

        return this.http.get<any>(this.baseUrl + "/api/SubscriptionType/getSubscriptionType/"+id);
    }



}
