import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SlickGridComponent } from './slick-grid/slick-grid.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularSlickgridModule } from 'angular-slickgrid';

@NgModule({
    declarations: [SlickGridComponent],
    imports: [FormsModule, CommonModule,TranslateModule,NgxSpinnerModule,
        AngularSlickgridModule.forRoot({
            // add any Global Grid Options/Config you might want
            // to avoid passing the same options over and over in each grids of your App
            enableAutoResize: true,
            autoResize: {
                container: '#demo-container',
                rightPadding: 10
            }
        })
    ],
    exports: [SlickGridComponent]
})
export class SlickGridModule {

}