import { Component, OnInit } from '@angular/core';
import { AngularGridInstance, Column, FieldType, Formatters, GridOption } from 'angular-slickgrid';
import { Subscription } from 'rxjs';
import { SettingMenuShowOptions } from 'src/app/shared/components/models/setting-menu-show-options';
import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';
import { PromoCode } from '../../models/promo-code';
import { TranslateService } from '@ngx-translate/core';
import { PromoCodeService } from '../../services/promo-code.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/common-services/shared-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { ExcelExportService } from '@slickgrid-universal/excel-export';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';

@Component({
  selector: 'app-promo-code-list',
  templateUrl: './promo-code-list.component.html',
  styleUrls: ['./promo-code-list.component.scss']
})
export class PromoCodeListComponent implements OnInit {

	currnetUrl: any;
	addUrl: string = '/security/promo-code/add-promo-code';
	updateUrl: string = '/security/promo-code/edit-promo-code/';
	listUrl: string = '/security/promo-code';
	toolbarPathData: ToolbarPath = {
	  listPath: this.addUrl,
	  addPath : this.addUrl,
	  updatePath: this.updateUrl,
	  componentList:this.translate.instant("component-names.promo-code"),
	  componentAdd: '',

	};
	sortByCols: any[] = [];
	groupByCols: string[] = [];
	direction: string = 'ltr';
	lang = localStorage.getItem("language");
	menuOptions: SettingMenuShowOptions = {
		showDelete: false,
		showEdit: true,};
	panelId: number = 1;
	searchFilters: any;
	currentBtn!: string;
    subsList: Subscription[] = [];
     //for slick grid prepartion
     columnNames : Column []= [];
	 gridOptions!: GridOption;
	 isAutoEdit : boolean  = true;
	 updatedObject: any;
	 angularGrid: AngularGridInstance;
	 promoCodes: any[] = [];

  constructor(
	private translate : TranslateService,
	private promoCodeService: PromoCodeService,
    private router: Router,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
  ) { }


	ngOnDestroy() {
		this.subsList.forEach((s) => {
		if (s) {
			s.unsubscribe();
		}
		});
	}
	ngAfterViewInit(): void {

	}

  ngOnInit(): void {
	this.spinner.show();
	this.prepareGird();
	this.sharedService.changeButton({ action: 'List' } as ToolbarData);
	this.sharedService.changeToolbarPath(this.toolbarPathData);
	this.listenToClickedButton();
	Promise.all([this.getModules()])
      .then(a => {

        this.spinner.hide();

      }).catch(err => {
        this.spinner.hide();
      })
  }

  prepareGird()
  {
	let deletebtn = this.translate.instant("buttons.delete");
    let name = this.translate.instant("modules.name")
	let promocode = this.translate.instant("PromoCode.promo-code")
	let discountpercentage = this.translate.instant("PromoCode.discount-percentage")
    let edit = this.translate.instant("buttons.edit");

	this.gridOptions = {
		asyncEditorLoading: true,
		autoEdit: this.isAutoEdit,
		autoCommitEdit: true,
		autoResize: {
		  container: '#demo-container',
		  rightPadding: 25
		},
		createPreHeaderPanel: true,
		showPreHeaderPanel: true,
		preHeaderPanelHeight: 40,
		cellHighlightCssClass: 'changed',
		gridWidth: "auto",
		editable: true,
		enableCellNavigation: true,
		enableColumnPicker: true,
		enableFiltering: true,
		enableExcelExport: true,
		enableExcelCopyBuffer: true,
		excelExportOptions: {
				  exportWithFormatter: true,
				  customColumnWidth: 20,
				  // you can customize how the header titles will be styled (defaults to Bold)
				  columnHeaderStyle: { font: { bold: true, italic: true } }
			  },

		registerExternalResources: [new ExcelExportService()],
		enableTranslate: true,
		i18n:this.translate,

	  };

	   this.columnNames = [
		{
		   id: "id",
		   name: "#",
		   field:"code",
		   filterable: false,
		   sortable: false,
		   type: FieldType.string,
		   formatter : Formatters.center,
		   minWidth: 15,
		 },
		 {
			id: this.lang == 'ar' ? "nameAr" : "nameEn",
			name: name,
			field:this.lang == 'ar' ? "nameAr" : "nameEn",
			filterable: true,
			sortable: true,
			type: FieldType.string,
			formatter : Formatters.center,
			minWidth: 175,
		  },
		  {
			id: "promoCode",
			name: promocode,
			field:"promoCode",
			filterable: true,
			sortable: true,
			type: FieldType.string,
			formatter : Formatters.center,
			minWidth: 175,
		  },
		  {
			id: "discountPercentage",
			name: discountpercentage,
			field:"discountPercentage",
			filterable: true,
			sortable: true,
			type: FieldType.float,
			formatter : Formatters.center,
			minWidth: 175,
		  },
		  {
			id: "edit",
			name: edit,
			field:"id",
			filterable: false,
			sortable: false,
			type: FieldType.string,
			formatter : Formatters.editIcon,
			minWidth: 15,
		  },
		  {
			id: "delete",
			name: deletebtn,
			field:"id",
			filterable: false,
			sortable: false,
			type: FieldType.string,
			formatter : Formatters.deleteIcon,
			minWidth: 15,
		  },
	 ]


  }


  getModules()
  {
	return new Promise<void>((resolve, reject) => {
		let sub = this.promoCodeService.allPromoCodes(undefined, undefined, undefined, undefined, undefined).subscribe({
		  next: (res) => {

			this.toolbarPathData.componentList =this.translate.instant("component-names.promo-code");
			if (res.success) {
			  this.promoCodes = res.response.items;
			  for (let index = 0; index < this.promoCodes.length; index++) {
				this.promoCodes[index].code = index+1;
			  }
			}
			resolve();
		  },
		  error: (err: any) => {
			reject(err);
		  },
		  complete: () => {
			console.log('complete');
		  },
		});
		this.subsList.push(sub);
	  });

  }

  listenToClickedButton()
  {

	let sub = this.sharedService.getClickedbutton().subscribe({
		next: (currentBtn: ToolbarData) => {
		  //currentBtn;
		  if (currentBtn != null) {
			if (currentBtn.action == ToolbarActions.List) {

			} else if (currentBtn.action == ToolbarActions.Update) {
			  this.router.navigate([this.updateUrl]);
			}

			else if (currentBtn.action == ToolbarActions.New) {
				this.router.navigate([this.addUrl]);
			  }
		  }
		},
	  });
	  this.subsList.push(sub);
  }


  onCheck(event : any)
  {

  }

  onEdit(event : any)
  {

    if (event != undefined) {
		this.edit(event);
		this.sharedService.changeButton({
		  action: 'Update',
		  componentName: 'List',
		  submitMode: false
		} as ToolbarData);

		this.sharedService.changeToolbarPath(this.toolbarPathData);
		this.router.navigate(['/security/promo-code/edit-promo-code' + event]
		)
	  }
  }

  edit(id: string) {
	
	this.sharedService.changeButton({
		action: "Update",
		componentName: "List",
		submitMode: false,
	} as ToolbarData);

    //this.router.navigate(['/security/promo-code/edit-promo-code/'+id]);
	this.router.navigate(['/security/promo-code/edit-promo-code/',id,]);
  }

  showConfirmDeleteMessage(event : any)
  {

  }

  onSearchTextChange(searchTxt: string) {
    this.searchFilters = [
      [
        { field: 'nameEn', type: 'like', value: searchTxt },
        { field: 'nameAr', type: 'like', value: searchTxt },
        { field: 'promoCode', type: 'like', value: searchTxt },
	    ,
      ],
    ];
  }

  click(e:any , args : any)
  {
	;
	let cellType = this.columnNames[args.cell].id + "" ?? "";
	let id = this.promoCodes[args.row].id
	    if (cellType == "edit") {
			this.edit(id+'');
		}else if(cellType == "delete")
		{
			this.spinner.show();
			this.promoCodeService.deletePromoCode(id).subscribe(
				(resonse) => {

					this.getModules();
					this.spinner.hide();
				}
			)
		}

  }
  angularGridReady(angularGrid: AngularGridInstance) {
	this.angularGrid = angularGrid;
	//this.gridObj = angularGrid.slickGrid;
  }


}
