import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../../shared/common-services/shared-service';
import { ToolbarPath } from '../../../../shared/interfaces/toolbar-path';
import { NgxSpinnerService } from 'ngx-spinner';
import { CODE_REQUIRED_VALIDATORS, EMAIL_VALIDATORS, NAME_REQUIRED_VALIDATORS } from '../../../../shared/constants/input-validators';
import { Subscription } from 'rxjs';
import { ToolbarData } from '../../../../shared/interfaces/toolbar-data';
import { ToolbarActions } from '../../../../shared/enum/toolbar-actions';
import { navigateUrl } from '../../../../shared/helper/helper-url';
import { UserServiceProxy } from '../../services/user-service'
import { RoleServiceProxy } from '../../services/role.servies';
import { RoleDto } from '../../models/role';
import { UserDto } from '../../models/User';
import { ModuleService } from '../../services/module.service';
import { TypeOfSubscription } from 'src/app/shared/enum/alert-types';
import { AngularGridInstance, Column, FieldType, Filters, Formatters, GridOption } from 'angular-slickgrid';
import { ExcelExportService } from '@slickgrid-universal/excel-export';
import { MatDialog } from '@angular/material/dialog';
import { PackageDetailsComponent } from '../package-details/package-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { da } from 'date-fns/locale';
@Component({
	selector: 'app-add-edit-owner',
	templateUrl: './add-edit-owner.component.html',
	styleUrls: ['./add-edit-owner.component.scss']
})
export class AddEditOwnerComponent implements OnInit {
	loading = false;
	isPackageActivating = false;


	//#region Main Declarations
	userForm!: FormGroup;
	id: any = 0;
	currnetUrl;
	public show: boolean = false;
	lang = localStorage.getItem("language")
	user: UserDto[] = [];
	userOwnerDto: any;
	addUrl: string = '/security/customer/add-customer';
	updateUrl: string = '/security/customer/update-customer/';
	listUrl: string = '/security/customer';
	moduleList: any[] = [];
	showModules: boolean = false;
	moduleIds: number[] = [];
	isPaid;
	toolbarPathData: ToolbarPath = {
		listPath: '',
		updatePath: this.updateUrl,
		addPath: this.addUrl,
		componentList: this.translate.instant("component-names.customer"),
		componentAdd: '',

	};
	response: any;
	errorMessage = '';
	errorClass = '';
	submited: boolean = false;
	rolesList: RoleDto[] = [];
	userDetailsPackageDtos: any = [];
	userDetailsModuleDtos: any = [];
	datauserDetailsPackageDtos: any;
	datauserDetailsModuleDtos: any;
	mergedUserDetailsDto: any;
	constructor(
		private roleService: RoleServiceProxy,
		private userService: UserServiceProxy,
		private router: Router,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private sharedService: SharedService,
		private translate: TranslateService,
		private moduleService: ModuleService,
		private modelService: NgbModal,
		public dialog: MatDialog,


	) {
		this.defineUserForm();
	}
	//#endregion
	ngAfterViewInit(): void {
		this.prepareGird();
	}
	//#region ngOnInit
	ngOnInit(): void {
		this.spinner.show();

		Promise.all([this.getRouteData(),
		this.getModuleList(),
			//   this.getRoles()
		]).then(a => {
			this.prepareGird()
			this.currnetUrl = this.router.url;
			if (this.currnetUrl == this.addUrl) {
				this.getUserCode();
			}
			this.changePath();
			this.listenToClickedButton();
		}).catch((err) => {

			this.spinner.hide();
		})


	}

	//#endregion

	//#region ngOnDestroy
	ngOnDestroy() {
		this.subsList.forEach((s) => {
			if (s) {
				s.unsubscribe();
			}
		});
	}
	//#endregion
	getRouteData() {
		let sub = this.route.params.subscribe((params) => {
			if (params['id'] != null) {
				this.id = params['id'];

				if (this.id) {
					this.getUserById(this.id).then(a => {

						this.spinner.hide();

					}).catch(err => {
						this.spinner.hide();

					});
				}
				else {
					this.sharedService.changeButton({ action: 'New' } as ToolbarData);
					this.spinner.hide();
				}
			}
			else {
				this.sharedService.changeButton({ action: 'New' } as ToolbarData);
				this.spinner.hide();
			}
		});
		this.subsList.push(sub);

	}
	//#region Authentications

	//#endregion

	//#region Permissions

	//#endregion

	//#region  State Management
	//#endregion

	//#region Basic Data
	///Geting form dropdown list data
	defineUserForm() {
		this.userForm = this.fb.group({
			id: 0,
			nameAr: NAME_REQUIRED_VALIDATORS,
			nameEn: NAME_REQUIRED_VALIDATORS,
			code: CODE_REQUIRED_VALIDATORS,
			isActive: true,
			fullName: NAME_REQUIRED_VALIDATORS,
			email: EMAIL_VALIDATORS,
			//passWord:new FormControl(''),
			// phoneNumber: null,
			// roles: ''
		});
	}
	getRoles() {
		return new Promise<void>((resolve, reject) => {
			let sub = this.roleService.allRoles(undefined, undefined, undefined, undefined, undefined).subscribe({
				next: (res) => {
					resolve();

					if (res) {
						this.rolesList = res.response.items;

					}



				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});

			this.subsList.push(sub);
		});

	}
	//#endregion

	//#region CRUD Operations
	getUserDetailsPackageById(id: any) {
		return new Promise<void>((resolve, reject) => {

			let sub = this.userService.getUserDetailsPackageById(id).subscribe({
				next: (res: any) => {
					resolve();


					this.datauserDetailsPackageDtos = res?.response;

					this.datauserDetailsPackageDtos
					this.datauserDetailsPackageDtos.typeOfSubscriptionString = this.getTranslatedEnumValue(this.datauserDetailsPackageDtos.typeOfSubscription);
					this.datauserDetailsPackageDtos.paymentTypeString = this.getTranslateEnumPayment(this.datauserDetailsPackageDtos.paymentType);





				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}
	getUserDetailsModuleByCode(id: any) {
		return new Promise<void>((resolve, reject) => {
			this.isPaid = true;
			let sub = this.userService.getUserDetailsModuleByCode(id).subscribe({
				next: (res: any) => {
					resolve();
					this.userDetailsModuleDtos = res?.response;

					this.userDetailsModuleDtos.forEach(
						element => {
							;
							element.typeOfSubscriptionString = this.getTranslatedEnumValue(element.typeOfSubscription);
							element.paymentTypeString = this.getTranslateEnumPayment(element.paymentType)
							if (element.isPaid == false) {
								this.isPaid = false;
							}
						}
					)



					this.userForm.disable();
				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}
	getUserById(id: any) {
		return new Promise<void>((resolve, reject) => {

			let sub = this.userService.getUserOwner(id).subscribe({
				next: (res: any) => {
					resolve();

					this.userForm.setValue({
						id: res.response?.id,
						nameAr: res.response?.nameAr,
						nameEn: res.response?.nameEn,
						code: res.response?.code,
						isActive: res.response?.isActive,
						fullName: res.response?.fullName,
						email: res.response?.email,
						//passWord:'',
						//phoneNumber: res.response?.phoneNumber,
						// roles: res.response?.roles
					});

					this.userOwnerDto = res.response;

					this.mergedUserDetailsDto = res?.response?.mergedUserDetailsDto;

					this.mergedUserDetailsDto.forEach(
						element => {

							element.typeOfSubscriptionString = this.getTranslatedEnumValue(element.typeOfSubscription)


						}
					)


					this.userForm.disable();
				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}
	showPassword() {
		this.show = !this.show;
	}
	getUserCode() {
		return new Promise<void>((resolve, reject) => {
			let sub = this.userService.getLastCode().subscribe({
				next: (res: any) => {
					resolve();
					this.toolbarPathData.componentList = this.translate.instant("component-names.role");
					this.userForm.patchValue({
						code: res.response
					});

				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}
	//#endregion

	//#region Helper Functions

	get f(): { [key: string]: AbstractControl } {
		return this.userForm.controls;
	}


	//#endregion
	//#region Tabulator
	subsList: Subscription[] = [];
	currentBtnResult;
	listenToClickedButton() {
		let sub = this.sharedService.getClickedbutton().subscribe({
			next: (currentBtn: ToolbarData) => {
				currentBtn;

				if (currentBtn != null) {
					if (currentBtn.action == ToolbarActions.List) {
						this.sharedService.changeToolbarPath({
							listPath: this.listUrl,
						} as ToolbarPath);
						this.router.navigate([this.listUrl]);
					} else if (currentBtn.action == ToolbarActions.Save) {
						this.onSave();
					} else if (currentBtn.action == ToolbarActions.New) {
						this.toolbarPathData.componentAdd = this.translate.instant("user-manager.add-user");
						this.defineUserForm();
						this.sharedService.changeToolbarPath(this.toolbarPathData);
					} else if (currentBtn.action == ToolbarActions.Update) {
						// this.onUpdate();
					}
				}
			},
		});
		this.subsList.push(sub);
	}
	changePath() {
		this.sharedService.changeToolbarPath(this.toolbarPathData);
	}
	confirmSave() {
		return new Promise<void>((resolve, reject) => {
			let sub = this.userService.createUser(this.userForm.value).subscribe({
				next: (result: any) => {

					this.response = { ...result.response };
					this.defineUserForm();
					this.submited = false;
					navigateUrl(this.listUrl, this.router);
				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}
	onSave() {
		if (this.userForm.valid) {
			this.spinner.show();
			this.confirmSave().then(a => {
				this.spinner.hide();
			}).catch(e => {
				this.spinner.hide();
			});
		} else {

			return this.userForm.markAllAsTouched();
		}
	}
	editUserDetailsPackageDto(event, userOwner, packageDetails) {

		this.isPackageActivating = true;

		// // Simulate activating package delay (replace with your actual data processing logic)
		// setTimeout(() => {
		// 	this.isPackageActivating = false;
		// 	// Activate the package here
		// }, 20000000); // يمكنك تعديل 2000 مللي ثانية وفقًا لرغبتك


		this.userForm.value.id = this.id;
		var userDetailsPackage =
		{
			isPaid: event.target.checked,
			id: userOwner.id,
			userId: this.id,
			paymentId: userOwner.paymentId
		};


		let sub = this.userService.paidPayment(userDetailsPackage).subscribe({
			next: (result: any) => {

				this.response = { ...result.response };
				//  this.defineUserForm();
				this.submited = false;
				this.isPackageActivating = false;
				this.getRouteData();
				//   navigateUrl(this.listUrl, this.router);


			},
			error: (err: any) => {
				this.isPackageActivating = false;
			},
			complete: () => {
				console.log('complete');
			},
		});
		this.subsList.push(sub);

	}
	editUserModule(event, userOwner) {
		this.isPackageActivating = true;
		this.userForm.value.id = this.id;
		var userDetailsPackage =
		{
			isPaid: event.target.checked,
			id: userOwner.id,
			userId: this.id,
			paymentId: userOwner.paymentId,
			code: userOwner.code
		};



		let sub = this.userService.paidPayment(userDetailsPackage).subscribe({
			next: (result: any) => {


				this.response = { ...result.response };
				//  this.defineUserForm();
				this.isPackageActivating = false;
				this.submited = false;
				this.getRouteData();
				//   navigateUrl(this.listUrl, this.router);

			},
			error: (err: any) => {
				this.isPackageActivating = false;

			},
			complete: () => {
				console.log('complete');
			},
		});
		this.subsList.push(sub);


	}


	chekcedModule(id: number, moduleIds) {
		if (moduleIds != null) {
			return moduleIds.find(x => x == id) != null ? true : false;
		}
		return false;
	}
	getModuleList() {
		return new Promise<void>((resolve, reject) => {
			let sub = this.moduleService.getModuleDDL()
				.subscribe({
					next: (res: any) => {
						resolve();

						this.moduleList = res.response;
					},
					error: (err: any) => {
						reject(err);
					},
					complete: () => {
						console.log('complete');
					},
				});

			this.subsList.push(sub);
		});
	}

	//#endregion
	getTranslatedEnumValue(enumValue: any): string {

		switch (enumValue) {
			case "monthlySubscription":
				return this.translate.instant('typeOfSubscription.monthlySubscription');
			case "yearlyQuarterSubscription":
				return this.translate.instant('typeOfSubscription.yearlyQuarterSubscription');
			case "yearlyHalfSubscription":
				return this.translate.instant('typeOfSubscription.yearlyHalfSubscription');
			case "yearlySubscription":
				return this.translate.instant('typeOfSubscription.yearlySubscription');
			case "fullBuyingSubscription":
				return this.translate.instant('typeOfSubscription.fullBuyingSubscription');
			default:
				return '';
		}

	}
	getTranslateEnumPayment(enumValue: any): string {

		switch (enumValue) {
			case "online":
				return this.translate.instant('paymentType.online');
			case "cash":
				return this.translate.instant('paymentType.cash');
			case "trial":
				return this.translate.instant('paymentType.online');
			case "free":
				return this.translate.instant('paymentType.cash');

			default:
				return '';
		}

	}
	//for slick grid prepartion
	columnNames: Column[] = [];
	gridOptions!: GridOption;
	isAutoEdit: boolean = true;
	updatedObject: any;
	angularGrid!: AngularGridInstance;
	prepareGird() {
		let name = this.translate.instant("modules.name")
		let edit = this.translate.instant("buttons.show")
		let typeOfSubscription = this.translate.instant("packages.typeOfSubscription")
		let subscriptionStartDate = this.translate.instant("packages.subscriptionStartDate")
		let subscriptionExpiaryDate = this.translate.instant("packages.subscriptionExpiryDate")
		let type = this.translate.instant("packages.type")
		this.gridOptions = {
			asyncEditorLoading: true,
			autoEdit: this.isAutoEdit,
			autoCommitEdit: true,
			autoResize: {
				container: '#editorGridPackage1',
				rightPadding: 25
			},
			createPreHeaderPanel: true,
			showPreHeaderPanel: true,
			preHeaderPanelHeight: 40,
			cellHighlightCssClass: 'changed',
			gridWidth: "auto",
			editable: true,
			enableCellNavigation: true,
			enableColumnPicker: true,
			enableFiltering: true,
			enableExcelExport: true,
			enableExcelCopyBuffer: true,
			excelExportOptions: {
				exportWithFormatter: true,
				customColumnWidth: 20,
				// you can customize how the header titles will be styled (defaults to Bold)
				columnHeaderStyle: { font: { bold: true, italic: true } }
			},

			registerExternalResources: [new ExcelExportService()],
			enableTranslate: true,
			i18n: this.translate,

		};

		this.columnNames = [
			{
				id: "id",
				name: "#",
				field: "",
				filterable: false,
				sortable: false,
				type: FieldType.string,
				// formatter: Formatters.center,
				minWidth: 15,
				maxWidth: 50
			},
			// {
			// 	id: "type",
			// 	name: type,
			// 	field: "type",
			// 	filterable: true,
			// 	sortable: true,
			// 	type: FieldType.date,
			// 	// formatter: Formatters.center,
			// 	//filter: { model: Filters.compoundDate },
			// 	minWidth: 100,
			// 	maxWidth: 200
			// },
			{
				id: this.lang == 'ar' ? "nameAr" : "nameEn",
				name: name,
				field: this.lang == 'ar' ? "nameAr" : "nameEn",
				filterable: true,
				sortable: true,
				type: FieldType.string,
				// formatter: Formatters.center,
				minWidth: 100,
				maxWidth: 200
			},
			{
				id: "typeOfSubscriptionString",
				name: typeOfSubscription,
				field: "typeOfSubscriptionString",
				filterable: true,
				sortable: true,
				type: FieldType.float,
				// formatter: Formatters.center,
				minWidth: 100,
				maxWidth: 200
			},
			{
				id: "subscriptionStartDate",
				name: subscriptionStartDate,
				field: "subscriptionStartDate",
				filterable: true,
				sortable: true,
				type: FieldType.date,
				formatter: Formatters.dateUs,
				minWidth: 100,
				maxWidth: 200,
				//filter: { model: Filters.compoundDate }
			},
			{
				id: "subscriptionExpiaryDate",
				name: subscriptionExpiaryDate,
				field: "subscriptionExpiaryDate",
				filterable: true,
				sortable: true,
				type: FieldType.date,
				formatter: Formatters.dateUs,
				//filter: { model: Filters.compoundDate },
				minWidth: 100,
				maxWidth: 200
			},

			{
				id: "edit",
				name: edit,
				field: "id",
				filterable: false,
				sortable: false,
				type: FieldType.string,
				formatter: Formatters.infoIcon,
				cssClass: 'text-center',
				minWidth: 25,
				maxWidth: 50
			},
		]


	}
	angularGridReady(angularGrid: AngularGridInstance) {
		this.angularGrid = angularGrid;
		//this.gridObj = angularGrid.slickGrid;
	}







	displayStyle = "none";
	closePopup() {
		this.displayStyle = "none";
	}
	showPackageModule(row: any, args: any) {

		var item = this.mergedUserDetailsDto[args.row];

		if (item.userDetailsModuleId != null && item.userDetailsModuleId != undefined && item.code != null) {

			this.displayStyleModule = "block";

			this.datauserDetailsPackageDtos = null;
			if (item.code) {
				this.getUserDetailsModuleByCode(item.code).then(a => {

					this.spinner.hide();

				}).catch(err => {
					this.spinner.hide();

				});
			}
		}
		else if (item.userDetailsPackageId != null && item.userDetailsPackageId != undefined) {
			this.displayStyle = "block";

			//this.datauserDetailsPackageDtos = this.mergedUserDetailsDto[args.row];
			if (item.userDetailsPackageId) {
				this.getUserDetailsPackageById(item.userDetailsPackageId).then(a => {

					this.spinner.hide();

				}).catch(err => {
					this.spinner.hide();

				});
			}
			this.userDetailsModuleDtos = null;
		}

	}

	displayStyleModule = "none";
	closePopupModule() {
		this.displayStyleModule = "none";
	}





}
