<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading'|translate}}... </p>
</ngx-spinner>
<section class="inner-page-wrapper inner-page-wrapper-units">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-3">
					<div class="card-body">
						<form [formGroup]="promoCodeForm" id="BusinessSubmit" class="tabs-form">
							<div class="main-customer">
								<div class="wrapper-title">
									<h3>{{'PromoCode.promo-code'|translate}}</h3>
									<hr>
									<div class="row align-items-center">
										<div class="col-md-6 col-12">
											<label for="nameAr" class="label-name">
												{{'general.nameAr'|translate}}</label><span class="text-danger  f-20">*</span>
											<div class="form-group position-relative">
 												<input type="text" class="form-control icon-user custom border-danger"
												 [ngClass]="{ 'is-invalid': this.promoCodeForm.get('nameAr').value == null }"
													name="nameAr" formControlName="nameAr">
												<!-- <div *ngIf="(f['nameAr'].touched || f['nameAr'].dirty ) && f['nameAr'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['nameAr'].errors && f['nameAr'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div> -->

											</div>
										</div>
										<div class="col-md-6 col-12">
											<label for="nameEn"
												class="label-name">{{'general.nameEn'|translate}}</label><span
												class="text-danger f-20">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user custom" name="nameEn"
												[ngClass]="{ 'is-invalid': this.promoCodeForm.get('nameEn').value == null }"
													formControlName="nameEn">
												<!-- <div *ngIf="(f['nameEn'].touched || f['nameEn'].dirty ) && f['nameEn'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['nameEn'].errors && f['nameEn'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div> -->

											</div>
										</div>
										<div class="col-md-6 col-12">
											<label for="promoCode"
												class="label-name">{{'PromoCode.promo-code'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="promoCode"
												[ngClass]="{'is-invalid':f['promoCode'].errors || f['promoCode'].invalid}"
												formControlName="promoCode">
												<div *ngIf="(f['promoCode'].touched || f['promoCode'].dirty ) && f['promoCode'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['promoCode'].errors && f['promoCode'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12">
											<label for="discountPercentage"
												class="label-name">{{'PromoCode.discount-percentage'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="number" class="form-control icon-user" name="discountPercentage"
												[ngClass]="{'is-invalid':f['discountPercentage'].errors || f['discountPercentage'].invalid}"

													formControlName="discountPercentage">
												<div *ngIf="(f['discountPercentage'].touched || f['discountPercentage'].dirty ) && f['discountPercentage'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['discountPercentage'].errors && f['discountPercentage'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>


										<div class="col-12 col-md-6">
											<label for="fromDate"
												class="label-name">{{'PromoCode.from-date'|translate}}</label><span class="text-danger">*</span>
											<div class="form-group position-relative">
												<input type="date" class="form-control icon-customer"
												[ngClass]="{'is-invalid':f['fromDate'].errors || f['fromDate'].invalid}"
													name="fromDate" formControlName="fromDate">
													<!-- <app-full-date name="fromDate" formControlName="fromDate" [(ngModel)]="fromDate" ngDefaultControl
													(OnDateSelect)="getFromDate($event)"></app-full-date> -->
													<div *ngIf="(f['fromDate'].touched || f['fromDate'].dirty ) && f['fromDate'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['fromDate'].errors && f['fromDate'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>


										<div class="col-12 col-md-6">
											<label for="toDate"
												class="label-name">{{'PromoCode.to-date'|translate}}</label><span class="text-danger">*</span>
											<div class="form-group position-relative">
												<input type="date" class="form-control icon-customer"
												[ngClass]="{'is-invalid':f['toDate'].errors || f['toDate'].invalid}"
													name="toDate" formControlName="toDate">
													<div *ngIf="(f['toDate'].touched || f['toDate'].dirty ) && f['toDate'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['toDate'].errors && f['toDate'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>


										<div class="col-md-6 col-12 d-flex align-items-end">
											<div class="media">
												<label class="label-name"> {{'general.status'|translate}}
												</label>
												<div class="icon-state media-body mx-5">
													<label class="mx-2 switch">
														<input type="checkbox" class="form-control" name="isActive"
															formControlName="isActive">
														<span class="switch-state"></span>
													</label>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</form>

					</div>
				</div>
			</div>
		</div>

		<!--
        <div id="ex-table-div"></div> -->

	</div>
</section>
