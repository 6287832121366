import { NgxSpinnerService } from 'ngx-spinner';
import { Column, GridOption, AngularGridInstance, FieldType, Formatters } from 'angular-slickgrid';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PackageService } from './../../services/package.service';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';
import { SettingMenuShowOptions } from 'src/app/shared/components/models/setting-menu-show-options';
import { SharedService } from 'src/app/shared/common-services/shared-service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { ExcelExportService } from '@slickgrid-universal/excel-export';
import { Package } from '../../models/package';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';

@Component({
  selector: 'app-packages-list',
  templateUrl: './packages-list.component.html',
  styleUrls: ['./packages-list.component.scss']
})
export class PackagesListComponent implements  OnInit, OnDestroy, AfterViewInit  {
	currnetUrl: any;
	updateUrl: string = '/security/packages/add-edit-package/';
	addUrl: string = '/security/packages/add-edit-package';
	listUrl: string = '/security/packages';
	toolbarPathData: ToolbarPath = {
		listPath: this.addUrl,
		addPath : this.addUrl,
		updatePath: this.updateUrl,
	  componentList:this.translate.instant("packages.package"),
	  componentAdd: '',

	};
	sortByCols: any[] = [];
	groupByCols: string[] = [];
	direction: string = 'ltr';
	lang = localStorage.getItem("language");
	menuOptions: SettingMenuShowOptions = {
		showDelete: false,
		showEdit: true,};
	panelId: number = 1;
	searchFilters: any;
	currentBtn!: string;
    subsList: Subscription[] = [];
     //for slick grid prepartion
     columnNames : Column []= [];
	 columnCutomizedNames : Column []= [];
	 gridOptions!: GridOption;
	 gridCutomizedOptions : GridOption;
	 isAutoEdit : boolean  = true;
	 updatedObject: any;
	 angularGrid: AngularGridInstance;
	 angularCustomizedGrid : AngularGridInstance;
	 datasetGeneral : any[] = [];
	 datasetCustomized : any[] = [];
	 constructor(
		private translate : TranslateService,
		private router: Router,
		private sharedService: SharedService,
		private spinner: NgxSpinnerService,
		private packageService : PackageService
	  ) {}

	  ngAfterViewInit(): void {

	  }

	  ngOnDestroy() {
		this.subsList.forEach((s) => {
		  if (s) {
			s.unsubscribe();
		  }
		});
	  }


	  ngOnInit(): void {
		this.spinner.show();

		this.defineGrid();


		this.sharedService.changeButton({ action: 'List' } as ToolbarData);
		this.sharedService.changeToolbarPath(this.toolbarPathData);
		this.listenToClickedButton();
		Promise.all([this.getAllPackages()])
		  .then(a => {
			this.spinner.hide();

		  }).catch(err => {
			this.spinner.hide();
		  })
	  }

	  getAllPackages()
	  {
		return new Promise<void>((resolve, reject) => {
			let sub = this.packageService.allPackages().subscribe({
			  next: (res) => {

				this.toolbarPathData.componentList =this.translate.instant("packages.package");
				if (res.success) {
                    this.datasetGeneral = res.response as any[]
					this.angularGrid.dataView.addItems(this.datasetGeneral)
					this.angularGrid.slickGrid.setData(this.datasetGeneral);
					this.angularGrid.slickGrid.render();

					let packageName = this.translate.instant("packages.package-name");
					let monthly = this.translate.instant("packages.monthly-price");
					let quarterYearly = this.translate.instant("packages.quarterYearly-price");
					let halfYearly = this.translate.instant("packages.halfYearly-price");
					let yearly = this.translate.instant("packages.yearly-price");
					let fullprice = this.translate.instant("packages.full-price");
					let deletebtn = this.translate.instant("buttons.delete");
					let edit = this.translate.instant("buttons.edit");

					//if list have values
					if(this.datasetGeneral.length > 0)
					{
						this.columnNames =  [
							{
							   id: "id",
							   name: "#",
							   field:"id",
							   filterable: false,
							   sortable: false,
							   type: FieldType.string,
							   formatter : Formatters.center,
							   minWidth: 15,
							 },
							 {
								id: this.lang == 'ar' ? "nameAr" : "nameEn",
								name: packageName,
								field:this.lang == 'ar' ? "nameAr" : "nameEn",
								filterable: true,
								sortable: true,
								type: FieldType.string,
								formatter : Formatters.center,
								minWidth: 175,
							  }];

					     //for monthly price
						if(this.datasetGeneral[0].monthlyPrice != null)
							{
								this.columnNames.push(  {
									id: "monthlyPrice",
									name: monthly,
									field:"monthlyPrice",
									filterable: true,
									sortable: true,
									type: FieldType.number,
									formatter : Formatters.center,
									minWidth: 175,
								  }
								)

							}

						// for quarter yearly price
						if(this.datasetGeneral[0].yearlyQuarterPrice != null)
							{
								this.columnNames.push(  {
									id: "yearlyQuarterPrice",
									name: quarterYearly,
									field:"yearlyQuarterPrice",
									filterable: true,
									sortable: true,
									type: FieldType.number,
									formatter : Formatters.center,
									minWidth: 175,
								  }
								)
							}


						// for half yearly price
						if(this.datasetGeneral[0].yearlyHalfPrice != null)
							{
								this.columnNames.push(  {
									id: "yearlyHalfPrice",
									name: halfYearly,
									field:"yearlyHalfPrice",
									filterable: true,
									sortable: true,
									type: FieldType.number,
									formatter : Formatters.center,
									minWidth: 175,
								  }
								)
							}

						// for yearly price
						if(this.datasetGeneral[0].yearlyPrice != null)
							{
								this.columnNames.push(  {
									id: "yearlyPrice",
									name: yearly,
									field:"yearlyPrice",
									filterable: true,
									sortable: true,
									type: FieldType.number,
									formatter : Formatters.center,
									minWidth: 175,
								  })
							}


						// for full price
						if(this.datasetGeneral[0].fullBuyPrice != null)
							{
								this.columnNames.push(  {
									id: "fullBuyPrice",
									name: fullprice,
									field:"fullBuyPrice",
									filterable: true,
									sortable: true,
									type: FieldType.number,
									formatter : Formatters.center,
									minWidth: 175,
								  })
							}

						this.columnNames.push( {
							id: "edit",
							name: edit,
							field:"id",
							filterable: false,
							sortable: false,
							type: FieldType.string,
							formatter : Formatters.editIcon,
							minWidth: 15,
						  },
						  {
							id: "delete",
							name: deletebtn,
							field:"id",
							filterable: false,
							sortable: false,
							type: FieldType.string,
							formatter : Formatters.deleteIcon,
							minWidth: 15,
						  })

					}
					else
					{
						this.columnNames = [
							{
							   id: "id",
							   name: "#",
							   field:"id",
							   filterable: false,
							   sortable: false,
							   type: FieldType.string,
							   formatter : Formatters.center,
							   minWidth: 15,
							 },
							 {
								id: this.lang == 'ar' ? "nameAr" : "nameEn",
								name: packageName,
								field:this.lang == 'ar' ? "nameAr" : "nameEn",
								filterable: true,
								sortable: true,
								type: FieldType.string,
								formatter : Formatters.center,
								minWidth: 175,
							  },
							  {
								id: "monthlyPrice",
								name: monthly,
								field:"monthlyPrice",
								filterable: true,
								sortable: true,
								type: FieldType.number,
								formatter : Formatters.center,
								minWidth: 175,
							  },
							  {
								id: "yearlyPrice",
								name: yearly,
								field:"yearlyPrice",
								filterable: true,
								sortable: true,
								type: FieldType.number,
								formatter : Formatters.center,
								minWidth: 175,
							  },
							  {
								id: "fullBuyPrice",
								name: fullprice,
								field:"fullBuyPrice",
								filterable: true,
								sortable: true,
								type: FieldType.number,
								formatter : Formatters.center,
								minWidth: 175,
							  },
							  {
								id: "edit",
								name: edit,
								field:"id",
								filterable: false,
								sortable: false,
								type: FieldType.string,
								formatter : Formatters.editIcon,
								minWidth: 15,
							  },
							  {
								id: "delete",
								name: deletebtn,
								field:"id",
								filterable: false,
								sortable: false,
								type: FieldType.string,
								formatter : Formatters.deleteIcon,
								minWidth: 15,
							  },
						 ]

					}
				}
				resolve();
			  },
			  error: (err: any) => {
				reject(err);
			  },
			  complete: () => {
				console.log('complete');
			  },
			});
			this.subsList.push(sub);
		  });
	  }
	  defineGrid ()
	  {
		this.columnNames = [];
		this.gridOptions = {
			asyncEditorLoading: true,
			autoEdit: this.isAutoEdit,
			autoCommitEdit: true,
			autoResize: {
			  container: '#packageGrid',
			  rightPadding: 25
			},
			createPreHeaderPanel: true,
			showPreHeaderPanel: true,
			preHeaderPanelHeight: 40,
			cellHighlightCssClass: 'changed',
			gridWidth: "auto",
			editable: true,
			enableCellNavigation: true,
			enableColumnPicker: true,
			enableFiltering: true,
			enableExcelExport: true,
			enableExcelCopyBuffer: true,
			excelExportOptions: {
					  exportWithFormatter: true,
					  customColumnWidth: 20,
					  // you can customize how the header titles will be styled (defaults to Bold)
					  columnHeaderStyle: { font: { bold: true, italic: true } }
				  },

			registerExternalResources: [new ExcelExportService()],
			enableTranslate: true,
			i18n:this.translate,

		  };
	  }



	  edit(id: string) {

		this.sharedService.changeButton({
			action: "Update",
			componentName: "List",
			submitMode: false,
		} as ToolbarData);

		//this.router.navigate(['/security/promo-code/edit-promo-code/'+id]);
		this.router.navigate(['/security/packages/add-edit-package/',id,]);
	  }

	  click(e:any , args : any)
	  {

		let cellType = this.columnNames[args.cell].id + "" ?? "";
		let id = this.datasetGeneral[args.row].id
		if (cellType == "edit") {
			this.edit(id+'');
		}else if(cellType == "delete")
		{
			this.spinner.show();
			this.packageService.deletePackage(id).subscribe(
				(resonse) => {

					this.getAllPackages();
					this.spinner.hide();
				}
			)
		}

	  }


	  listenToClickedButton()
	  {

		let sub = this.sharedService.getClickedbutton().subscribe({
			next: (currentBtn: ToolbarData) => {
			  //currentBtn;
			  if (currentBtn != null) {
				if (currentBtn.action == ToolbarActions.List) {

				} else if (currentBtn.action == ToolbarActions.Update) {
				  this.router.navigate([this.updateUrl]);
				}

				else if (currentBtn.action == ToolbarActions.New) {
					this.router.navigate([this.addUrl]);
				  }
			  }
			},
		  });
		  this.subsList.push(sub);
	  }



	  angularGridReady(angularGrid: AngularGridInstance) {
		this.angularGrid = angularGrid;
		//this.gridObj = angularGrid.slickGrid;
	  }

	  onCustomizedTabClicked()
	  {


				    this.angularCustomizedGrid.dataView.addItem(this.datasetCustomized)
                    this.angularCustomizedGrid.slickGrid.setData(this.datasetCustomized)
					this.angularCustomizedGrid.slickGrid.render();


	  }

}
