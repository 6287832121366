<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading'|translate}}... </p>
</ngx-spinner>
<section class="inner-page-wrapper inner-page-wrapper-units">

	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-body">
						<form [formGroup]="userForm" id="userFormSubmit" class="tabs-form">
							<div class="main-customer">
								<div class="wrapper-title">

									<h3>{{'component-names.user-information'|translate}}</h3>
									<hr>
									<div class="row ">

										<div class="col-12 col-md-6">
											<label for="nameAr" class="label-name">
												{{'general.nameAr'|translate}}</label><span class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user"
													[ngClass]="{'is-invalid':f['nameAr'].errors || f['nameAr'].invalid}"
													name="nameAr" formControlName="nameAr">
												<div *ngIf="(f['nameAr'].touched || f['nameAr'].dirty ) && f['nameAr'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['nameAr'].errors && f['nameAr'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>
										<div class="col-12 col-md-6">
											<label for="nameEn"
												class="label-name">{{'general.nameEn'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="nameEn"
													formControlName="nameEn">
												<div *ngIf="(f['nameEn'].touched || f['nameEn'].dirty ) && f['nameEn'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['nameEn'].errors && f['nameEn'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>
										<div class="col-12 col-md-6">
											<label for="fullName"
												class="label-name">{{'user-manager.user-name'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="fullName"
													formControlName="fullName">
												<div *ngIf="(f['fullName'].touched || f['fullName'].dirty ) && f['fullName'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span
														*ngIf="f['fullName'].errors && f['fullName'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>
										<div class="col-12 col-md-6">
											<label for="email"
												class="label-name">{{'user-manager.user-email'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="email"
													formControlName="email">
												<div *ngIf="(f['email'].touched || f['email'].dirty ) && f['email'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['email'].errors && f['email'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12 d-flex align-items-end">
											<div class="media">
												<label class="label-name"> {{'general.status'|translate}}
												</label>
												<div class="icon-state media-body mx-5">
													<label class="mx-2 switch">
														<input type="checkbox" class="form-control" name="isActive"
															formControlName="isActive">
														<span class="switch-state"></span>
													</label>
												</div>
											</div>
										</div>

									</div>

								</div>
							</div>
						</form>
						<hr>

						<section *ngIf="userOwnerDto && userOwnerDto.mergedUserDetailsDto.length > 0"
							class="inner-page-wrapper inner-page-auction">
							<div class="container-fluid p-0">
								<angular-slickgrid [gridOptions]="gridOptions" [columnDefinitions]="columnNames"
									gridId="editorGridPackage1" [dataset]="mergedUserDetailsDto"
									(onClick)="showPackageModule($event.detail.eventData, $event.detail.args)"
									(onAngularGridCreated)="angularGridReady($event.detail)">

								</angular-slickgrid>
							</div>
						</section>


					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
	<!-- Add a loading spinner -->
	<div *ngIf="isPackageActivating" class="overlay-loader">
		<div class="loading-spinner"></div>
		<p> جاري تجهيز قاعدة البيانات
		</p>
	</div>


	<div class="modal-dialog" role="document" style="max-width: 800px"
		[ngClass]="{'disabled-content': isPackageActivating}">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{'packages.package'|translate}}</h4>
			</div>

			<div *ngIf="datauserDetailsPackageDtos" class="modal-body">
				<div>

					<div class="d-flex align-items-center gap-3">
						<label class="label-name"> {{'packages.active'|translate}}
						</label>
						<label class="mx-2 switch">
							<input type="checkbox" class="form-control" name="isPaid"
							    [disabled]="datauserDetailsPackageDtos.isPaid == true"
								[(ngModel)]="datauserDetailsPackageDtos.isPaid"
								(change)="editUserDetailsPackageDto($event,datauserDetailsPackageDtos)">
							<span class="switch-state"></span>
						</label>
					</div>

					<hr />
					<div class="row">
						<div class="col-md-6 col-12">
							<div class="item-permissions">
								<div class="d-flex justify-content-between align-items-end ">
									<h3>{{ datauserDetailsPackageDtos.nameAr }}</h3>

								</div>
								<hr>
								<h6 class="justify-content-between align-items-center d-flex">
									{{'general.nameAr'|translate}}<span>{{ datauserDetailsPackageDtos.nameAr }}</span>
								</h6>
								<h6 class="justify-content-between align-items-center d-flex">
									{{'general.nameEn'|translate}}<span>{{ datauserDetailsPackageDtos.nameEn }}</span>
								</h6>

								<h6 class="justify-content-between align-items-center d-flex">{{
									'packages.typeOfSubscription' | translate }}
									<span>{{
										getTranslatedEnumValue(datauserDetailsPackageDtos.typeOfSubscription)}}</span>
								</h6>
								<h6 class="justify-content-between align-items-center d-flex">{{
									'packages.subscriptionPrice' | translate }}
									<span>{{
										datauserDetailsPackageDtos.subscriptionPrice }}</span>
								</h6>
								<h6 class="justify-content-between align-items-center d-flex">{{
									'packages.subscriptionStartDate' | translate }}
									<span>{{datauserDetailsPackageDtos.subscriptionStartDate|date }}</span>
								</h6>
								<h6 class="justify-content-between align-items-center d-flex">{{
									'packages.subscriptionExpiryDate' | translate }}
									<span>{{
										datauserDetailsPackageDtos.subscriptionExpiaryDate|date }}</span>
								</h6>
								<h6 class="justify-content-between align-items-center d-flex">{{
									'paymentType.paymentType' | translate }}
									<span>
										{{(datauserDetailsPackageDtos.paymentTypeString) }}
									</span>
								</h6>

								<h6 class="justify-content-between align-items-center d-flex">{{
									'paymentType.totalPaidPrice' | translate }}
									<span>
										{{(datauserDetailsPackageDtos.totalSubscriptionPrice) }}
									</span>

								</h6>

								<h6 class="justify-content-between align-items-center d-flex"
									*ngIf="datauserDetailsPackageDtos.isPaid == false">{{
									'paymentType.totalNotPaidPrice' | translate }}
									<span>
										{{(datauserDetailsPackageDtos.lastPaidPrice) }}
									</span>
								</h6>



							</div>
						</div>
						<div class="col-md-6 col-12">
							<div class="item-permissions">
								<h3>{{'landingpage.packageDetailes'|translate}}</h3>
								<hr>
								<h6 class="justify-content-between align-items-center d-flex">
									{{'packages.numberOfUsers'|translate}}
									<span>
										{{ datauserDetailsPackageDtos.numberOfUsers
										}}
									</span>
								</h6>
								<h6 class="justify-content-between align-items-center d-flex">
									{{'packages.numberOfCompanies'|translate}}
									<span>
										{{
										datauserDetailsPackageDtos.numberOfCompanies }}
									</span>
								</h6>
								<h6 class="justify-content-between align-items-center d-flex">
									{{'packages.numberOfBranches'|translate}}
									<span>
										{{
										datauserDetailsPackageDtos.numberOfBranches }}
									</span>
								</h6>
								<h6 class="justify-content-between align-items-center d-flex">
									{{'packages.billPattrenNumber'|translate}}
									<span>{{
										datauserDetailsPackageDtos.billPattrenNumber }}</span>
								</h6>
								<h6 class="justify-content-between align-items-center d-flex">
									{{'packages.instrumentPattrenNumber'|translate}}
									<span>{{datauserDetailsPackageDtos.instrumentPattrenNumber
										}}</span>
								</h6>

							</div>
						</div>
					</div>


					<hr />



					<h3>{{'modules.Available-systems'|translate}}</h3>
					<hr>
					<div *ngIf="datauserDetailsPackageDtos" class="row">

						<div class="col-md-4 col-12"
							*ngFor="let userOwner of datauserDetailsPackageDtos?.userDetailsModuleDtos">

							<div class="item-permissions">
								<h6 *ngIf="lang == 'ar'">{{ userOwner.nameAr }}</h6>
								<hr>
								<h6 *ngIf="lang !== 'ar'">{{ userOwner.nameEn }}</h6>





							</div>
						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" (click)="closePopup()">
							{{'landingpage.Close' | translate}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleModule}"

>

<div *ngIf="isPackageActivating" class="overlay-loader">
	<div class="loading-spinner"></div>
	<p> جاري تجهيز قاعدة البيانات
	</p>
</div>
	<div class="modal-dialog" role="document" style="max-width: 800px" [ngClass]="{'disabled-content': isPackageActivating}">
		<div class="modal-content">
			<!-- <div class="modal-header">
				<h3>{{'modules.Module-information'|translate}}</h3>
			</div> -->
			<div *ngIf="userDetailsModuleDtos" class="modal-body">
				<div *ngIf="userDetailsModuleDtos">
					<div class="row">
						<div class="col-md-12 col-12">
							<div class="d-flex align-items-center gap-3">
								<label class="label-name"> {{'modules.active'|translate}}
								</label>
								<label class="mx-2 switch">
									<input type="checkbox" class="form-control" name="isPaid" [(ngModel)]="isPaid"
								     	[disabled]="isPaid == true"
										(change)="editUserModule($event,userDetailsModuleDtos[0])">
									<span class="switch-state"></span>
								</label>
							</div>
							<div class="item-permissions" *ngFor="let datauserModule of userDetailsModuleDtos">
								<div class="d-flex justify-content-between align-items-end ">
									<h3>{{'modules.Module-information'|translate}}</h3>

								</div>
								<hr>
								<div class="row">

									<div class="col-md-6 col-12 border-right">
										<h6 class="justify-content-between align-items-center d-flex">
											{{'general.nameAr'|translate}} <span>{{
												datauserModule.nameAr }}</span></h6>
										<h6 class="justify-content-between align-items-center d-flex">
											{{'general.nameEn'|translate}} <span> {{ datauserModule.nameEn }}</span>
										</h6>

										<h6 class="justify-content-between align-items-center d-flex">{{
											'packages.typeOfSubscription' | translate }} <span>
												{{getTranslatedEnumValue(
												datauserModule.typeOfSubscription) }}</span>
												</h6>
										<h6 class="justify-content-between align-items-center d-flex">{{
											'packages.subscriptionPrice' | translate }} <span> {{
												datauserModule.subscriptionPrice }}</span>
												</h6>


												<h6 class="justify-content-between align-items-center d-flex">{{
													'paymentType.totalPaidPrice' | translate }}
													<span>
														{{(datauserModule.totalSubscriptionPrice) }}
													</span>

												</h6>

												<h6 class="justify-content-between align-items-center d-flex"
													*ngIf="datauserModule.isPaid == false">{{
													'paymentType.totalNotPaidPrice' | translate }}
													<span>
														{{(datauserModule.lastPaidPrice) }}
													</span>
												</h6>



										<h6 class="justify-content-between align-items-center d-flex">{{
											'packages.subscriptionStartDate' | translate }} <span>{{
												datauserModule.subscriptionStartDate|date }}</span>
										</h6>

										<h6 class="justify-content-between align-items-center d-flex">{{
											'packages.subscriptionExpiryDate' | translate }}<span>{{
												datauserModule.subscriptionExpiaryDate |date}}</span> </h6>
									</div>
									<div class="col-md-6 col-12">
										<h6 class="justify-content-between align-items-center d-flex">
											{{'modules.instrumentPattrenPrice'|translate}}
											<span>{{datauserModule.instrumentPattrenPrice }}</span>
										</h6>
										<h6 class="justify-content-between align-items-center d-flex">
											{{'modules.billPattrenPrice'|translate}}
											<span>{{datauserModule.billPattrenPrice
												}}</span>
										</h6>
										<h6 *ngIf="datauserDetailsPackageDtos?.subscriptionPrice==null||datauserDetailsPackageDtos?.subscriptionPrice==0"
											class="justify-content-between align-items-center d-flex">
											{{'modules.IsFree'|translate}} <span>

											</span></h6>
										<!-- <h6 class="justify-content-between align-items-center d-flex">
											{{'packages.customize-modules'| translate}}
											<span>{{datauserModule.isPackageModule}}</span>
										</h6> -->
										<h6 class="justify-content-between align-items-center d-flex"
											*ngIf="userDetailsModuleDtos!=null && userDetailsModuleDtos[0]?.isPackageModule">
											{{'packages.numberOfUsers'|translate}} <span
												*ngIf="userDetailsModuleDtos!=null &&userDetailsModuleDtos[0]?.isPackageModule">{{
												datauserModule.numberOfUser }}</span></h6>
										<h6 class="justify-content-between align-items-center d-flex"
											*ngIf="userDetailsModuleDtos!=null &&userDetailsModuleDtos[0]?.isPackageModule">
											{{'packages.numberOfCompanies'|translate}} <span
												*ngIf="userDetailsModuleDtos!=null &&userDetailsModuleDtos[0]?.isPackageModule">{{
												datauserModule.numberOfCompanies }}</span></h6>
										<h6 class="justify-content-between align-items-center d-flex"
											*ngIf="userDetailsModuleDtos!=null &&userDetailsModuleDtos[0]?.isPackageModule">
											{{'packages.numberOfBranches'|translate}} <span
												*ngIf="userDetailsModuleDtos!=null &&userDetailsModuleDtos[0]?.isPackageModule">{{
												datauserModule.numberOfBranches }}</span></h6>
										<h6 class="justify-content-between align-items-center d-flex">{{
											'packages.payment' | translate }} <span>
												{{(datauserModule.paymentTypeString) }}</span></h6>

									</div>
								</div>
							</div>
						</div>

					</div>

				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" (click)="closePopupModule()">
						{{'landingpage.Close' | translate}}
					</button>
				</div>


			</div>


		</div>

	</div>
</div>
