<div class="card">
	<div class="card-body">
		<section class="inner-page-wrapper inner-page-auction">
			<div class="container-fluid p-0">
				<div class="col-md-12 col-12">
					<angular-slickgrid [gridOptions]="gridOptions" [columnDefinitions]="columnNames"
						gridId="packageGrid" [dataset]="datasetGeneral"
						(onClick)="click($event.detail.eventData, $event.detail.args)"
						(onAngularGridCreated)="angularGridReady($event.detail)">
					</angular-slickgrid>
				</div>
			</div>
		</section>
	</div>
</div>