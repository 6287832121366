import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuleDto } from '../../models/module';
import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';
import { TranslateService } from '@ngx-translate/core';
import { ModuleService } from '../../services/module.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/shared/common-services/shared-service';
import { NAME_REQUIRED_VALIDATORS } from 'src/app/shared/constants/input-validators';
import { Subscription } from 'rxjs';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';
import { navigateUrl } from 'src/app/shared/helper/helper-url';

@Component({
  selector: 'app-edit-modules',
  templateUrl: './edit-modules.component.html',
  styleUrls: ['./edit-modules.component.scss']
})
export class EditModulesComponent implements OnInit {
   moduleForm!: FormGroup;
   url: any;
   id: any = 0;
   currnetUrl;
   module: ModuleDto[] = [];
   updateUrl: string = '/security/modules/edit-module/';
   listUrl: string = '/security/modules';
   toolbarPathData: ToolbarPath = {
    listPath: '',
    updatePath: this.updateUrl,
    addPath: '',
    componentList: this.translate.instant("modules.Module-information"),
    componentAdd: '',

  };
  response: any;
  errorMessage = '';
  errorClass = '';
  submited: boolean = false;
  searchText: string;
  subsList: Subscription[] = [];
  currentBtnResult;
  constructor(private translate : TranslateService,
	private moduleService: ModuleService ,
	private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService
) {

	this.defineModuleForm();
}

  ngOnInit(): void {
	//this.spinner.show();
	this.sharedService.changeButton({ action: 'EditOnly' } as ToolbarData);
    this.getRouteData();
    this.currnetUrl = this.router.url;
    this.changePath();
    this.listenToClickedButton();
    this.spinner.hide();
 }

  getRouteData()
  {
	let sub = this.route.params.subscribe((params) => {
		if (params['id'] != null) {
		  this.id = params['id'];

		  if (this.id) {
			this.getModuleById(this.id).then(a => {

			  this.spinner.hide();

			}).catch(err => {
			  this.spinner.hide();

			});
		  }

		}
	  });
	  this.subsList.push(sub);
  }

  getModuleById(id : any)
  {
	return new Promise<void>((resolve, reject) => {
		let sub = this.moduleService.getModule(id).subscribe({
		  next: (res: any) => {
			resolve();

			this.moduleForm.setValue({
			  id: res.response?.id,
			  nameAr: res.response?.nameAr,
			  nameEn: res.response?.nameEn,
			  monthlySubscriptionPrice: res.response?.monthlySubscriptionPrice,
			  yearlySubscriptionPrice: res.response?.yearlySubscriptionPrice,
			  fullBuyingSubscriptionPrice: res.response?.fullBuyingSubscriptionPrice,
			  otherUserMonthlySubscriptionPrice: res.response?.otherUserMonthlySubscriptionPrice,
			  otherUserYearlySubscriptionPrice: res.response?.otherUserYearlySubscriptionPrice,
			  otherUserFullBuyingSubscriptionPrice: res.response?.otherUserFullBuyingSubscriptionPrice,
			  yearlyQuarterSubscriptionPrice : res.response?.yearlyQuarterSubscriptionPrice,
			  otherUserYearlyQuarterSubscriptionPrice : res.response?.otherUserYearlyQuarterSubscriptionPrice,
			  yearlyHalfSubscriptionPrice : res.response?.yearlyHalfSubscriptionPrice,
			  otherUserYearlyHalfSubscriptionPrice : res.response?.otherUserYearlyHalfSubscriptionPrice,
			  isFree : res.response?.isFree,
			  isActive: res.response?.isActive ,
			  instrumentPattrenPrice : res.response?.instrumentPattrenPrice,
	 		  billPattrenPrice : res.response?.billPattrenPrice,
			});

			console.log(
			  this.moduleForm.value
			);
  		  },
		  error: (err: any) => {
			reject(err);
		  },
		  complete: () => {
			console.log('complete');
		  },
		});
		this.subsList.push(sub);

	  });
  }

  defineModuleForm()
  {
	this.moduleForm = this.fb.group({
		id: 0,
		nameAr: NAME_REQUIRED_VALIDATORS,
		nameEn: NAME_REQUIRED_VALIDATORS,
		monthlySubscriptionPrice :[ null , Validators.required ],
	    yearlySubscriptionPrice  :[ null , Validators.required ],
		fullBuyingSubscriptionPrice  :[ null , Validators.required ],
		otherUserMonthlySubscriptionPrice :[ null , Validators.required ],
		otherUserYearlySubscriptionPrice  :[null , Validators.required ],
		otherUserFullBuyingSubscriptionPrice  :[null , Validators.required ],
		yearlyQuarterSubscriptionPrice: [null , Validators.required ],
		otherUserYearlyQuarterSubscriptionPrice : [null , Validators.required ] ,
		yearlyHalfSubscriptionPrice : [null , Validators.required ],
		otherUserYearlyHalfSubscriptionPrice : [null , Validators.required ],
		instrumentPattrenPrice : [null],
		billPattrenPrice : [null],
		isFree : false,
		isActive: true
	  });
  }

  changePath() {
    this.sharedService.changeToolbarPath(this.toolbarPathData);
  }
  listenToClickedButton() {
    let sub = this.sharedService.getClickedbutton().subscribe({
      next: (currentBtn: ToolbarData) => {
        currentBtn;

        if (currentBtn != null) {
          if (currentBtn.action == ToolbarActions.List) {
            this.sharedService.changeToolbarPath({
              listPath: this.listUrl,
            } as ToolbarPath);
            this.router.navigate([this.listUrl]);
          } else if (currentBtn.action == ToolbarActions.Update) {
            this.onUpdate();
          }
        }
      },
    });
    this.subsList.push(sub);
  }

  onUpdate()
  {

		this.spinner.show();
		this.confirmUpdate().then(a => {
		  this.spinner.hide();
		}).catch(e => {
		  this.spinner.hide();
		});

  }

  ngOnDestroy() {
    this.subsList.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  confirmUpdate()
  {
	this.moduleForm.value.id = this.id;

    return new Promise<void>((resolve, reject) => {
      let sub = this.moduleService.updateModule({
		id : this.id ,
		inputDto : this.moduleForm.value
	  }).subscribe({
        next: (result: any) => {

          this.response = { ...result.response };
          this.defineModuleForm();
          this.submited = false;
          navigateUrl(this.listUrl, this.router);
        },
        error: (err: any) => {
          reject(err);
        },
        complete: () => {
          console.log('complete');
        },
      });
      this.subsList.push(sub);

    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.moduleForm.controls;
  }

}
