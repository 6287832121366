<div class="container-fluid">
	<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
		<p style="color: white ;font-size: 20px;"> {{'general.loading'|translate}}... </p>
	</ngx-spinner>


	<div class="card">
		<!-- <div class="card-header">

		</div> -->
		<div class="card-body py-5">
			<div class="align-items-center m-0 row">

				<div class="col col-md-6">
					<div class="media">
						<label class="label-name"> {{'configuration.additional-company-price'|translate}}
						</label>
						<div class="">
							<div class="">
								<input type="number" class="form-control" [(ngModel)]="additionalCompanyPrice"
									name="additionalCompanyPrice">

							</div>
						</div>
					</div>
				</div>

				<div class="col col-md-6">
					<div class="media">
						<label class="label-name"> {{'configuration.additional-branch-price'|translate}}
						</label>
						<div class="">
							<div class="">
								<input type="number" class="form-control" [(ngModel)]="additionalBranchPrice"
									name="additionalBranchPrice">

							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>









</div>