import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, AfterContentInit, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { AngularGridInstance, Column, Formatters, GridOption } from "angular-slickgrid";
import { ExcelExportService } from "@slickgrid-universal/excel-export";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-slick-grid",
	templateUrl: "./slick-grid.component.html",
	styleUrls: ["./slick-grid.component.scss"],
})
export class SlickGridComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy, AfterContentInit {
	lang = localStorage.getItem("language");

	//----------------------
	@Input() gridId: string;
	@Input() angularGrid!: AngularGridInstance;
	@Input() gridOptions!: GridOption;
	@Input() columnDefinitions: Column[] = [];
	@Input() dataset: any[] = [];
	@Input() direction: string = "rtl";
	@Input() hiddenEdit: boolean = false;
	@Input() hiddenDelete: boolean = false;

	//-----------------------
	subsList: Subscription[] = [];
	editFormatIcon() {
		//plain text value
		return "<i class=' fa fa-pencil'></i>";
	}
	deleteFormatIcon() {
		//plain text value

		return "<i class='fa fa-trash'></i>";
	}
	CheckBoxFormatIcon() {
		//plain text value

		return "<input id='yourID' type='checkbox' />";
	}
	//-------------------------

	@Output() onEditItem: EventEmitter<any> = new EventEmitter();
	@Output() onDeleteItem: EventEmitter<any> = new EventEmitter();
	@Output() onCheckItem: EventEmitter<any> = new EventEmitter();

	constructor(private translate: TranslateService) {}

	ngOnInit() {
		this.defineGrid();
	}
	ngOnDestroy() {
		this.subsList.forEach((s) => {
			if (s) {
				s.unsubscribe();
			}
		});
		//unsubscribeAllObservables(this.subsList);
	}
	ngAfterContentInit(): void {}
	ngOnChanges(changes: SimpleChanges): void {
		if (this.angularGrid) {
			this.angularGrid.paginationService.goToFirstPage();
		}
	}
	ngAfterViewInit() {}
	defineGrid() {
		if (this.hiddenEdit == false) {
			this.columnDefinitions.push({
				id: "edit",
				name: this.lang == "en-us" ? "edit" : "تعديل",
				field: "id",
				minWidth: 30,
				maxWidth: 60,
				formatter: Formatters.editIcon,
				excludeFromExport: true,
				onCellClick: (e, args) => {
					const dataContext = args.dataContext;
					this.onEditItem.emit(dataContext.id);
				},
			});
		}

		if (this.hiddenDelete == false) {
			this.columnDefinitions.push({
				id: "delete",
				name: this.lang == "en-us" ? "delete" : "حذف",
				field: "id",
				minWidth: 30,
				maxWidth: 60,
				formatter: Formatters.deleteIcon,
				excludeFromExport: true,
				onCellClick: (e, args) => {
					const dataContext = args.dataContext;
					this.onDeleteItem.emit(dataContext.id);
				},
			});
		}

		this.columnDefinitions.push({
			id: "check",
			name: this.lang == "en-us" ? "check" : "أختر",
			field: "id",
			minWidth: 30,
			maxWidth: 60,
			formatter: this.CheckBoxFormatIcon,
			excludeFromExport: true,
			onCellClick: (e, args) => {
				const dataContext = args.dataContext;
				this.onCheckItem.emit(dataContext.id);
			},
		});
		this.columnDefinitions = [...this.columnDefinitions];

		this.gridOptions = {
			autoResize: {
				container: ".trading-platform",
				rightPadding: 0,
				bottomPadding: 10,
			},
			formatterOptions: {
				displayNegativeNumberWithParentheses: true,
				thousandSeparator: ",",
			},
			draggableGrouping: {
				dropPlaceHolderText: "",
				deleteIconCssClass: "fa fa-times",
			},
			//enableDraggableGrouping: true,
			createPreHeaderPanel: true,
			showPreHeaderPanel: true,
			preHeaderPanelHeight: 40,
			enableCellNavigation: true,
			enableFiltering: true,
			cellHighlightCssClass: "changed",
			gridWidth: "auto",
			enableExcelExport: true,
			enableExcelCopyBuffer: true,
			excelExportOptions: {
				exportWithFormatter: true,
				customColumnWidth: 15,

				// you can customize how the header titles will be styled (defaults to Bold)
				columnHeaderStyle: { font: { bold: true, italic: true } },
			},
			registerExternalResources: [new ExcelExportService()],
			enableContextMenu: true,
			enableCellMenu: true,
			enableTranslate: true,
			i18n: this.translate,
			// load Context Menu structure
			//	contextMenu: this.getContextMenuOptions(),
			enablePagination: true,
			pagination: {
				pageSizes: [10, 25, 50, 100, 1000, 10000, 100000],
				pageSize: 50,
				pageNumber: 1,
			},
		};
	}

	angularGridReady(angularGrid: AngularGridInstance) {
		this.angularGrid = angularGrid;
		this.angularGrid.paginationService.goToFirstPage();
	}
}
