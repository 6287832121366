import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UserService {


	private LoggedIn = new BehaviorSubject<boolean>(false);
	getLoggedIn = this.LoggedIn.asObservable();



	private databaseCreated = new BehaviorSubject<boolean>(false);
	getDatabaseCreated = this.databaseCreated.asObservable();
    constructor(
        private router: Router
    ) { }

	setLoggedIn(islogged : any)
	{
		this.LoggedIn.next(islogged);
	}

	setDatabaseCreated(databaseCreated : boolean)
	{
		this.databaseCreated.next(databaseCreated);
	}

	logoutCustomer() {
        this.removeToken();
        this.removeRefreshToken();
      //  this.removeGuid();
        this.removeUserId();
        this.removeEmail();
		this.LoggedIn.next(false);
        this.router.navigate(['/landing-page/login-customer'])
    }

    getCurrentSystemLanguage(): string {

        let language = localStorage.getItem('language');
        if (language) return language;
        else {
            localStorage.setItem('language', 'ar');
            return 'ar';
        }
    }
    setLanguage(language: string) {
        localStorage.setItem('language', language)
    }
    isLoggedIn(): boolean {
        let token = localStorage.getItem('token');
        let refreshToken = localStorage.getItem('refreshToken');
        if (token) return true;

        return false;
    }
	getClaims()
	{
		let token = localStorage.getItem('token');
		let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
		return decodedJWT;
	}
    setToken(token: string) {
        localStorage.setItem('token', token);
    }
    setRefreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }
    getToken(): string | null {
        return localStorage.getItem('token');
    }
    getRefreshToken(): string | null {
        return localStorage.getItem('refreshToken');
    }
    removeToken() {
        localStorage.removeItem('token');
    }
    removeRefreshToken() {
        localStorage.removeItem('refreshToken');
    }
    logout() {
        debugger
        const routeExists = this.router.url.includes('landing-page/login-customer');
        this.removeToken();
        this.removeRefreshToken();
       // this.removeGuid();
        this.removeUserId();
        this.removeEmail();
		if(localStorage.getItem('type') == "Owner"||routeExists)
		{
			this.router.navigate(['/landing-page/login-customer'])
		}
		else
		{
			this.router.navigate(['/authentication/login'])
		}
		localStorage.removeItem('userName');
		localStorage.removeItem('type')

    }

	logoutToHomePage()
	{
		this.removeToken();
        this.removeRefreshToken();
      //  this.removeGuid();
        this.removeUserId();
        this.removeEmail();
		localStorage.removeItem('userName');
		localStorage.removeItem('type');
		this.router.navigate(['/landing-page/home-page'])
	}


    setProfileImage(imageUrl: string) {
        if (imageUrl) {
            localStorage.setItem('profileImageUrl', imageUrl);
        }
    }
    getProfileImage(): string {
        let image = localStorage.getItem('profileImageUrl');
        if (image) return image;
        else return '';
    }

    setUserName(name: string) {
        if (name) {
            localStorage.setItem('userName', name);
        }
    }

    getUserName(){
        let name = localStorage.getItem('userName');
        if(name)return name;
        else return '';
    }

	setUserType(type : string)
	{
		if (type) {
            localStorage.setItem('type', type);
        }
    }
	getUserType(){
        let type = localStorage.getItem('type');
        if(type)return type;
        else return '';
    }
    setGuid(guid: string) {
		localStorage.setItem('guidToken', guid);
	}
	removeGuid() {
		localStorage.removeItem('guidToken');
	}
	getGuid(): string {
		return localStorage.getItem('guidToken') || '';
	}
    getUserId() {
		let name = localStorage.getItem("userId");
		if (name) return name;
		else return "";
	}
    setUserId(userId) {
		localStorage.setItem('userId', userId);
	}
    removeEmail() {
		localStorage.removeItem('email');
	}
	getEmail(): string {
		return localStorage.getItem('email') || '';
	}
    setEmail(email) {
		localStorage.setItem('email', email);
	}
    removeUserId() {
		localStorage.removeItem('userId');
	}
}
