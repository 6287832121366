import { element } from 'protractor';
import { RegisterService } from './../../services/register.service';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/shared/common-services/user.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { TechnicalSupportService } from 'src/app/administration/authentication/services/technical-support-service';

@Component({
	selector: 'app-landing-page-header',
	templateUrl: './landing-page-header.component.html',
	styleUrls: ['./landing-page-header.component.scss']
})
export class LandingPageHeaderComponent implements OnInit {
	currentSystemLanguage = 'ar';
	isHeaderSticky = false;
	@Input() public isLoggedIn: boolean = false;
	@Input() public userName: string = '';
	constructor(
		public navServices: NavService,
		private router: Router,
		public authService: TechnicalSupportService,

		private registerService: RegisterService,
		private spinner: NgxSpinnerService,
		private translate: TranslateService,
		private loggedUser: UserService) {



		this.loggedUser.getLoggedIn.subscribe((element: boolean) => {
			this.isLoggedIn = element
			if (element == true)
				this.userName = this.loggedUser.getUserName();



		});
	}
	redirectToSection() {

		window.location.hash = 'about-erp';
	}
	openProfile() {
		this.router.navigate(['/landing-page//customer-profile'])
	}
	ngOnInit(): void {


		this.loggedUser.getLoggedIn.subscribe((element: boolean) => {
			this.isLoggedIn = element
			if (element == true) {

				this.userName = this.loggedUser.getUserName();
			}
		});
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.

		this.isLoggedIn = this.loggedUser.isLoggedIn();
		if (this.isLoggedIn == true) {
			this.userName = this.loggedUser.getUserName();
		}

	}
	languageToggle() {

		this.navServices.language = !this.navServices.language;
		let lang =  localStorage.getItem('language');
        if(lang == 'ar')
		{
			this.translate.setDefaultLang('en-us')
			this.translate.use('en-us');
		}else{

			this.translate.setDefaultLang('ar');
			this.translate.use('ar');
		}

		window.location.reload();

	}

	logout() {
		setInterval(() => {
			this.spinner.show();
		}, 200);
		this.authService.logout().subscribe(
			next => {

				this.spinner.hide();


			},
			error => {


				console.log(error)

			}
		)
		this.isLoggedIn = false;
		this.loggedUser.logoutToHomePage();
	}

	login() {
		this.router.navigate(['/landing-page/login-customer'])
	}

	register() {
		this.router.navigate(['/landing-page/register'])
	}
	toggleLanguage() {
		this.currentSystemLanguage = this.currentSystemLanguage === 'en-us' ? 'ar' : 'en-us';

		// تحديد اتجاه النص بناءً على اللغة
		if (this.currentSystemLanguage === 'ar') {
			document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
			this.translate.use(this.currentSystemLanguage);
		} else {
			document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
			this.translate.use(this.currentSystemLanguage);
		}

		// تحديث اللغة بدون إعادة تحميل الصفحة
		this.router.navigate([], { queryParams: { lang: this.currentSystemLanguage }, queryParamsHandling: 'merge' });
	}
}
