import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../../shared/common-services/shared-service';
import { ToolbarPath } from '../../../../shared/interfaces/toolbar-path';
import { NgxSpinnerService } from 'ngx-spinner';
import { CODE_REQUIRED_VALIDATORS, EMAIL_VALIDATORS, NAME_REQUIRED_VALIDATORS, PHONE_VALIDATORS, REQUIRED_VALIDATORS } from '../../../../shared/constants/input-validators';
import { Subscription } from 'rxjs';
import { ToolbarData } from '../../../../shared/interfaces/toolbar-data';
import { ToolbarActions } from '../../../../shared/enum/toolbar-actions';
import { navigateUrl } from '../../../../shared/helper/helper-url';
import { CustomerServiceProxy } from '../../services/customer-service'
import { CountryServiceProxy } from 'src/app/administration/master-codes/services/country.servies';
import { CountryDto } from 'src/app/administration/master-codes/models/country';
import { BusinessServiceProxy } from 'src/app/administration/master-codes/services/business-field.servies';
import * as Tabulator from 'tabulator-tables/dist/js/tabulator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditSubscriptionsComponent } from '../add-edit-subscriptions/add-edit-subscriptions.component';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';
import format from 'date-fns/format';
import { ICustomerDto } from '../../models/customer';
import { DeleteSubscriptionsComponent } from '../delete-subscriptions/delete-subscriptions.component';
@Component({
	selector: 'app-add-edit-customer',
	templateUrl: './add-edit-customer.component.html',
	styleUrls: ['./add-edit-customer.component.scss']
})
export class AddEditCustomerComponent implements OnInit {
	//#region Main Declarations
	customerForm!: FormGroup;
	id: any = 0;
	currnetUrl;
	public show: boolean = false;
	lang = localStorage.getItem("language")
	customer: ICustomerDto[] = [];
	addUrl: string = '/security/customer/add-customer';
	updateUrl: string = '/security/customer/update-customer/';
	listUrl: string = '/security/customer';
	toolbarPathData: ToolbarPath = {
		listPath: '',
		updatePath: this.updateUrl,
		addPath: this.addUrl,
		componentList: this.translate.instant("component-names.customer"),
		componentAdd: '',

	};
	response: any;
	errorMessage = '';
	errorClass = '';
	submited: boolean = false;
	countriesList: CountryDto[] = [];
	businessList: CountryDto[] = [];
	companySizeList: string[] = [];
	checksApplications: { descriptionAr: string; descriptionEn: string; value: string; check: boolean; }[];
	constructor(
		private countryService: CountryServiceProxy,
		private businessService: BusinessServiceProxy,
		private customerService: CustomerServiceProxy,
		private router: Router,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private sharedService: SharedService, private translate: TranslateService,
		private modelService: NgbModal,

	) {
		this.defineCustomerForm();
	}
	//#endregion

	//#region ngOnInit
	ngOnInit(): void {
		this.getCompanySize();
		this.getApplications();
		this.spinner.show();
		Promise.all([
			this.getCountries(),
			this.getBusiness()
		]).then(a => {
			this.getRouteData();
			this.currnetUrl = this.router.url;
			if (this.currnetUrl == this.addUrl) {
				this.getCustomerCode();
			}
			this.changePath();
			this.listenToClickedButton();
		}).catch((err) => {

			this.spinner.hide();
		})


	}

	//#endregion

	//#region ngOnDestroy
	ngOnDestroy() {
		this.subsList.forEach((s) => {
			if (s) {
				s.unsubscribe();
			}
		});
	}
	//#endregion
	getRouteData() {
		let sub = this.route.params.subscribe((params) => {
			if (params['id'] != null) {
				this.id = params['id'];
				if (this.id) {
					this.getCustomerById(this.id).then(a => {

						this.spinner.hide();


					}).catch(err => {
						this.spinner.hide();

					});
				}
				else {
					this.sharedService.changeButton({ action: 'New' } as ToolbarData);
					this.spinner.hide();
				}
			}
			else {
				this.sharedService.changeButton({ action: 'New' } as ToolbarData);
				this.spinner.hide();
			}
		});
		this.subsList.push(sub);

	}
	//#region Authentications

	//#endregion

	//#region Subscriptionss

	//#endregion

	//#region  State Management
	//#endregion

	//#region Basic Data
	///Geting form dropdown list data
	defineCustomerForm() {
		this.customerForm = this.fb.group({
			id: 0,
			nameAr: NAME_REQUIRED_VALIDATORS,
			nameEn: NAME_REQUIRED_VALIDATORS,
			code: CODE_REQUIRED_VALIDATORS,
			isActive: true,
			email: EMAIL_VALIDATORS,
			phoneNumber: null,
			countryId: REQUIRED_VALIDATORS,
			businessId: null,
			companySize: null,
			multiCompanies: false,
			multiBranches: false,
			numberOfCompany: null,
			numberOfBranch: null,
			// applications: ""
		});
		this.customerForm.controls['numberOfCompany'].disable();
		this.customerForm.controls['numberOfBranch'].disable();
	}
	getCountries() {
		return new Promise<void>((resolve, reject) => {
			let sub = this.countryService.getDdl().subscribe({
				next: (res) => {

					if (res.success) {
						this.countriesList = res.response;

					}


					resolve();

				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});

			this.subsList.push(sub);
		});

	}
	getBusiness() {
		return new Promise<void>((resolve, reject) => {
			let sub = this.businessService.getDdl().subscribe({
				next: (res) => {

					if (res.success) {
						this.businessList = res.response;

					}


					resolve();

				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});

			this.subsList.push(sub);
		});

	}
	getCompanySize() {
		this.companySizeList = ["1-5", "5-25", "25-500", ">500"]
	}
	getApplications() {
		this.checksApplications = [
			{ descriptionAr: 'مبيعات', descriptionEn: 'Sales', value: '1', check: false },
			{ descriptionAr: "إدارة علاقات العملاء", descriptionEn: 'CRM', value: '2', check: false },
			{ descriptionAr: "رواتب", descriptionEn: 'Payroll', value: '3', check: false },
			{ descriptionAr: "مشتريات", descriptionEn: 'Purchase', value: '4', check: false },
			{ descriptionAr: "محاسبة", descriptionEn: 'Accounting', value: '5', check: false }
		];
	}
	applications = [];

	onCheckChange(value) {

		if (!this.applications.includes(value)) {
			this.applications.push(value);
		} else {
			var index = this.applications.indexOf(value);
			if (index > -1) {
				this.applications.splice(index, 1);
			}
		}

	}

	onChangeMultiCompanies(event) {

		if (event.target.checked) {
			this.customerForm.controls['numberOfCompany'].enable();
		}
		else {
			this.customerForm.controls['numberOfCompany'].disable();
			this.customerForm.value.numberOfCompany = null
		}


	}

	onChangeMultiBranches(event) {
		if (event.target.checked) {
			this.customerForm.controls['numberOfBranch'].enable();
		}
		else {
			this.customerForm.controls['numberOfBranch'].disable();
			this.customerForm.value.numberOfBranch = null
		}

	}
	//#endregion

	checkMultiCompanies(isMultiCompanies) {
		if (isMultiCompanies) {
			this.customerForm.controls['numberOfCompany'].enable();
		} else {
			this.customerForm.controls['numberOfCompany'].disable();

		}
	}
	checkMultiBranchies(isMultiBranchies) {
		if (isMultiBranchies) {
			this.customerForm.controls['numberOfBranch'].enable();
		} else {
			this.customerForm.controls['numberOfBranch'].disable();

		}
	}
	//#region CRUD Operations
	getCustomerById(id: any) {
		return new Promise<void>((resolve, reject) => {
			let sub = this.customerService.getCustomer(id).subscribe({
				next: (res: any) => {
					resolve();
					this.customerForm.setValue({
						id: res.response?.id,
						nameAr: res.response?.nameAr,
						nameEn: res.response?.nameEn,
						code: res.response?.code,
						isActive: res.response?.isActive,
						email: res.response?.email,
						phoneNumber: res.response?.phoneNumber,
						countryId: res.response?.countryId,
						businessId: res.response?.businessId,
						companySize: res.response?.companySize,
						multiCompanies: res.response?.multiCompanies,
						multiBranches: res.response?.multiBranches,
						numberOfCompany: res.response?.numberOfCompany,
						numberOfBranch: res.response?.numberOfBranch,
						// applications: res.response?.applications,

					});
					this.checkMultiCompanies(res.response?.multiCompanies);
					this.checkMultiBranchies(res.response?.multiBranches)
					this.subscriptions = res.response?.subscriptionDto;
					this.drawTable();
					console.log(
						'this.customerForm.value set value',
						this.customerForm.value
					);
				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}
	showPassword() {
		this.show = !this.show;
	}
	getCustomerCode() {
		return new Promise<void>((resolve, reject) => {
			let sub = this.customerService.getLastCode().subscribe({
				next: (res: any) => {
					resolve();
					this.toolbarPathData.componentList = this.translate.instant("component-names.role");
					this.customerForm.patchValue({
						code: res.response
					});

				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);
		});
	}
	//#endregion

	//#region Helper Functions

	get f(): { [key: string]: AbstractControl } {
		return this.customerForm.controls;
	}


	//#endregion
	//#region Tabulator
	subsList: Subscription[] = [];
	currentBtnResult;
	listenToClickedButton() {
		let sub = this.sharedService.getClickedbutton().subscribe({
			next: (currentBtn: ToolbarData) => {
				currentBtn;

				if (currentBtn != null) {
					if (currentBtn.action == ToolbarActions.List) {
						this.sharedService.changeToolbarPath({
							listPath: this.listUrl,
						} as ToolbarPath);
						this.router.navigate([this.listUrl]);
					} else if (currentBtn.action == ToolbarActions.Save) {
						this.onSave();
					} else if (currentBtn.action == ToolbarActions.New) {
						this.toolbarPathData.componentAdd = this.translate.instant("component-names.add-customer");
						this.defineCustomerForm();
						this.sharedService.changeToolbarPath(this.toolbarPathData);
					} else if (currentBtn.action == ToolbarActions.Update) {
						this.onUpdate();
					}
				}
			},
		});
		this.subsList.push(sub);
	}
	changePath() {
		this.sharedService.changeToolbarPath(this.toolbarPathData);
	}
	confirmSave() {
		
		return new Promise<void>((resolve, reject) => {
			let sub = this.customerService.createCustomer(this.customerForm.value).subscribe({
				next: (result: any) => {
					resolve();
					this.response = { ...result.response };
					this.defineCustomerForm();

					this.submited = false;

					navigateUrl(this.listUrl, this.router);
				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}
	onSave() {
		if (this.customerForm.valid) {
			this.spinner.show();
			
			this.confirmSave().then(a => {
				
				this.spinner.hide();
			}).catch(e => {
				
				this.spinner.hide();
			});

		} else {

			return this.customerForm.markAllAsTouched();
		}
	}
	confirmUpdate() {
		this.customerForm.value.id = this.id;
		return new Promise<void>((resolve, reject) => {
			let sub = this.customerService.updateCustomer(this.customerForm.value).subscribe({
				next: (result: any) => {
					this.response = { ...result.response };
					this.defineCustomerForm();
					this.submited = false;
					navigateUrl(this.listUrl, this.router);
				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}

	onUpdate() {
		if (this.customerForm.valid) {
			this.spinner.show();
			this.confirmUpdate().then(a => {
				this.spinner.hide();
			}).catch(e => {
				this.spinner.hide();
			});
		}

		else {

			return this.customerForm.markAllAsTouched();
		}
	}

	//#endregion
	//Subscriptions region

	disabledSave = false;
	disabledUpate = false;
	disabledNew = false;
	disabledDlete = false

	doUpdateEvent(id) {
		const modalRef = this.modelService.open(AddEditSubscriptionsComponent, { size: 'lg' });
		modalRef.componentInstance.name = this.translate.instant("general.add-edit");
		modalRef.componentInstance.customerId = this.id;
		modalRef.componentInstance.id = id;
		modalRef.result.then((result) => {
			if (result) {
				this.getCustomerById(result)
			}
		});
	}

	doNewEvent() {
		const modalRef = this.modelService.open(AddEditSubscriptionsComponent, { size: 'lg' });
		modalRef.componentInstance.name = this.translate.instant("general.add-edit");
		modalRef.componentInstance.customerId = this.id;
		modalRef.result.then((result) => {
			if (result) {
				this.getCustomerById(result)
			}
		});
	}


	showConfirmDeleteMessage(id) {
		const modalRef = this.modelService.open(MessageModalComponent);
		modalRef.result.then((rs) => {
			console.log(rs);
			if (rs == 'Confirm') {
				this.spinner.show();
				let sub = this.customerService.deleteCustomerSubscription(id).subscribe(
					() => {
						//reloadPage()
						this.getCustomerById(this.id);

					});
				this.subsList.push(sub);
				this.spinner.hide();

			}
		});
	}

	editFormatIcon() { //plain text value

		return "<i class='fa fa-edit'></i>";
	};
	deleteFormatIcon() { //plain text value

		return "<i class='fa fa-trash'></i>";
	};
	CheckBoxFormatIcon() { //plain text value

		return "<input id='yourID' type='checkbox' />";
	};
	subscriptions: any = [];
	columnNames = [
		this.lang == 'ar'
			? {
				title: '  تاريخ بداية العقد', width: 300, field: 'contractStartDate', formatter: function (cell, formatterParams, onRendered) {
					var value = cell.getValue();
					value = format(new Date(value), 'dd-MM-yyyy');;
					return value;
				}
			} : {
				title: 'Contract Start Date', width: 300, field: 'contractStartDate'
				, formatter: function (cell, formatterParams, onRendered) {
					var value = cell.getValue();
					value = format(new Date(value), 'dd-MM-yyyy');;
					return value;
				}
			},
		this.lang == 'ar'
			? {
				title: '  تاريخ نهاية العقد', width: 300, field: 'contractEndDate', formatter: function (cell, formatterParams, onRendered) {
					var value = cell.getValue();
					value = format(new Date(value), 'dd-MM-yyyy');;
					return value;
				}
			} : {
				title: ' Contract End Date', width: 300, field: 'contractEndDate', formatter: function (cell, formatterParams, onRendered) {
					var value = cell.getValue();
					value = format(new Date(value), 'dd-MM-yyyy');;
					return value;
				}
			},
		this.lang == "ar" ? {
			title: "حذف",

			field: "id", formatter: this.deleteFormatIcon, cellClick: (e, cell) => {

				this.showConfirmDeleteMessage(cell.getRow().getData().id);
			},
			//  visible: false,
		} :
			{
				title: "Delete",
				field: "id", formatter: this.deleteFormatIcon, cellClick: (e, cell) => {

					this.showConfirmDeleteMessage(cell.getRow().getData().id);
				},
				//   visible: false,
			}

		,

		this.lang == "ar" ? {
			title: "تعديل",
			field: "id", formatter: this.editFormatIcon, cellClick: (e, cell) => {

				this.doUpdateEvent(cell.getRow().getData().id);
			}
		}
			:

			{
				title: "Edit",
				field: "id", formatter: this.editFormatIcon, cellClick: (e, cell) => {

					this.doUpdateEvent(cell.getRow().getData().id);
				}
			},



	];
	exTable: any;
	filterParam: string = '';
	// subscriptionsData=[]
	tab = document.createElement('div');
	drawTable() {
		this.exTable = new Tabulator(this.tab, {
			height: 130,
			layout: 'fitColumns',
			columns: this.columnNames,
			movableColumns: true,
			data: this.subscriptions,
			//Local Pagination
			pagination: "local",
			paginationSize: 50,
			paginationSizeSelector: [5, 10, 20, 50, 100, 1000, 10000, 100000],

			paginationCounter: "rows",
		});
		//    this.exTable.setData(persons);
		document.getElementById('ex-table-div').appendChild(this.tab);
	}
	onCheckItem(item) {

		let entity = this.subscriptions.find(c => c.id == item.id);
		entity.isChecked = !entity.isChecked;
	}
	selectAll(e, column) {

		const all = document.getElementById('all') as HTMLInputElement;
		// all.onclick = (e) => this.onCheckItem(e);
		//  this.selectAll(all.checked)
		column.getCells().forEach(
			cell => {

				var value = cell.getData();
				const ele = document.getElementById(value.id) as HTMLInputElement;
				if (all.checked) {
					ele.checked = true;


				}

				else {
					ele.checked = false;


				}

			}
		);


	}
	subscriptionsSelectAll() {

		this.subscriptions.forEach(
			cell => {


				const ele = document.getElementById(cell.id) as HTMLInputElement;
				if (ele.checked) {
					cell.isChecked = true;

				}

				else {
					cell.isChecked = false;


				}

			})
	}
}

