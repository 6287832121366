export const environment = {
	//apiUrl: "https://localhost:7198",
	//apiUrl: "http://195.88.87.244:5050",
	//apiUrl: "http://195.88.87.244:5050",
	//apiUrl: "https://195.88.87.244:6060",
	//apiUrl: "http://94.237.99.172:8887",
	//apiUrl: "http://94.237.99.172:1005",
	apiUrl: "http://94.237.99.172:1005",
	production: false,
	clientId: '636828583197-js9fnsvur38n6dkjka74gnofqriosah1.apps.googleusercontent.com',
	facebookAppId: "961173238697058",
	serverKey: "SRJNJWTDWN-JKBDHGH2NR-HJDNZWD96W",
	erpDashUrl: "http://erp.afzaz.com"


};
//apiUrl:"http://mahmoudabd2345-002-site5.atempurl.com",
