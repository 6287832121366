import { Package } from './../models/package';

import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
@Injectable({
  providedIn: 'root'
})
export class PackageService {

	private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = environment.apiUrl
    }


	allPackages(): Observable<any> {

        return this.http.get<any>(this.baseUrl + "/api/Package/GetAllPackages");
  }

  getPackagesList(): Observable<any> {

	return this.http.get<any>(this.baseUrl + "/api/Package/GetAllPackages");
 }

  getPackage(id: any): Observable<any> {
	let params = new HttpParams();
	params = params.append('id', id);
	return this.http.get<any>(this.baseUrl + "/api/Package/GetPackagebyId/"+id);
 }

 updatePackage(Package: Package): Observable<Package> {
	return this.http.post<any>(environment.apiUrl + "/api/Package/EditPackage", Package);
}

createPackage(Package: Package): Observable<Package> {
	return this.http.post<any>(environment.apiUrl + "/api/Package/Create", Package);
}

deletePackage(id : number): Observable<any> {
	let params = new HttpParams();
	params = params.append('id', id);
	return this.http.get<any>(environment.apiUrl + "/api/Package/delete?" , { params : params});
}


}
