<!-- <div [id]="'export'+divId"> -->
<!--Start Setting Menu -->


<!-- <div id="exportdevices" class="exportdevices_main mb-2"> -->


<!-- <div class="bg-secondary export-toolbar p-2"> -->
<!-- <app-tabulator-setting (onSortItemsChange)="sortItems($event)" (onColumnItemsChange)="showHideArraneColums($event)" (onGroupbyItemsChange)="groupByItems($event)" (onGroupbyItemsSave)="saveGroupBy($event)" (onSortItemsSave)="saveSortBy($event)" (onColumnItemsSave)="saveColumnsSetting($event)"
                [componentName]="componentName" [columnSettings]="columnSettings" [sortByList]="sortByList" [groupByList]="groupByList"></app-tabulator-setting> -->
<!-- <div class="exportdevices_main_icons" style="display: flex;"> -->

<!-- <button title="export pdf" class="sniper-icon-btn-pdf btn btn-tabulator-secondary" (click)="exportPdf()"><span>
                    export pdf

                </span></button>
                <button title="export excel" class="sniper-icon-btn-excel btn btn-tabulator-secondary mx-1 " (click)="exportExcel()"><span>
                    export excel

                </span></button>
                <button title="export csv" class="sniper-icon-btn-csv btn btn-tabulator-secondary mx-1 " (click)="exportCsv()"><span>
                    export csv

                </span></button>
                <button title="export html" class="sniper-icon-btn-html btn btn-tabulator-secondary mx-1 " (click)="exportHtml()"><span>
                    export html

                </span></button>
                <button title="export json" class="sniper-icon-btn-json btn btn-tabulator-secondary mx-1 " (click)="exportJson()"><span>
                    export json

                </span></button>
                <button title="save" class="sniper-icon-btn-save btn btn-tabulator-secondary mx-1 " (click)="saveAllSetting()"><span>
                    save

                 </span></button> -->
<!-- <button title="save" class="sniper-icon-btn-save"(click)="showGridFilter()"><span><img src="../../../assets/sniper/images/save-file-new.svg"> </span></button>             -->
<!-- <button title="filter" class="sniper-icon-btn-save" *ngIf="showMenuOptions.showAddGroup" (click)="openAddGroup()"><span><img src="../../../assets/sniper/images/folder.svg"> </span> </button>
                <button title="filter" class="sniper-icon-btn-save" *ngIf="showMenuOptions.showAddItem" (click)="openAddItem()"><span><img src="../../../assets/sniper/images/plus_gray.svg"> </span> </button> -->

<!-- </div> -->
<!-- <div class="search_area_on_export_setting">
                <div class="search-box">
                    <input class="search-txt" type="text" name="" placeholder="Search" [(ngModel)]="searchTxt" (change)="search()" #searchInput (blur)="showSearchButton()">
                    <button class="search-btn" (click)="showSearch()" #searchButton></button>
                </div>

            </div> -->



<!-- </div> -->



<!--End Setting Menu -->


<!-- </div> -->











<!-- </div> -->

<div class="search_area_on_export_setting">
	<div class="align-items-center d-flex  search-box">
		<input class="search-txt" type="text" name="" placeholder="Search" [(ngModel)]="searchTxt" (change)="search()"
			#searchInput (blur)="showSearchButton()">
		<button class="search-btn0 btn btn-primary" (click)="showSearch()" #searchButton> <i
				class="fa fa-search"></i></button>
	</div>
	<div [style]="tabulatorStyle" class="tabulator-container">
		<div [style]="customStyle" [id]="'my-tabular-table'+divId"></div>
	</div>
</div>