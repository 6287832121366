<!-- <div class="translate_wrapper" [class.active]="navServices.language">
    <div class="current_lang">
        <div class="lang">
            <i class="flag-icon flag-icon-{{selectedLanguage.icon}}"></i><span class="lang-txt">{{selectedLanguage.code}}</span>
        </div>
    </div>
    <div class="more_lang" [class.active]="navServices.language">
        <div class="lang selected" data-value="en-us" (click)="changeLanguage(lang)" *ngFor="let lang of languages">
            <i class="flag-icon flag-icon-{{lang.icon}}"></i>
            <span class="lang-txt">{{lang.language}}
				<span *ngIf="lang.type">({{lang.type}})</span>

            </span>
        </div>
    </div>
</div> -->
<div>
	<button class="arabic btn font-secondary p-0" *ngIf="currentSystemLanguage=='en-us'" (click)="changeLanguage('ar')">
		<i class="flag-icon flag-icon-sa "></i>
		<!-- {{'language.ARABIC'|translate}} -->
	</button>
	<button class="btn english font-secondary p-0" *ngIf="currentSystemLanguage=='ar'"
		(click)="changeLanguage('en-us')">
		<i class="flag-icon flag-icon-us"></i>
		<!-- {{'language.ENGLISH'|translate}} -->
	</button>
</div>