import { Component, OnInit } from '@angular/core';
import { AddEditPackageComponent } from '../add-edit-package/add-edit-package.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-choose-modules-packages',
  templateUrl: './choose-modules-packages.component.html',
  styleUrls: ['./choose-modules-packages.component.scss']
})
export class ChooseModulesPackagesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddEditPackageComponent>) { }

  ngOnInit(): void {
  }

}
