<div class="container-fluid p-0">
    <div class="row d-flex justify-content-center ">

        <div class="col-xl-6 p-0 col">
            <div class="login-card">
                <div>
                    <div class="Verification login-main">
                        <form [formGroup]="passForm" (submit)="onSubmit()">

                            <div class="text-center">

                                <img class="img-fluid " src="../assets/images/login/Safe.gif" alt="looginpage" width="240">


                            </div>
                            <h4 class="text-center">{{'login.newPassword'|translate}}</h4>
                            <p class="text-center">{{'login.newPasswordM'|translate}}
                            </p>


                            <div class="d-flex justify-content-between align-items-center mb-2">
                                <!-- <h4 class="text-right f-24 font-weight-light text-muted mt-2">{{'label.Security'|translate}}</h4> -->
                            </div>
                            <hr>


                            <div class="form-row mt-2">

                                <div class="form-group col-md-12">
                                    <label for="newPassword" class="control-label label-name">{{'login.password'|translate}}</label>
                                    <div class="input-group">

                                        <input type="password" class="form-control" id="newPassword" formControlName="newPassword" [ngClass]="{'error' : (f.newPassword.touched) && (f.newPassword.errors)}">
                                        <small *ngIf="(f.newPassword.touched) && (f.newPassword.errors)" class="w-100 text-danger mt-1">
											<span
												*ngIf="(f.newPassword.errors?.required)">{{'validation.required'|translate}}</span>
											<span
												*ngIf="(f.newPassword.errors.minlength)">{{'validation.invalidPassword'|translate}}</span>
											<span
												*ngIf="(f.newPassword.errors?.notEquivalent)">{{'validation.password-no-match'|translate}}</span>
										</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="confirmNewPassword" class="control-label label-name">{{'login.confirm-password'|translate}}</label>
                                    <div class="input-group">

                                        <input type="password" class="form-control" id="confirmNewPassword" formControlName="confirmNewPassword" [ngClass]="{'error' : (f.confirmNewPassword.touched) && (f.confirmNewPassword.errors)}">
                                        <small *ngIf="(f.confirmNewPassword.touched) && (f.confirmNewPassword.errors)" class="w-100 text-danger mt-1">
											<span
												*ngIf="(f.confirmNewPassword.errors?.required)">{{'validation.required'|translate}}</span>
											<span
												*ngIf="(f.confirmNewPassword.errors.minlength)">{{'validation.invalidPassword'|translate}}</span>
											<span
												*ngIf="(f.confirmNewPassword.errors?.notEquivalent)">{{'validation.password-no-match'|translate}}</span>
										</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-12">
                                    <button class="btn btn-info mr-2" type="submit">{{'buttons.save'|translate}}</button>
                                    <!-- <button class="btn btn-info mr-2" type="submit" [disabled]="!passForm.valid">{{'label.SaveNewPassword'|translate}}</button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
