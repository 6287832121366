<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading'|translate}}... </p>
</ngx-spinner>
<section class="inner-page-wrapper inner-page-wrapper-units">

	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<form [formGroup]="customerForm" id="BusinessSubmit" class="tabs-form">
					<div class="main-customer">
						<div class="wrapper-title">




							<div class="modal-header">
								<h4 class="modal-title">{{ 'customer.newSubscription'|translate}}</h4>
							</div>
							<div class="modal-body">
								<div class="row ">


									<div class="col-12 col-md-12">
										<label for="contractStartDate"
											class="label-name">{{'customer.contract-start-date'|translate}}</label>
										<div class="form-group position-relative">

											<input type="date" class="form-control icon-customer"
												name="contractStartDate" formControlName="contractStartDate">

										</div>
									</div>

									<div class="col-12 col-md-12">
										<label for="contractEndDate"
											class="label-name">{{'customer.contract-end-date'|translate}}</label>
										<div class="form-group position-relative">

											<input type="date" class="form-control icon-customer" name="contractEndDate"
												formControlName="contractEndDate">


										</div>
									</div>
									<!-- <hr class="p-0"> -->
									<div class="choice-applictions mt-3">
										<h5>{{ 'customer.choiceApplictions'|translate}}</h5>
										<div class="d-flex flex-wrap w-100 gap-3">
											<div *ngFor="let choice of checksApplications; let i=index"
												class="col-12 col-md-6">
												<label class="d-flex gap-2 label-name">
													<input type="checkbox" [checked]="choice.check"
														[value]="choice.value" (change)="onCheckChange(choice.value)"
														class="m-0">

													<span>{{lang=="ar"?choice.descriptionAr:choice.descriptionEn}}</span>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button (click)="save()" type="submit" class="btn btn-primary "> {{
							'toolbar.save'|translate}}</button>
					</div>

				</form>

			</div>
		</div>
	</div>

</section>