import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {environment} from '../../../../environments/environment'
import { CreateClientCommand } from '../../security/models/User';
import { AnyARecord } from 'dns';
import { PayTabRequestLink } from 'src/app/landing-page/model/PayTabRequestLink';

@Injectable({
    providedIn: 'root',
  })
export class TechnicalSupportService {



  constructor(
	private _httpClient: HttpClient )
	 {}


  TechnicalSupportLogin(AuthenticateRequest: any):Observable<any>{

    return this._httpClient.post<any>(environment.apiUrl + "/api/TechnicalSupport/Login", AuthenticateRequest);
  }


  RegisterCustomer(AuthenticateRequest: any):Observable<any>{

    return this._httpClient.post<any>(environment.apiUrl + "/api/TechnicalSupport/RegisterOwner", AuthenticateRequest);
  }


  LoginWithGoogle(credential : string) : Observable<any>
  {
	const headers = new HttpHeaders().set('Content-type' , 'application/json');
	return this._httpClient.post<any>(environment.apiUrl + '/api/TechnicalSupport/LonginWithGoogle', JSON.stringify(credential) , {headers : headers});
  }


  SignUpWithGoogle(credential : string) : Observable<any>
  {
	const headers = new HttpHeaders().set('Content-type' , 'application/json');
	return this._httpClient.post<any>(environment.apiUrl + '/api/TechnicalSupport/SignUpWithGoogle', JSON.stringify(credential) , {headers : headers});
  }


  LoginWithFacebook(request : any)
  {
	const headers = new HttpHeaders().set('Content-type' , 'application/json');
	return this._httpClient.post<any>(environment.apiUrl + '/api/TechnicalSupport/LonginWithFacebook', JSON.stringify(request) , {headers : headers , withCredentials : true});
  }


  AddOwnerCompanies(request : any)
  {
	const headers = new HttpHeaders().set('Content-type' , 'application/json');
	return this._httpClient.post<any>(environment.apiUrl + '/api/User/AddOwnerCompanies',
	JSON.stringify(request) ,
	{headers : headers , withCredentials : true});
  }


  GetOwnerCompanies(request : any)
  {
	const headers = new HttpHeaders().set('Content-type' , 'application/json');
	return this._httpClient.get<any>(environment.apiUrl + '/api/User/GetCompanies',
	{ params:request  , headers : headers , withCredentials : true});
  }


 AddOwnerPackage(request : any)
  {
	const headers = new HttpHeaders().set('Content-type' , 'application/json');
	return this._httpClient.post<any>(environment.apiUrl + '/api/User/AddOwnerPackages',
	JSON.stringify(request) ,
	{headers : headers , withCredentials : true});
  }

  AddOwnerModule(request : any)
  {
	const headers = new HttpHeaders().set('Content-type' , 'application/json');
	return this._httpClient.post<any>(environment.apiUrl + '/api/User/AddOwnerCustomModules',
	JSON.stringify(request) ,
	{headers : headers , withCredentials : true});
  }

  StartTrial(request : any)
  {
	const headers = new HttpHeaders().set('Content-type' , 'application/json');
	return this._httpClient.post<any>(environment.apiUrl + '/api/User/AddTrial',
	JSON.stringify(request) ,
	{headers : headers , withCredentials : true});
  }
  logout() : Observable<any>
  {
	const headers = new HttpHeaders().set('Content-type' , 'application/json');
	return this._httpClient.post<any>(environment.apiUrl + '/api/TechnicalSupport/logout' , {headers : headers});
  }
  checkSession() : Observable<any>
  {
	const headers = new HttpHeaders().set('Content-type' , 'application/json');
	return this._httpClient.post<any>(environment.apiUrl + '/api/TechnicalSupport/checkSession' , {headers : headers});
  }

  getGuid(): Observable<any> {
		
		return this._httpClient.get<any>(environment.apiUrl + "/api/UserOrganizations/getGuid");
	  }
}
