
	<angular-slickgrid  gridId="{{gridId}}" [columnDefinitions]="columnDefinitions"
		[gridOptions]="gridOptions" [dataset]="dataset" (onAngularGridCreated)="angularGridReady($event.detail)" >
	</angular-slickgrid>








