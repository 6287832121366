import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss']
})
export class PackageDetailsComponent implements OnInit {

  constructor(
  
    public dialogRef: MatDialogRef<PackageDetailsComponent>) { }

  ngOnInit(): void {
    
    console.log(this.dialogRef)
  }

}
