<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading'|translate}}... </p>
</ngx-spinner>
<section class="inner-page-wrapper inner-page-wrapper-units">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-3">
					<div class="card-body">
						<form [formGroup]="packageForm" id="BusinessSubmit" class="tabs-form">

							<div class="main-customer">
								<div class="wrapper-title">
									<h3>{{'packages.package'|translate}}</h3>
									<hr>
									<div class="row align-items-center">

										<div class="col-md-6 col-12">
											<label for="nameAr" class="label-name">
												{{'general.nameAr'|translate}}</label><span
												class="text-danger  f-20">*</span>
											<div class="form-group position-relative">
												<input type="text" class="form-control icon-user custom border-danger"
													[ngClass]="{ 'is-invalid': this.packageForm.get('nameAr').value == null }"
													name="nameAr" formControlName="nameAr">

											</div>
										</div>

										<div class="col-md-6 col-12">
											<label for="nameEn"
												class="label-name">{{'general.nameEn'|translate}}</label><span
												class="text-danger f-20">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user custom" name="nameEn"
													[ngClass]="{ 'is-invalid': this.packageForm.get('nameEn').value == null }"
													formControlName="nameEn">

											</div>
										</div>

										<!--Monthly Price-->
										<div class="col-md-6 col-12" *ngIf="monthlyPrice.isActive == true" >
											<label for="monthlyPrice"
												class="label-name">{{'packages.monthly-price'|translate}}</label><span
												class="text-danger f-20">*</span>
											<div class="form-group position-relative">
												<input type="number" class="form-control icon-user custom"
													name="monthlyPrice"
													[ngClass]="{ 'is-invalid': this.packageForm.get('monthlyPrice').value == null }"
													formControlName="monthlyPrice">
												<div *ngIf="(f['monthlyPrice'].touched || f['monthlyPrice'].dirty ) && f['monthlyPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span
														*ngIf="f['monthlyPrice'].errors && f['monthlyPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<!--Quarter Year Price-->
										<div class="col-md-6 col-12" *ngIf="quarterYearPrice.isActive == true">
											<label for="yearlyQuarterPrice"
												class="label-name">{{'packages.quarterYearly-price'|translate}}</label><span
												class="text-danger f-20">*</span>
											<div class="form-group position-relative">
												<input type="number" class="form-control icon-user custom"
													name="yearlyQuarterPrice"
													[ngClass]="{ 'is-invalid': this.packageForm.get('yearlyQuarterPrice').value == null }"
													formControlName="yearlyQuarterPrice">
												<div *ngIf="(f['yearlyQuarterPrice'].touched || f['yearlyQuarterPrice'].dirty ) && f['yearlyQuarterPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span
														*ngIf="f['yearlyQuarterPrice'].errors && f['yearlyQuarterPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>


										<!--Half Year Price-->
										<div class="col-md-6 col-12" *ngIf="halfYearPrice.isActive == true">
											<label for="yearlyHalfPrice"
												class="label-name">{{'packages.halfYearly-price'|translate}}</label><span
												class="text-danger f-20">*</span>
											<div class="form-group position-relative">
												<input type="number" class="form-control icon-user custom"
													name="yearlyHalfPrice"
													[ngClass]="{ 'is-invalid': this.packageForm.get('yearlyHalfPrice').value == null }"
													formControlName="yearlyHalfPrice">
												<div *ngIf="(f['yearlyHalfPrice'].touched || f['yearlyHalfPrice'].dirty ) && f['yearlyHalfPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span
														*ngIf="f['yearlyHalfPrice'].errors && f['yearlyHalfPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<!--Year Price-->
										<div class="col-md-6 col-12" *ngIf="yearPrice.isActive == true">
											<label for="yearlyPrice"
												class="label-name">{{'packages.yearly-price'|translate}}</label><span
												class="text-danger f-20">*</span>
											<div class="form-group position-relative">
												<input type="number" class="form-control icon-user custom"
													name="yearlyPrice"
													[ngClass]="{ 'is-invalid': this.packageForm.get('yearlyPrice').value == null }"
													formControlName="yearlyPrice">
												<div *ngIf="(f['yearlyPrice'].touched || f['yearlyPrice'].dirty ) && f['yearlyPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span
														*ngIf="f['yearlyPrice'].errors && f['yearlyPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<!--Full Price-->
										<div class="col-md-6 col-12" *ngIf="fullPrice.isActive == true">
											<label for="fullBuyPrice"
												class="label-name">{{'packages.full-price'|translate}}</label><span
												class="text-danger f-20">*</span>
											<div class="form-group position-relative">
												<input type="number" class="form-control icon-user custom"
													name="fullBuyPrice"
													[ngClass]="{ 'is-invalid': this.packageForm.get('fullBuyPrice').value == null }"
													formControlName="fullBuyPrice">
												<div *ngIf="(f['fullBuyPrice'].touched || f['fullBuyPrice'].dirty ) && f['fullBuyPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span
														*ngIf="f['fullBuyPrice'].errors && f['fullBuyPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12">
											<label for="numberOfUsers"
												class="label-name">{{'packages.numberOfUsers'|translate}}</label><span
												class="text-danger f-20">*</span>
											<div class="form-group position-relative">
												<input type="number" class="form-control icon-user custom"
													name="numberOfUsers"
													[ngClass]="{ 'is-invalid': this.packageForm.get('numberOfUsers').value == null }"
													formControlName="numberOfUsers">
													<span class="hint">{{'0 is refer to unlimited number' | translate}}</span>
												<div *ngIf="(f['numberOfUsers'].touched || f['numberOfUsers'].dirty ) && f['numberOfUsers'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span
														*ngIf="f['numberOfUsers'].errors && f['numberOfUsers'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12">
											<label for="numberOfCompanies"
												class="label-name">{{'packages.numberOfCompanies'|translate}}</label><span
												class="text-danger f-20">*</span>
											<div class="form-group position-relative">
												<input type="number" class="form-control icon-user custom"
													name="numberOfCompanies"
													[ngClass]="{ 'is-invalid': this.packageForm.get('numberOfCompanies').value == null }"
													formControlName="numberOfCompanies">
													<span class="hint">{{'0 is refer to unlimited number' | translate}}</span>
												<div *ngIf="(f['numberOfCompanies'].touched || f['numberOfCompanies'].dirty ) && f['numberOfCompanies'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span
														*ngIf="f['numberOfCompanies'].errors && f['numberOfCompanies'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12">
											<label for="numberOfBranches"
												class="label-name">{{'packages.numberOfBranches'|translate}}</label><span
												class="text-danger f-20">*</span>
											<div class="form-group position-relative">
												<input type="number" class="form-control icon-user custom"
													name="numberOfBranches"
													[ngClass]="{ 'is-invalid': this.packageForm.get('numberOfBranches').value == null }"
													formControlName="numberOfBranches">
													<span class="hint">{{'0 is refer to unlimited number' | translate}}</span>
												<div *ngIf="(f['numberOfBranches'].touched || f['numberOfBranches'].dirty ) && f['numberOfBranches'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span
														*ngIf="f['numberOfBranches'].errors && f['numberOfBranches'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12 d-flex">
											<div class="media">
												<label class="label-name"> {{'general.status'|translate}}
												</label>
												<div class="icon-state media-body mx-5">
													<label class="mx-2 switch">
														<input type="checkbox" class="form-control" name="isActive"
															formControlName="isActive">
														<span class="switch-state"></span>
													</label>
												</div>
											</div>
										</div>
									</div>

									<hr />

									<div class="row m-0">
										<div class="item-permissions col-md-6 col-12">
											<h3>{{'modules.Available-systems' | translate}}</h3>
											<hr>
											<div class="" *ngFor="let item of moduleList">
												<div class="py-1 animate-chk" *ngIf="item.isActive == true ">
													<input class="checkbox_animated" type="checkbox"
														[checked]="chekcedModule(item)" value="{{item.id}}"
														[id]="item.id" (change)="addModule($event , item.id)"> &nbsp;
													&nbsp;
													<label class="form-check-label" [for]="item.id">
														{{lang == 'ar' ? item.nameAr : item.nameEn}}
													</label>
												</div>
											</div>
										</div>

										<div class="col-md-6 col-12 ">
											<div class="" *ngIf="showBillNumber == true">
												<label for="billPattrenNumber"
													class="label-name">{{'packages.billPattrenNumber'|translate}}</label><span
													class="text-danger f-20">*</span>
												<div class="form-group position-relative">
													<input type="number" class="form-control icon-user custom"
														name="billPattrenNumber"
														[ngClass]="{ 'is-invalid': this.packageForm.get('billPattrenNumber').value == null }"
														formControlName="billPattrenNumber">
														<span class="hint">{{'0 is refer to unlimited number' | translate}}</span>
													<div *ngIf="(f['billPattrenNumber'].touched || f['billPattrenNumber'].dirty ) && f['billPattrenNumber'].invalid"
														class="validation alert-danger position-absolute">
														<i class="fa fa-close "></i>
														<span
															*ngIf="f['billPattrenNumber'].errors && f['billPattrenNumber'].errors['required']"
															class="f-12 ng-star-inserted text-white">
															{{'error.required'|translate}}</span>
													</div>

												</div>
											</div>

											<div class="" *ngIf="showInstallmentNumber == true">
												<label for="instrumentPattrenNumber"
													class="label-name">{{'packages.instrumentPattrenNumber'|translate}}</label><span
													class="text-danger f-20">*</span>
												<div class="form-group position-relative">
													<input type="number" class="form-control icon-user custom"
														name="instrumentPattrenNumber"
														[ngClass]="{ 'is-invalid': this.packageForm.get('instrumentPattrenNumber').value == null }"
														formControlName="instrumentPattrenNumber">
														<span class="hint">{{'0 is refer to unlimited number' | translate}}</span>
													<div *ngIf="(f['instrumentPattrenNumber'].touched || f['instrumentPattrenNumber'].dirty ) && f['instrumentPattrenNumber'].invalid"
														class="validation alert-danger position-absolute">
														<i class="fa fa-close "></i>
														<span
															*ngIf="f['instrumentPattrenNumber'].errors && f['instrumentPattrenNumber'].errors['required']"
															class="f-12 ng-star-inserted text-white">
															{{'error.required'|translate}}</span>
													</div>

												</div>
											</div>
										</div>

									</div>

								</div>

							</div>




						</form>

					</div>
				</div>
			</div>
		</div>


	</div>
</section>
