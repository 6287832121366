<div class="card">
	<div class="card-body">
		<section class="inner-page-wrapper inner-page-auction">
			<div class="col-md-6 col-12">
				<label for="roles" class="label-name">
					{{'component-names.role'|translate}}

				</label>
				<div class="input-group  popup">
					<ng-select (change)="onChange($event)" style="width:100%" name='roles' class="" id="roles">
						<ng-option *ngFor="let role of rolesList" [value]="role.id">
							{{lang=="ar"?role.nameAr:role.nameEn}}
						</ng-option>
					</ng-select>

				</div>
			</div>
			<div class="container-fluid p-0">
				<app-tabulator [idColName]="'id'" [childRowData]="permission" (onCheckItem)="onCheck($event)"
					(onEditItem)="onEdit($event)" (onDeleteItem)="showConfirmDeleteMessage($event)"
					[parentColName]="'groupId'" [divId]="'Permission'" [columnSettings]="columnNames"
					[componentName]="'permission'" [showMenuOptions]="menuOptions" [direction]="direction"
					[sortByList]="sortByCols" [groupByList]="groupByCols" [panelId]="panelId"
					[searchFilters]="searchFilters" (onSearchTextChange)="onSearchTextChange($event)"
					[groupType]="1"></app-tabulator>
			</div>
		</section>
	</div>
</div>