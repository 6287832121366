<div [ngClass]="{ 'sticky-header': isHeaderSticky }">
	<header>
		<nav class="navbar navbar-b navbar-trans navbar-expand-xl fixed-top nav-padding" id="sidebar-menu">
			<a class="navbar-brand p-0" [routerLink]="['/landing-page/home-page']"><img class="img-fluid"
					src="../../../assets/images/landing-page/logo-erp.png" alt=""></a>
			<button class="navbar-toggler navabr_btn-set custom_nav" type="button" data-bs-toggle="collapse"
				data-bs-target="#navbarDefault" aria-controls="navbarDefault" aria-expanded="false"
				aria-label="Toggle navigation"><span></span><span></span><span></span></button>
			<div class="navbar-collapse justify-content-between collapse hidenav" id="navbarDefault">
				<ul class="navbar-nav navbar_nav_modify" id="scroll-spy">
					<li class="nav-item"><a class="nav-link"
							[routerLink]="['/landing-page/home-page']">{{'landingpage.home-page'|translate}}</a></li>
					<li class="nav-item"><a class="nav-link"
							(click)="redirectToSection()">{{'landingpage.about-us'|translate}}</a>
					</li>
					<li class="nav-item"><a class="nav-link" href="#">{{'landingpage.systems-features'|translate}} </a>
					</li>
					<li class="nav-item"><a class="nav-link" href="#">{{'landingpage.Afzaz-Systems'|translate}} </a>
					</li>
					<li class="nav-item"><a class="nav-link" href="#"
							target="_blank">{{'landingpage.contact-us'|translate}}</a>
					</li>
					<li class="language-nav nav-item" (click)="languageToggle()">
						<!-- <app-languages></app-languages> -->
					</li>

				</ul>

				<ul class="d-flex gap-2" *ngIf="isLoggedIn == false">

					<li>
						<div class="text-start">
							<button class="language-btn btn p-0" (click)="toggleLanguage()">
								<i class="flag-icon"
									[ngClass]="currentSystemLanguage === 'en-us' ? 'flag-icon-sa' : 'flag-icon-us'"></i>

							</button>
						</div>
					</li>
					<li>
						<a [routerLink]="['/landing-page/login-customer']"
							class="btn px-3 btn-primary">{{'landingpage.Log-In'|translate}}</a>
					</li>
					<li>
						<a [routerLink]="['/landing-page/trial-modules']"
							class="btn px-3 btn-secondary">{{'landingpage.Get-free'|translate}}
						</a>
					</li>
				</ul>
				<ul class="d-flex gap-2" *ngIf="isLoggedIn == true">
					<li class="font-weight-bold text-white pt-2">
						{{userName}}
					</li>
					<li>
						<a (click)="openProfile()" class="btn btn-secondary icon-btn">
							<i class="fa fa-user" aria-hidden="true"></i> &nbsp; الملف الشخصي

						</a>
					</li>
					<li>
						<a (click)="logout()" class="btn px-3 btn-secondary">{{'landingpage.Log-out'|translate}}
						</a>
					</li>

				</ul>

			</div>
		</nav>
	</header>
</div>