export enum TypeOfSubscription {

	MonthlySubscription=1,
	YearlyQuarterSubscription = 2,
	YearlyHalfSubscription=3,
	YearlySubscription= 4,
	FullBuyingSubscription = 5
}
export enum SettingConfigurationEnum {
	AdditionalCompanyPrice = 1,
	AdditionalBranchPrice = 2,


}