export enum AlertTypes {
    success = "success",
    info = "info",
    error = "error",
    warning = "warning"

  }
  export enum UserType
  {
      Client = 1,
      Technical,
      Owner,
	  Employee
  }
  export enum TypeOfSubscription
  {
      monthlySubscription=1,
      yearlyQuarterSubscription = 2,
      yearlyHalfSubscription=3,
      yearlySubscription= 4,
      fullBuyingSubscription

  }
  export enum TypeOfSubscriptionAr
  {
      'الاشتراك الشهري'=1,
      ' الاشتراك الربع سنوي' = 2,
      'الاشتراك نصف سنوي'=3,
      'الاشتراك السنوي'= 4,
      'الاشتراك الكامل'

  }
