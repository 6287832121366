import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/common-services/shared-service';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';
import { navigateUrl } from 'src/app/shared/helper/helper-url';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';
import { EditSettingCommand, SettingDto } from '../models/general-configurations';
import { SettingProxy } from '../services/general-configurations.services'
import { SettingConfigurationEnum } from 'src/app/shared/enum/TypeOfSubscription.enum';
@Component({
  selector: 'app-setting-configurations',
  templateUrl: './setting-configurations.component.html',
  styleUrls: ['./setting-configurations.component.scss']
})
export class SettingConfigurationsComponent implements OnInit, OnDestroy {
  //#region Main Declarations
  sub: any;
  url: any;
  id: any = 0;


  additionalCompanyPrice: any = 0;

  additionalBranchPrice: number = 0;




  lang = localStorage.getItem("language");
  generalConfiguration: SettingDto[] = [];
  addUrl: string = '/master-codes/setting-configurations';
  updateUrl: string = '/master-codes/setting-configurations';
  listUrl: string = '/master-codes/setting-configurations';
  toolbarPathData: ToolbarPath = {
    listPath: '',
    updatePath: this.updateUrl,
    addPath: this.addUrl,
    componentList: this.translate.instant("component-names.general-configuration"),
    componentAdd: '',

  };
  response: any;
  errorMessage = '';
  errorClass = '';
  submited: boolean = false;

  constructor(
    private generalConfigurationService: SettingProxy,
    private router: Router,

    private spinner: NgxSpinnerService,
    private sharedService: SharedService, private translate: TranslateService
  ) {

  }
  //#endregion

  //#region ngOnInit
  ngOnInit(): void {


    this.changePath();
    this.spinner.show();
    this.listenToClickedButton();
    this.sharedService.changeButton({ action: "ConfigMode" } as ToolbarData);
    this.getSetting()
    this.spinner.hide();

  }





  //#endregion

  //#region ngOnDestroy
  ngOnDestroy() {
    this.subsList.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }



  //#endregion

  //#region Authentications

  //#endregion


  //#endregion

  //#region CRUD Operations

  getSetting() {
    return new Promise<void>((resolve, reject) => {
      let sub = this.generalConfigurationService.allGeneralConfiguration(undefined, 1, 10000, undefined, undefined, undefined).subscribe({
        next: (res) => {
          resolve();

          this.toolbarPathData.componentList = this.translate.instant("component-names.general-configuration");
          if (res.success) {
            this.generalConfiguration = res.response.items;



            this.additionalCompanyPrice = Number(this.generalConfiguration.find(c => c.id == SettingConfigurationEnum.AdditionalCompanyPrice).value);

            this.additionalBranchPrice = Number(this.generalConfiguration.find(c => c.id == SettingConfigurationEnum.AdditionalBranchPrice).value);

          }



        },
        error: (err: any) => {
          reject(err);
        },
        complete: () => {

        },
      });

      this.subsList.push(sub);
    });

  }


  //#endregion

  //#region Helper Functions



  //#endregion
  //#region Tabulator
  subsList: Subscription[] = [];
  currentBtnResult;
  listenToClickedButton() {

    let sub = this.sharedService.getClickedbutton().subscribe({
      next: (currentBtn: ToolbarData) => {


        this.sharedService.changeToolbarPath(this.toolbarPathData);

        if (currentBtn != null) {
          if (currentBtn.action == ToolbarActions.List) {
            this.onUpdate();
          } else if (currentBtn.action == ToolbarActions.Save) {
            
            this.onUpdate();
            // this.onSave();
          } else if (currentBtn.action == ToolbarActions.New) {
            this.onUpdate();
          } else if (currentBtn.action == ToolbarActions.Update) {
            this.onUpdate();
          }
        }
      },
    });
    this.subsList.push(sub);
  }
  changePath() {
    this.sharedService.changeToolbarPath(this.toolbarPathData);
  }
  confirmUpdate() {
    return new Promise<void>((resolve, reject) => {

      let item = new EditSettingCommand();
      item.setting = this.generalConfiguration;
      let sub = this.generalConfigurationService.updateGeneralConfiguration(item).subscribe({
        next: (result: any) => {
          this.spinner.show();
          this.response = { ...result.response };
          this.sharedService.changeButton({ action: "ConfigMode" } as ToolbarData);
          this.spinner.hide();

          navigateUrl(this.listUrl, this.router);
        },
        error: (err: any) => {
          reject(err);
        },
        complete: () => {

        },
      });
      this.subsList.push(sub);

    }).then(a => {
      this.getSetting();
    });
  }

  onUpdate() {
    {

      if (this.generalConfiguration.length > 0) {
        this.generalConfiguration.forEach((s) => {
          if (s) {




            if (s.id == 1) {
              s.value = this.additionalCompanyPrice + "";
            }
            if (s.id == 2) {
              s.value = this.additionalBranchPrice + "";
            }

          }
        });

        this.spinner.show();
        this.confirmUpdate().then(a => {
          this.spinner.hide();
          this.getSetting();
          this.changePath();
        }).catch(e => {
          this.spinner.hide();
        });

      }

    }


  }

  //#endregion
}

