<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading'|translate}}... </p>
</ngx-spinner>
<div class="card">
	<div class="card-body">
		<section class="inner-page-wrapper inner-page-auction">
			<div class="container-fluid p-0">
				<angular-slickgrid [gridOptions]="gridOptions" [columnDefinitions]="columnNames" gridId="editorGrid"
					[dataset]="modules" (onClick)="editRow($event.detail.eventData, $event.detail.args)"
					(onAngularGridCreated)="angularGridReady($event.detail)">

				</angular-slickgrid>
			</div>
		</section>
	</div>
</div>