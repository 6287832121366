import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SubscriptionTypeService } from '../../services/subscription-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';
import { NAME_REQUIRED_VALIDATORS } from 'src/app/shared/constants/input-validators';
import { SharedService } from 'src/app/shared/common-services/shared-service';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';
import { navigateUrl } from 'src/app/shared/helper/helper-url';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subscription-type-editor',
  templateUrl: './subscription-type-editor.component.html',
  styleUrls: ['./subscription-type-editor.component.scss']
})
export class SubscriptionTypeEditorComponent implements OnInit {

	subscriptionTypeForm!: FormGroup;
	url: any;
	id: any = 0;
	currnetUrl;
	subscriptionType: any[] = [];
	updateUrl: string = '/security/subscription-type/subscription-type-edit/';
	listUrl: string = '/security/subscription-type';
	toolbarPathData: ToolbarPath = {
	 listPath: '',
	 updatePath: this.updateUrl,
	 addPath: '',
	 componentList: this.translate.instant("component-names.subscription-type"),
	 componentAdd: '',

   };
   response: any;
   errorMessage = '';
   errorClass = '';
   submited: boolean = false;
   searchText: string;
   subsList: Subscription[] = [];
   currentBtnResult;
   constructor(private translate : TranslateService,
	 private subscriptionTypeService: SubscriptionTypeService ,
	 private router: Router,
	 private fb: FormBuilder,
	 private route: ActivatedRoute,
	 private spinner: NgxSpinnerService,
	 private sharedService: SharedService,
	 private appToaster : ToastrService
 ) {

	 this.defineModuleForm();
 }

 ngOnInit(): void {
	//this.spinner.show();
	this.sharedService.changeButton({ action: 'EditOnly' } as ToolbarData);
    this.getRouteData();
    this.currnetUrl = this.router.url;
    this.changePath();
    this.listenToClickedButton();
    this.spinner.hide();
 }


 getRouteData()
 {
   let sub = this.route.params.subscribe((params) => {
	   if (params['id'] != null) {
		 this.id = params['id'];

		 if (this.id) {
		   this.getSubscriptionTypeById(this.id).then(a => {

			 this.spinner.hide();

		   }).catch(err => {
			 this.spinner.hide();

		   });
		 }

	   }
	 });
	 this.subsList.push(sub);
 }

 getSubscriptionTypeById(id : any)
 {
   return new Promise<void>((resolve, reject) => {
	   let sub = this.subscriptionTypeService.getSubscriptionType(id).subscribe({
		 next: (res: any) => {
		   resolve();

		   this.subscriptionTypeForm.setValue({
			 id: res.response?.id,
			 nameAr: res.response?.nameAr,
			 nameEn: res.response?.nameEn,
			 period : res.response?.period ,
			 isActive: res.response?.isActive
		   });

		   console.log(
			 this.subscriptionTypeForm.value
		   );
		   },
		 error: (err: any) => {
		   reject(err);
		 },
		 complete: () => {
		   console.log('complete');
		 },
	   });
	   this.subsList.push(sub);

	 });
 }

 defineModuleForm()
 {
   this.subscriptionTypeForm = this.fb.group({
	   id: 0,
	   nameAr: NAME_REQUIRED_VALIDATORS,
	   nameEn: NAME_REQUIRED_VALIDATORS,
	   period : 0 ,
	   isActive: true
	 });
 }

 changePath() {
   this.sharedService.changeToolbarPath(this.toolbarPathData);
 }
 listenToClickedButton() {
   let sub = this.sharedService.getClickedbutton().subscribe({
	 next: (currentBtn: ToolbarData) => {
	   currentBtn;

	   if (currentBtn != null) {
		 if (currentBtn.action == ToolbarActions.List) {
		   this.sharedService.changeToolbarPath({
			 listPath: this.listUrl,
		   } as ToolbarPath);
		   this.router.navigate([this.listUrl]);
		 } else if (currentBtn.action == ToolbarActions.Update) {
		   this.onUpdate();
		 }
	   }
	 },
   });
   this.subsList.push(sub);
 }

 onUpdate()
 {
   if (this.subscriptionTypeForm.valid) {
	   this.spinner.show();
	   this.confirmUpdate().then(a => {
		 this.spinner.hide();
	   }).catch(e => {
		 this.spinner.hide();
	   });
	 }

	 else {

	   return this.subscriptionTypeForm.markAllAsTouched();
	 }
 }

 ngOnDestroy() {
   this.subsList.forEach((s) => {
	 if (s) {
	   s.unsubscribe();
	 }
   });
 }

 confirmUpdate()
 {
   this.subscriptionTypeForm.value.id = this.id;

   return new Promise<void>((resolve, reject) => {
	debugger;
	 let sub = this.subscriptionTypeService.updateSubscriptionType(Number.parseInt(this.id)).subscribe({
	   next: (result: any) => {
		 this.response = { ...result.response };
		 this.defineModuleForm();
		 this.submited = false;
		this.appToaster.success(this.translate.instant('editSuccessfully'))
		navigateUrl(this.listUrl, this.router);
		resolve();
	   },
	   error: (err: any) => {
		 reject(err);
	   },
	   complete: () => {
		 console.log('complete');
	   },
	 });
	 this.subsList.push(sub);

   });
 }

 get f(): { [key: string]: AbstractControl } {
   return this.subscriptionTypeForm.controls;
 }


}
