<div class="media profile-media">
	<img class="b-r-10" src="assets/images/profile.png" alt="">
	<div class="media-body"><span>{{'login.welcome'|translate}}</span>
		<p class="mb-0 font-roboto">{{userName}} <i class="middle fa fa-angle-down"></i></p>
	</div>
</div>
<ul class="profile-dropdown onhover-show-div">
	<!-- <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>-->
	<li>
		<a (click)="showModalFrom()">
			<app-feather-icons
				[icon]="'settings'"></app-feather-icons><span>{{'login.change-password'|translate}}</span></a>
	</li>
	<li (click)="logoutFunc()">
		<app-feather-icons [icon]="'log-in'"></app-feather-icons><span>{{'login.log-out'|translate}}</span>
	</li>
</ul>