import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';
import { navigateUrl } from 'src/app/shared/helper/helper-url';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';
import { CashPaymentInfoDto, CashPaymentInfoServiceProxy } from '../../security/services/cash-payment-info-service';
import { SharedService } from 'src/app/shared/common-services/shared-service';

@Component({
  selector: 'app-cash-payment-info',
  templateUrl: './cash-payment-info.component.html',
  styleUrls: ['./cash-payment-info.component.scss']
})
export class CashPaymentInfoComponent implements OnInit, OnDestroy {
  //#region Main Declarations
  sub: any;
  url: any;
  id: any = 0;

  cashPaymentInfoForm: FormGroup;

  lang = localStorage.getItem("language");
  itemDto: any = []; // Use any to allow different types of configurations
  addUrl: string = '/security/cash-payment-info';
  updateUrl: string = '/security/cash-payment-info';
  listUrl: string = '/security/cash-payment-info';
  toolbarPathData: ToolbarPath = {
    listPath: '',
    updatePath: this.updateUrl,
    addPath: this.addUrl,
    componentList: this.translate.instant("component-names.payment-info"),
    componentAdd: '',
  };
  response: any;
  errorMessage = '';
  errorClass = '';
  submited: boolean = false;
  subsList: Subscription[] = [];

  constructor(
    private paymentServices: CashPaymentInfoServiceProxy,
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private translate: TranslateService
  ) {
    this.cashPaymentInfoForm = this.fb.group({
      phoneNumber: [''],
      mobileNumber: [''],
      companyAddress: [''],
      email: ['', [ Validators.email]],
      bankNameEn: [''],
      bankNameAr: [''],
      iban: [''],
      bankAccountNumber: [''],
      bankAccountNameAr: [''],
      bankAccountNameEn: [''],
    });
  }
  //#endregion

  //#region ngOnInit
  ngOnInit(): void {
    this.changePath();
    this.spinner.show();
    this.listenToClickedButton();
    this.sharedService.changeButton({ action: "ConfigMode" } as ToolbarData);
    this.getCashPaymentInfo().then(() => {
      this.spinner.hide();
    });
  }
  //#endregion

  //#region ngOnDestroy
  ngOnDestroy() {
    this.subsList.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }
  //#endregion

  //#region CRUD Operations

  getCashPaymentInfo() {
    return new Promise<void>((resolve, reject) => {
      let sub = this.paymentServices.getCashPaymentInfo().subscribe({
        next: (res) => {
          resolve();
        
		  
          if (res.success) {
			
            this.itemDto = res.response;
            this.populateForm(this.itemDto);
          }
        },
        error: (err: any) => {
          reject(err);
        },
      });
      this.subsList.push(sub);
    });
  }

  populateForm(data: any) {
	
    if (data) {
      this.cashPaymentInfoForm.patchValue({
        phoneNumber: data.phoneNumber,
        mobileNumber: data.mobileNumber,
        companyAddress: data.companyAddress,
        email: data.email,
        bankNameEn: data.bankNameEn,
        bankNameAr: data.bankNameAr,
        iban: data.iban,
        bankAccountNumber: data.bankAccountNumber,
        bankAccountNameAr: data.bankAccountNameAr,
        bankAccountNameEn: data.bankAccountNameEn,
      });
    }
  }

  confirmUpdate() {
    return new Promise<void>((resolve, reject) => {
      const item = this.cashPaymentInfoForm.value as CashPaymentInfoDto;
      let sub = this.paymentServices.updateCashPaymentInfo(item).subscribe({
        next: (result: any) => {
          this.spinner.show();
          this.response = { ...result.response };
          this.sharedService.changeButton({ action: "ConfigMode" } as ToolbarData);
          this.spinner.hide();
          navigateUrl(this.listUrl, this.router);
          resolve();
        },
        error: (err: any) => {
          reject(err);
        },
      });
      this.subsList.push(sub);
    }).then(() => {
      this.getCashPaymentInfo();
    });
  }

  onUpdate() {
    this.spinner.show();
    this.confirmUpdate().then(() => {
      this.spinner.hide();
      this.getCashPaymentInfo();
      this.changePath();
    }).catch(() => {
      this.spinner.hide();
    });
  }
  //#endregion

  //#region Helper Functions
  listenToClickedButton() {
    let sub = this.sharedService.getClickedbutton().subscribe({
      next: (currentBtn: ToolbarData) => {
        this.sharedService.changeToolbarPath(this.toolbarPathData);
        if (currentBtn != null) {
          if (currentBtn.action == ToolbarActions.List) {
            this.onUpdate();
          } else if (currentBtn.action == ToolbarActions.Save) {
            this.onUpdate();
          } else if (currentBtn.action == ToolbarActions.New) {
            this.onUpdate();
          } else if (currentBtn.action == ToolbarActions.Update) {
            this.onUpdate();
          }
        }
      },
    });
    this.subsList.push(sub);
  }

  changePath() {
    this.sharedService.changeToolbarPath(this.toolbarPathData);
  }
  //#endregion
}
