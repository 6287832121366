<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading'|translate}}... </p>
</ngx-spinner>
<div class="card">
	<div class="card-body">
		<section class="inner-page-wrapper inner-page-auction">
			<div class="container-fluid p-0">
				<!-- <app-tabulator [idColName]="'id'" [childRowData]="user" (onCheckItem)="onCheck($event)"
					(onEditItem)="onEdit($event)" (onDeleteItem)="showConfirmDeleteMessage($event)"
					[parentColName]="'groupId'" [divId]="'owner'" [columnSettings]="columnNames"
					[componentName]="'user'" [showMenuOptions]="menuOptions" [direction]="direction"
					[sortByList]="sortByCols" [groupByList]="groupByCols" [panelId]="panelId"
					[searchFilters]="searchFilters" (onSearchTextChange)="onSearchTextChange($event)"
					[groupType]="1"></app-tabulator> -->
					<app-slick-grid
					gridId="customerGrid"
					[columnDefinitions]="columnDefinitions"
					[gridOptions]="gridOptions"
					[dataset]="user"
					(onEditItem)="onEdit($event)"
					(onDeleteItem)="showConfirmDeleteMessage($event)"
					(onCheckItem)="onCheck($event)"
				  >
				  </app-slick-grid>
			</div>
		</section>
	</div>
</div>