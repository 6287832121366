import { SubscriptionTypeEnum } from './../../enums/SubscriptionTypeEnum.enum';
import { SubscriptionTypeService } from './../../services/subscription-type.service';
import { Component } from '@angular/core';
import { Package } from '../../models/package';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';
import { DateModel } from 'src/app/shared/model/date-model';
import { PackageService } from '../../services/package.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsAlertsService } from 'src/app/shared/common-services/notifications-alerts.service';
import { Subscription } from 'rxjs';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { SharedService } from 'src/app/shared/common-services/shared-service';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';
import { navigateUrl } from 'src/app/shared/helper/helper-url';
import { stringIsNullOrEmpty } from 'src/app/shared/helper/helper';
import { REQUIRED_VALIDATORS } from 'src/app/shared/constants/input-validators';
import { MatDialog } from '@angular/material/dialog';
import { ChooseModulesPackagesComponent } from '../choose-modules-packages/choose-modules-packages.component';
import { ModuleService } from '../../services/module.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-package',
  templateUrl: './add-edit-package.component.html',
  styleUrls: ['./add-edit-package.component.scss']
})
export class AddEditPackageComponent {
	packageForm!: FormGroup;
	id: any = 0;
	currnetUrl;
	public show: boolean = false;
	lang = localStorage.getItem("language")
	package: Package[] = [];
	updateUrl: string = '/security/packages/add-edit-package/';
	addUrl: string = '/security/packages/add-edit-package';
	listUrl: string = '/security/packages';
	toolbarPathData: ToolbarPath = {
		listPath: '',
		updatePath: this.updateUrl,
		addPath: this.addUrl,
		componentList: this.translate.instant("packages.package"),
		componentAdd: '',

	};
	response: any;
	errorMessage = '';
	errorClass = '';
	submited: boolean = false;
	checksApplications: { descriptionAr: string; descriptionEn: string; value: string; check: boolean; }[];
	fromDate!: DateModel;
	moduleList : any [] = [];
	showBillNumber : boolean = false ;
	showInstallmentNumber : boolean = false;

	subscriptionTypeList : any [] = []
	subscriptionTypeEnum : SubscriptionTypeEnum;

	constructor(
		private translate : TranslateService,
		private packageService : PackageService,
		private router: Router,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private sharedService: SharedService,
		private alertsService:NotificationsAlertsService,
		public  dialog: MatDialog,
		private moduleService : ModuleService,
		private toastService : ToastrService,
		private subscriptionTypeService : SubscriptionTypeService
	)

	{
		this.definePackageForm();
	}

	definePackageForm()
	{
		this.packageForm = this.fb.group({
			id: 0,
			nameAr: null,
			nameEn: null,
			monthlyPrice : [null , Validators.required] ,
			yearlyPrice : [null , Validators.required] ,
			yearlyQuarterPrice : [null, Validators.required ],
			yearlyHalfPrice : [null, Validators.required] ,
			fullBuyPrice : [null , Validators.required] ,
			numberOfUsers : [null , Validators.required] ,
			numberOfCompanies : [null , Validators.required] ,
			numberOfBranches : [null , Validators.required]  ,
			billPattrenNumber : [null],
			instrumentPattrenNumber : [null],
			isCustomized : false,
			isActive : [true]
			});
	}


	ngOnInit(): void {
		this.spinner.show();
		Promise.all([this.getModuleList() , this.getSubscriptionType() , this.getRouteData()]).then(a => {
			this.currnetUrl = this.router.url;
			this.changePath();

			this.listenToClickedButton();
		}).catch((err) => {
			this.spinner.hide();
		})


	}

	getModuleList()
	{
		return new Promise<void>((resolve, reject) => {
			let sub  = this.moduleService.allModules(null , null , null , null , null )
			               .subscribe({
							next: (res: any) => {

								resolve();
								debugger
								this.moduleList = res.response;
								this.moduleList = this.moduleList.filter(x=> x.isActive == true);
 							},
							error: (err: any) => {
								reject(err);
							},
							complete: () => {
								console.log('complete');
							},
						   });

				this.subsList.push(sub);
		});
	}

	//#region ngOnDestroy
	ngOnDestroy() {
		this.subsList.forEach((s) => {
			if (s) {
				s.unsubscribe();
			}
		});
	}


	getRouteData() {
		let sub = this.route.params.subscribe((params) => {

			if (params['id'] != null) {
				this.id = params['id'];
				if (this.id) {
					this.getPackageById(this.id).then(a => {
						this.spinner.hide();
					    this.sharedService.changeButton({ action: 'Edit' } as ToolbarData);

					 }).catch(err => {
					   this.spinner.hide();

					 });
				}
				else {
					this.sharedService.changeButton({ action: 'New' } as ToolbarData);
					this.spinner.hide();
				}
			}
			else {
				this.sharedService.changeButton({ action: 'New' } as ToolbarData);
				this.spinner.hide();
			}
		});
		this.subsList.push(sub);

	}

	monthlyPrice : any ;
	quarterYearPrice : any ;
	halfYearPrice : any;
	yearPrice : any;
	fullPrice : any;

	getSubscriptionType()
	{
		let sub = this.subscriptionTypeService.getAllSubscriptionTypes()
		.subscribe(
			(res)=>{
				if (res.success) {
					this.subscriptionTypeList = res.response;
					this.monthlyPrice = this.subscriptionTypeList.find(x=>x.code == SubscriptionTypeEnum.MonthlySubscription);
					this.quarterYearPrice =  this.subscriptionTypeList.find(x=>x.code == SubscriptionTypeEnum.YearlyQuarterSubscription);
					this.halfYearPrice =  this.subscriptionTypeList.find(x=>x.code == SubscriptionTypeEnum.YearlyHalfSubscription);
					this.yearPrice =  this.subscriptionTypeList.find(x=>x.code == SubscriptionTypeEnum.YearlySubscription);
					this.fullPrice =  this.subscriptionTypeList.find(x=>x.code == SubscriptionTypeEnum.FullBuyingSubscription);



				}
			}
		)

		this.subsList.push(sub);
	}
	chekcedModule (item : any)
	{

		 if(this.moduleIds != null)
			{

				return this.moduleIds.find(x=> x == item.id) != null ? true : false;
			}
		return false;
	}

	getPackageById(id: any) {
		return new Promise<void>((resolve, reject) => {
			let sub = this.packageService.getPackage(id).subscribe({
				next: (res: any) => {
					resolve();
					console.log( res.response)
					this.packageForm.setValue({
						numberOfUsers : res.response.numberOfUsers,
						numberOfCompanies : res.response.numberOfCompanies,
						numberOfBranches :res.response.numberOfBranches,
						billPattrenNumber :res.response.billPattrenNumber,
						instrumentPattrenNumber :res.response.instrumentPattrenNumber,
						isCustomized :res.response.isCustomized,
						fullBuyPrice :res.response.fullBuyPrice,
						monthlyPrice : res.response.monthlyPrice,
						yearlyQuarterPrice :res.response.yearlyQuarterPrice,
						yearlyHalfPrice :res.response.yearlyHalfPrice ,
						nameAr : res.response.nameAr,
						nameEn : res.response.nameEn,
						yearlyPrice : res.response.yearlyPrice,
						id : res.response.id,
						isActive : res.response.isActive
					  });
                      this.id = res.response.id
					  this.moduleIds = res.response.moduleIds
					  if(res.response.instrumentPattrenNumber != null)
						{
							this.showInstallmentNumber = true;
						}
					  if(res.response.billPattrenNumber != null)
						{
								this.showBillNumber = true;
						}



				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});


			this.subsList.push(sub);

		});
	}

	get f(): { [key: string]: AbstractControl } {
		return this.packageForm.controls;
	}

	subsList: Subscription[] = [];
	currentBtnResult;
	listenToClickedButton() {
		let sub = this.sharedService.getClickedbutton().subscribe({
			next: (currentBtn: ToolbarData) => {
				currentBtn;

				if (currentBtn != null) {
					if (currentBtn.action == ToolbarActions.List) {
						this.sharedService.changeToolbarPath({
							listPath: this.listUrl,
						} as ToolbarPath);
						this.router.navigate([this.listUrl]);
					} else if (currentBtn.action == ToolbarActions.Save) {
						this.onSave();
					} else if (currentBtn.action == ToolbarActions.New) {
						this.toolbarPathData.componentAdd = this.translate.instant("component-names.promo-code");
						this.definePackageForm();
						this.sharedService.changeToolbarPath(this.toolbarPathData);
					} else if (currentBtn.action == ToolbarActions.Update) {
						this.onUpdate();
					}
				}
			},
		});
		this.subsList.push(sub);
	}
	changePath() {
		this.sharedService.changeToolbarPath(this.toolbarPathData);
	}

	confirmSave() {


		return new Promise<void>((resolve, reject) => {
			let sub = this.packageService.createPackage({
				numberOfUsers : this.packageForm.controls['numberOfUsers'].value,
				numberOfCompanies : this.packageForm.controls['numberOfCompanies'].value,
				numberOfBranches : this.packageForm.controls['numberOfBranches'].value,
				isCustomized : this.packageForm.controls['isCustomized'].value,
				isActive : this.packageForm.controls['isActive'].value,
				moduleIds : this.moduleIds ,
				fullBuyPrice : this.packageForm.controls['fullBuyPrice'].value,
				monthlyPrice : this.packageForm.controls['monthlyPrice'].value,
				nameAr : this.packageForm.controls['nameAr'].value,
				nameEn : this.packageForm.controls['nameEn'].value,
				yearlyPrice : this.packageForm.controls['yearlyPrice'].value,
				billPattrenNumber:  this.packageForm.controls['billPattrenNumber'].value ,
				instrumentPattrenNumber : this.packageForm.controls['instrumentPattrenNumber'].value,
				yearlyHalfPrice : this.packageForm.controls['yearlyHalfPrice'].value ,
				yearlyQuarterPrice : this.packageForm.controls['yearlyQuarterPrice'].value,
				id : 0
			}).subscribe({
				next: (result: any) => {
					resolve();
					this.response = { ...result.response };
					this.definePackageForm();

					this.submited = false;

					navigateUrl(this.listUrl, this.router);
				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}
	onSave() {

			if(this.moduleIds.length == 0)
				{
					//this.toastService.error(this.translate.instant("You must Choose at Least one Module"));
					return;
				}
			this.spinner.show();
		     this.fillEmptyName();
			this.confirmSave().then(a => {

				this.spinner.hide();
			}).catch(e => {

				this.spinner.hide();
			});
	}
	confirmUpdate() {

		return new Promise<void>((resolve, reject) => {
			let sub = this.packageService.updatePackage({
				numberOfUsers : this.packageForm.controls['numberOfUsers'].value,
				numberOfCompanies : this.packageForm.controls['numberOfCompanies'].value,
				numberOfBranches : this.packageForm.controls['numberOfBranches'].value,
				billPattrenNumber:  this.packageForm.controls['billPattrenNumber'].value ,
				instrumentPattrenNumber : this.packageForm.controls['instrumentPattrenNumber'].value,
				isCustomized : this.packageForm.controls['isCustomized'].value,
				isActive : this.packageForm.controls['isActive'].value,
				moduleIds : this.moduleIds ,
				fullBuyPrice : this.packageForm.controls['fullBuyPrice'].value,
				monthlyPrice : this.packageForm.controls['monthlyPrice'].value,
				nameAr : this.packageForm.controls['nameAr'].value,
				nameEn : this.packageForm.controls['nameEn'].value,
				yearlyPrice : this.packageForm.controls['yearlyPrice'].value,
				yearlyHalfPrice : this.packageForm.controls['yearlyHalfPrice'].value ,
				yearlyQuarterPrice : this.packageForm.controls['yearlyQuarterPrice'].value,
				id : this.id
			}).subscribe({
				next: (result: any) => {
					this.response = { ...result.response };
					this.definePackageForm();
					this.submited = false;
					navigateUrl(this.listUrl, this.router);
				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}

	onUpdate() {

			if(this.moduleIds.length == 0)
			{
				//this.toastService.error(this.translate.instant("You must Choose at Least one Module"));
				return;
			}
			this.spinner.show();
			this.fillEmptyName();

			this.confirmUpdate().then(a => {
				this.spinner.hide();
			}).catch(e => {
				this.spinner.hide();
			});

	}

	fillEmptyName()
	{
		const nameAr = this.packageForm.value.nameAr?.trim();
		const nameEn = this.packageForm.value.nameEn?.trim();
		if (stringIsNullOrEmpty(nameAr) && stringIsNullOrEmpty(nameEn)) {
		  this.errorMessage = this.translate.instant("general.name-required");
		  this.errorClass = 'errorMessage';
		  this.alertsService.showError(this.errorMessage, this.translate.instant("message-title.wrong"));
		  this.spinner.hide();
		  return;
		}else{
			if (stringIsNullOrEmpty(nameAr)) {
				this.packageForm.controls["nameAr"].setValue(this.packageForm.controls["nameEn"].value);
			  }

			  if (stringIsNullOrEmpty(nameEn)) {
				this.packageForm.controls["nameEn"].setValue(this.packageForm.controls["nameAr"].value);
			  }
		}
	}

	chooseProgram(event)
	{
		if(event.target.checked == true)
		{
             const dialogRef = this.dialog.open(ChooseModulesPackagesComponent, {
				   width:'250px'
				});

				dialogRef.afterClosed().subscribe(result => {
				console.log('The dialog was closed');

				});
		}
	}

  showModules : boolean = false;
  moduleIds : number [] = [];
  isCustomize(event : any)
  {
      if(event.target.checked == true)
		{
            this.showModules = true;
		}
	  else
	  {
		this.showModules = false;
	  }
  }

  addModule(event : any , id : number)
  {
	debugger;
	let module = this.moduleList.find(x=>x.id == id);
	console.log(module);
	if(event.target.checked == true)
		{
			 this.moduleIds.push(id);
			 if(module.instrumentPattrenPrice != null && module.billPattrenPrice != null)
				{
					this.showInstallmentNumber = true;
					this.showBillNumber = true;
				}

				if(module.billPattrenPrice == null && module.instrumentPattrenPrice != null)
				{

					this.showInstallmentNumber = true;

				}
		}
		else
		{
            this.moduleIds = this.moduleIds.filter(x=>x != id);
			if(module.instrumentPattrenPrice != null && module.billPattrenPrice != null)
				{
					let testModule = this.moduleList.find(x=>x.billPattrenPrice == null && x.instrumentPattrenPrice != null)
					if(testModule != null)
						{
							if(this.moduleIds.find(x=>x == testModule.id) == null)
								{
									this.showInstallmentNumber = false;
								}
						}
					this.showBillNumber = false;
				}

			if(module.billPattrenPrice == null && module.instrumentPattrenPrice != null)
				{
						if(this.showBillNumber == false)
						{
							this.showInstallmentNumber = false;
						}

				}
		}


  }
}
