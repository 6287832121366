import { DateModel } from './../../../../../shared/model/date-model';
import { NotificationsAlertsService } from './../../../../../shared/common-services/notifications-alerts.service';
import { PromoCode } from './../../../models/promo-code';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';
import { PromoCodeService } from '../../../services/promo-code.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NAME_REQUIRED_VALIDATORS } from 'src/app/shared/constants/input-validators';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/common-services/shared-service';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';
import { navigateUrl } from 'src/app/shared/helper/helper-url';
import { formatDate } from '@angular/common';
import { stringIsNullOrEmpty } from 'src/app/shared/helper/helper';

@Component({
  selector: 'app-add-edit-pomo-code',
  templateUrl: './add-edit-pomo-code.component.html',
  styleUrls: ['./add-edit-pomo-code.component.scss']
})
export class AddEditPomoCodeComponent implements OnInit {
	promoCodeForm!: FormGroup;
	id: any = 0;
	currnetUrl;
	public show: boolean = false;
	lang = localStorage.getItem("language")
	role: PromoCode[] = [];
	addUrl: string = '/security/promo-code/add-promo-code';
	updateUrl: string = '/security/promo-code/edit-promo-code/';
	listUrl: string =  '/security/promo-code';
	toolbarPathData: ToolbarPath = {
		listPath: '',
		updatePath: this.updateUrl,
		addPath: this.addUrl,
		componentList: this.translate.instant("PromoCode.promo-code"),
		componentAdd: '',

	};
	response: any;
	errorMessage = '';
	errorClass = '';
	submited: boolean = false;
	checksApplications: { descriptionAr: string; descriptionEn: string; value: string; check: boolean; }[];
	fromDate!: DateModel;
	constructor(
		private translate : TranslateService,
		private promoCodeService : PromoCodeService,
		private router: Router,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private sharedService: SharedService,
		private alertsService:NotificationsAlertsService

	) {
		this.definePromoCodeForm();
	}

	definePromoCodeForm()
	{
	 this.promoCodeForm = this.fb.group({
		 id: 0,
		 nameAr: null,
		 nameEn: null,
		 promoCode : NAME_REQUIRED_VALIDATORS,
		 discountPercentage : [null , Validators.required] ,
		 isActive: true,
		 fromDate : [ '' , Validators.required] ,
		 toDate :  [ '' , Validators.required]
		  });
	}

   //#region ngOnInit
	ngOnInit(): void {
		this.spinner.show();
		Promise.all([this.getRouteData()]).then(a => {
			this.currnetUrl = this.router.url;
			if (this.currnetUrl == this.addUrl) {
				this.getCustomerCode();
			}
			this.changePath();
			this.listenToClickedButton();
		}).catch((err) => {

			this.spinner.hide();
		})


	}

	getCustomerCode()
	{

	}
	//#endregion
	//#region ngOnDestroy
	ngOnDestroy() {
		this.subsList.forEach((s) => {
			if (s) {
				s.unsubscribe();
			}
		});
	}
	//#endregion
	getRouteData() {
		let sub = this.route.params.subscribe((params) => {
			if (params['id'] != null) {
				this.id = params['id'];
				if (this.id) {
					this.getPromoCodeById(this.id).then(a => {
						this.spinner.hide();
					    this.sharedService.changeButton({ action: 'Edit' } as ToolbarData);

					 }).catch(err => {
					   this.spinner.hide();

					 });
				}
				else {
					this.sharedService.changeButton({ action: 'New' } as ToolbarData);
					this.spinner.hide();
				}
			}
			else {
				this.sharedService.changeButton({ action: 'New' } as ToolbarData);
				this.spinner.hide();
			}
		});
		this.subsList.push(sub);

	}
	//#region Authentications
	private formatDate(date) {
		const d = new Date(date);
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		const year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	  }
	getPromoCodeById(id: any) {
		return new Promise<void>((resolve, reject) => {
			let sub = this.promoCodeService.getPromoCode(id).subscribe({
				next: (res: any) => {
					resolve();
					this.promoCodeForm.setValue({
						id: res.response?.id,
						nameAr: res.response?.nameAr,
						nameEn: res.response?.nameEn,
						promoCode: res.response?.promoCode,
						discountPercentage: res.response?.discountPercentage,
						isActive: res.response?.isActive,
						fromDate : res.response?.fromDate ,
						toDate :res.response?.toDate

					  });


					let startDate=  formatDate(this.promoCodeForm.controls['fromDate'].value,'yyyy-MM-dd' , 'en')
					this.promoCodeForm.controls['fromDate'].setValue(startDate);
					let toDate=  formatDate(this.promoCodeForm.controls['toDate'].value,'yyyy-MM-dd' , 'en')
					this.promoCodeForm.controls['toDate'].setValue(toDate);

				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}

	get f(): { [key: string]: AbstractControl } {
		return this.promoCodeForm.controls;
	}

	subsList: Subscription[] = [];
	currentBtnResult;
	listenToClickedButton() {
		let sub = this.sharedService.getClickedbutton().subscribe({
			next: (currentBtn: ToolbarData) => {
				currentBtn;

				if (currentBtn != null) {
					if (currentBtn.action == ToolbarActions.List) {
						this.sharedService.changeToolbarPath({
							listPath: this.listUrl,
						} as ToolbarPath);
						this.router.navigate([this.listUrl]);
					} else if (currentBtn.action == ToolbarActions.Save) {
						this.onSave();
					} else if (currentBtn.action == ToolbarActions.New) {
						this.toolbarPathData.componentAdd = this.translate.instant("component-names.promo-code");
						this.definePromoCodeForm();
						this.sharedService.changeToolbarPath(this.toolbarPathData);
					} else if (currentBtn.action == ToolbarActions.Update) {
						this.onUpdate();
					}
				}
			},
		});
		this.subsList.push(sub);
	}
	changePath() {
		this.sharedService.changeToolbarPath(this.toolbarPathData);
	}


	confirmSave() {
		
		return new Promise<void>((resolve, reject) => {
			let sub = this.promoCodeService.createPromoCode(this.promoCodeForm.value).subscribe({
				next: (result: any) => {
					resolve();
					this.response = { ...result.response };
					this.definePromoCodeForm();

					this.submited = false;

					navigateUrl(this.listUrl, this.router);
				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}
	onSave() {
		if (this.promoCodeForm.valid) {
			this.spinner.show();
		     this.fillEmptyName();
			this.confirmSave().then(a => {
				
				this.spinner.hide();
			}).catch(e => {
				
				this.spinner.hide();
			});

		} else {

			return this.promoCodeForm.markAllAsTouched();
		}
	}
	confirmUpdate() {
		this.promoCodeForm.value.id = this.id;
		return new Promise<void>((resolve, reject) => {
			let sub = this.promoCodeService.updatePromoCode(this.promoCodeForm.value).subscribe({
				next: (result: any) => {
					this.response = { ...result.response };
					this.definePromoCodeForm();
					this.submited = false;
					navigateUrl(this.listUrl, this.router);
				},
				error: (err: any) => {
					reject(err);
				},
				complete: () => {
					console.log('complete');
				},
			});
			this.subsList.push(sub);

		});
	}

	onUpdate() {
		if (this.promoCodeForm.valid) {
			this.spinner.show();
			this.fillEmptyName();
			this.confirmUpdate().then(a => {
				this.spinner.hide();
			}).catch(e => {
				this.spinner.hide();
			});
		}

		else {

			return this.promoCodeForm.markAllAsTouched();
		}
	}
	fillEmptyName()
	{
		const nameAr = this.promoCodeForm.value.nameAr?.trim();
		const nameEn = this.promoCodeForm.value.nameEn?.trim();
		if (stringIsNullOrEmpty(nameAr) && stringIsNullOrEmpty(nameEn)) {
		  this.errorMessage = this.translate.instant("general.name-required");
		  this.errorClass = 'errorMessage';
		  this.alertsService.showError(this.errorMessage, this.translate.instant("message-title.wrong"));
		  this.spinner.hide();
		  return;
		}else{
			if (stringIsNullOrEmpty(nameAr)) {
				this.promoCodeForm.controls["nameAr"].setValue(this.promoCodeForm.controls["nameEn"].value);
			  }

			  if (stringIsNullOrEmpty(nameEn)) {
				this.promoCodeForm.controls["nameEn"].setValue(this.promoCodeForm.controls["nameAr"].value);
			  }
		}
	}


	getFromDate(selectedDate: DateModel) {
		this.fromDate = selectedDate;
	  }

	  dateRangeValidator(control: FormGroup): { [key: string]: boolean } | null {
		const startDate = control.get('fromDate').value;
		const endDate = control.get('toDate').value;

		if (startDate && endDate) {
		  return startDate <= endDate ? null : { 'dateRange': true };
		}

		return null;
	  }


}
