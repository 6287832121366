<div class="container-fluid p-0">
	<div class="row bg-login-admin">
		<!-- <div class="col-xl-6 p-0"><img class="bg-img-cover bg-center h-100vh" src="../assets/images/login/bg-login.png" alt="looginpage" width="100%">
        </div> -->


		<div class="col-xl-6 p-0">
			<div class="login-card">
				<div>
					<div class="login-main ">
						<form class="theme-form" [formGroup]="loginForm">
							<div>
								<a class="logo" routerLink='/'>
									<img class="img-fluid for-light w-50" src="assets/images/logo/logo-1.png"
										alt="looginpage" width="200">
									<img class="img-fluid for-dark w-50" src="assets/images/logo/logo-1.png"
										alt="looginpage" width="200">
								</a>
							</div>
							<div class="form-group">
								<label class="col-form-label label-name">{{'login.user-name'|translate}}</label>
								<input class="form-control" type="input" required="" formControlName="userName">
								<div *ngIf="loginForm.controls.userName.touched && loginForm.controls.userName.errors?.required"
									class="text text-danger mt-1">
									{{'error.required'|translate}}
								</div>

							</div>
							<div class="form-group">
								<label class="col-form-label label-name"> {{'login.password'|translate}} </label>
								<input class="form-control" [type]="show ? 'text' : 'password'"
									formControlName="password" required="" placeholder="*********">
								<div class="show-hide" (click)="showPassword()" *ngIf="!show"><span
										class="show fa-show fa"></span>
								</div>
								<div class="show-hide" (click)="showPassword()" *ngIf="show"><span
										class="Hide fa  fa-hide"></span>
								</div>
								<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
									class="text text-danger mt-1">
									{{'error.required'|translate}}
								</div>
							</div>
							<div class="form-group my-5">

								<!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a> -->
								<button class="btn btn-block btn-primary w-100" [class.loader--text]="showLoader"
									[disabled]="!loginForm.valid || showLoader" (click)="login()"
									type="button">{{'login.login'|translate}}
								</button>
							</div>

							<!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
                <div class="social mt-4">
                  <div class="btn-showcase">
                    <a class="btn btn-light" (click)="loginGoogle()" target="_blank">
                      <app-feather-icons [icon]="'log-in'" class="txt-google"></app-feather-icons>Google +
                    </a>
                    <a class="btn btn-light" (click)="loginFacebook()" target="_blank">
                      <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook
                    </a>
                    <a class="btn btn-light" (click)="loginTwitter()" target="_blank">
                      <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                    </a>
                  </div>
                </div> -->
							<!-- <p class="mt-4 mb-0">Don't have account?
                  <a [routerLink]="'/authentication/register/simple'" class="ms-2">Create Account</a>
                </p> -->
						</form>
					</div>
				</div>
			</div>
		</div>

		<!-- footer start-->

		<footer class="align-items-center bottom-0 d-flex justify-content-between m-0 position-absolute right-0 row">
			<div class="col-md-6 col text-start">
				<button class="arabic btn  p-0" *ngIf="currentSystemLanguage=='en-us'" (click)="changeLanguage('ar')">
					<i class="flag-icon flag-icon-sa "></i>
					{{'language.ARABIC'|translate}}
				</button>
				<button class="english btn  p-0" *ngIf="currentSystemLanguage=='ar'" (click)="changeLanguage('en-us')">
					<i class="flag-icon flag-icon-us"></i>
					{{'language.ENGLISH'|translate}}
				</button>
			</div>
			<app-footer class="col-md-6 col">

			</app-footer>
		</footer>

		<!-- footer End-->
	</div>


</div>