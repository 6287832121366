<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading'|translate}}... </p>
</ngx-spinner>
<div class="card">
	<div class="card-body">
		<section class="inner-page-wrapper inner-page-auction">
			<div class="container-fluid p-0">
				<app-tabulator [idColName]="'id'" [childRowData]="countries" [parentColName]="'groupId'"
					[divId]="'Countries'" [columnSettings]="columnNames" [componentName]="'countries'"
					(onEditItem)="onEdit($event)" (onCheckItem)="onCheck($event)"
					(onDeleteItem)="showConfirmDeleteMessage($event)" (onAddItem)="openCountries()"
					(onMenuActionSelected)="onMenuActionSelected($event)" [showMenuOptions]="menuOptions"
					[direction]="direction" [sortByList]="sortByCols" [groupByList]="groupByCols" [panelId]="panelId"
					[searchFilters]="searchFilters" (onSearchTextChange)="onSearchTextChange($event)" [groupType]="1"
					[path]="'countries'"></app-tabulator>
			</div>
		</section>
	</div>
</div>