import { SubscriptionTypeService } from './services/subscription-type.service';

import { PromoCodeService } from './services/promo-code.service';
import { ModuleService } from './services/module.service';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from "../../shared/shared.module";
import { SecurityRoutingModule } from './security-routing.module';
import { RolesComponent } from './roles/roles.component';
import { AddEditRoleComponent } from './roles/add-edit-role/add-edit-role.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RoleServiceProxy } from './services/role.servies';
import { UsersListComponent } from './users/users-list/users-list.component';
import { AddEditUserComponent } from './users/add-edit-user/add-edit-user.component';
import { UserServiceProxy } from './services/user-service';
import { CustomersListComponent } from './customers/customers-list/customers-list.component';
import { AddEditCustomerComponent } from './customers/add-edit-customer/add-edit-customer.component';
import { CustomerServiceProxy } from './services/customer-service';
import { PermissionListComponent } from './permission-list/permission-list.component';
import { PermissionServiceProxy } from './services/permission.servies';
import { AddEditSubscriptionsComponent } from './customers/add-edit-subscriptions/add-edit-subscriptions.component';
import { DateConverterService } from 'src/app/shared/services/date-services/date-converter.service';
import { ModulesComponent } from './modules/modules.component';
import { EditModulesComponent } from './modules/edit-modules/edit-modules.component';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { PromoCodeListComponent } from './promo-code/promo-code-list/promo-code-list.component';
import { AddEditPomoCodeComponent } from './promo-code/add-edit-pomo-code/add-edit-pomo-code/add-edit-pomo-code.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PackagesListComponent } from './packages/packages-list/packages-list.component';
import { AddEditPackageComponent } from './packages/add-edit-package/add-edit-package.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CustompackagesComponent } from './packages/custompackages/custompackages.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ChooseModulesPackagesComponent } from './packages/choose-modules-packages/choose-modules-packages.component';
import { ClientsListComponent } from './client/clients-list/clients-list.component';
import { AddEditClientComponent } from './client/add-edit-client/add-edit-client.component';
import { OwnersListComponent } from './owners/owners-list/owners-list.component';
import { AddEditOwnerComponent } from './owners/add-edit-owner/add-edit-owner.component';
import { PackageDetailsComponent } from './owners/package-details/package-details.component';
import { CashPaymentInfoComponent } from './cash-payment-info/cash-payment-info.component';
import { CashPaymentInfoServiceProxy } from './services/cash-payment-info-service';
import { SubscriptionTypeComponent } from './subscription-type/subscription-type.component';
import { SubscriptionTypeEditorComponent } from './subscription-type/subscription-type-editor/subscription-type-editor.component';
import { OrderModulesComponent } from './order-modules/order-modules.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
@NgModule({
	declarations: [RolesComponent, AddEditRoleComponent, UsersListComponent,
		AddEditUserComponent, CustomersListComponent, AddEditCustomerComponent,
		PermissionListComponent, AddEditSubscriptionsComponent, ModulesComponent,
		EditModulesComponent, PromoCodeListComponent, AddEditPomoCodeComponent,
		PackagesListComponent, AddEditPackageComponent, CustompackagesComponent,
		ChooseModulesPackagesComponent,
		ClientsListComponent, AddEditClientComponent, OwnersListComponent,
		AddEditOwnerComponent,
		PackageDetailsComponent,
		CashPaymentInfoComponent,
  SubscriptionTypeComponent,
  SubscriptionTypeEditorComponent,
  OrderModulesComponent
	],
	imports: [
		CommonModule,
		DragDropModule,
		NgxSpinnerModule,
		SharedModule,
		NgbModule,
		MatTabsModule,
		MatDialogModule, SecurityRoutingModule,
		AngularSlickgridModule.forRoot({
			// add any Global Grid Options/Config you might want
			// to avoid passing the same options over and over in each grids of your App
			enableAutoResize: true,
			autoResize: {
				container: '#grid-container',
				rightPadding: 10
			}
		})
	],
	providers: [RoleServiceProxy, ModuleService, PromoCodeService,SubscriptionTypeService,
		UserServiceProxy, CustomerServiceProxy, PermissionServiceProxy, DatePipe, DateConverterService,CashPaymentInfoServiceProxy
	]
})
export class SecurityModule { }
