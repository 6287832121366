<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading' | translate}}... </p>
</ngx-spinner>
<section class="inner-page-wrapper inner-page-wrapper-units">

	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-3">
					<div class="card-body">
						<form [formGroup]="customerForm" id="BusinessSubmit" class="tabs-form">
							<div class="main-customer ranoo">
								<div class="wrapper-title">

									<h3>{{'component-names.customer-information'|translate}}</h3>
									<hr>
									<div class="row ">

										<div class="col-12 col-md-6 offset-6">
											<label for="code" class="label-name">
												{{'general.code'|translate}}</label><span class="text-danger">*</span>
											<div class="form-group position-relative">
												<!-- [ngClass]="{'is-invalid':f['code'].errors || f['code'].invalid}" -->
												<input type="text" class="form-control icon-customer" name="code"
													formControlName="code">
												<div *ngIf="(f['code'].touched || f['code'].dirty ) && f['code'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['code'].errors && f['code'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}} </span>
												</div>

											</div>
										</div>
										<div class="col-12 col-md-6">
											<label for="nameAr" class="label-name">
												{{'general.nameAr'|translate}}</label><span class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-customer"
													[ngClass]="{'is-invalid':f['nameAr'].errors || f['nameAr'].invalid}"
													name="nameAr" formControlName="nameAr">
												<div *ngIf="(f['nameAr'].touched || f['nameAr'].dirty ) && f['nameAr'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['nameAr'].errors && f['nameAr'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>
										<div class="col-12 col-md-6">
											<label for="nameEn"
												class="label-name">{{'general.nameEn'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-customer" name="nameEn"
													formControlName="nameEn">
												<div *ngIf="(f['nameEn'].touched || f['nameEn'].dirty ) && f['nameEn'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['nameEn'].errors && f['nameEn'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-12 col-md-6">
											<label for="email"
												class="label-name">{{'general.email'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-customer" name="email"
													formControlName="email">
												<div *ngIf="(f['email'].touched || f['email'].dirty ) && f['email'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['email'].errors && f['email'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-12 col-md-6">
											<label for="phoneNumber"
												class="label-name">{{'general.mobile-number'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-customer" name="phoneNumber"
													formControlName="phoneNumber">
												<!-- <div *ngIf="(f['phoneNumber'].touched || f['phoneNumber'].dirty ) && f['phoneNumber'].invalid" class="validation alert-danger position-absolute">
                                                    <i class="fa fa-close "></i>
                                                    <span *ngIf="f['phoneNumber'].errors && f['phoneNumber'].errors['required']" class="f-12 ng-star-inserted text-white">
                                                        {{'error.required'|translate}}</span>
                                                </div> -->

											</div>
										</div>
										<div class="col-12 col-md-6">
											<label for="countryId" class="label-name">
												{{'component-names.countries'|translate}}
												<span class="text-danger">*</span>
											</label>
											<div class="input-group  popup">
												<ng-select style="width:100%" formControlName="countryId"
													name='countryId' class="" id="countryId">
													<ng-option *ngFor="let country of countriesList"
														[value]="country.id">
														{{lang=="ar"?country.nameAr:country.nameEn}}
													</ng-option>
												</ng-select>

											</div>
											<div *ngIf="(f['countryId'].touched || f['countryId'].dirty ) && f['countryId'].invalid"
												class="validation alert-danger position-absolute">
												<i class="fa fa-close "></i>
												<span *ngIf="f['countryId'].errors && f['countryId'].errors['required']"
													class="f-12 ng-star-inserted text-white">
													{{'error.required'|translate}}</span>
											</div>
										</div>

										<div class="col-12 col-md-6">
											<label for="businessId" class="label-name">
												{{'component-names.business'|translate}}

											</label>
											<div class="input-group  popup">
												<ng-select style="width:100%" formControlName="businessId"
													name='businessId' class="" id="businessId">
													<ng-option *ngFor="let business of businessList"
														[value]="business.id">
														{{lang=="ar"?business.nameAr:business.nameEn}}
													</ng-option>
												</ng-select>

											</div>
										</div>
										<div class="col-12 col-md-6">
											<label for="companySize" class="label-name">
												{{'customer.company-size'|translate}}

											</label>
											<div class="input-group  popup">
												<ng-select style="width:100%" formControlName="companySize"
													name='companySize' class="" id="companySize">
													<ng-option *ngFor="let company of companySizeList"
														[value]="company">
														{{company}}
													</ng-option>
												</ng-select>

											</div>
										</div>
										<hr class="mt-3 mb-3 p-0">
										<div class="col-md-6 col-12 d-flex align-items-end">
											<div class="media">
												<label class="label-name">
													{{'customer.multi-companies'|translate}} </label>
												<div>
													<label class="mx-2 switch">
														<input type="checkbox" class="switch-state"
															name="multiCompanies"
															(change)="onChangeMultiCompanies($event)"
															formControlName="multiCompanies">
														<span class="switch-state"></span>
													</label>
												</div>
											</div>
										</div>
										<div class="col-md-6 col-12 d-flex align-items-end">
											<div class="media">
												<label class="label-name">
													{{'customer.multi-branches'|translate}} </label>
												<div>
													<label class="mx-2 switch">
														<input type="checkbox" class="switch-state" name="multiBranches"
															(change)="onChangeMultiBranches($event)"
															formControlName="multiBranches">
														<span class="switch-state"></span>
													</label>
												</div>
											</div>
										</div>
										<hr class="mt-3 mb-3 p-0">
										<div class="col-12 col-md-6 "> 
											<label for=""
												class="label-name ">{{'customer.number-companies'|translate}}</label>
											<input class="form-control" id="numberOfCompany" type="number"
												name="numberOfCompany" formControlName="numberOfCompany">
										</div>
										<div class="col-12 col-md-6 ">
											<label for=""
												class="label-name ">{{'customer.number-branches'|translate}}</label>
											<input class="form-control " id="numberOfBranch" type="number"
												name="numberOfBranch" formControlName="numberOfBranch">
										</div>
										<!-- <div *ngFor="let choice of checksApplications; let i=index" class="col-6 col-md-2">
                                            <label>
                                              <input type="checkbox" [checked]="choice.check" [value]="choice.value" (change)="onCheckChange(choice.value)">

                                              {{lang=="ar"?choice.descriptionAr:choice.descriptionEn}}
                                            </label>
                                          </div> -->
										<hr class="mt-3 mb-3 p-0">
										<div class="col-md-6 col-12 d-flex align-items-end">
											<div class="media">
												<label class="label-name"> {{'general.status'|translate}}
												</label>
												<div class="icon-state media-body mx-5">
													<label class="mx-2 switch">
														<input type="checkbox" class="form-control" name="isActive"
															formControlName="isActive">
														<span class="switch-state"></span>
													</label>
												</div>
											</div>
										</div>

									</div>

								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>


		<button (click)="doNewEvent()" [disabled]="id==0" class="btn btn-primary" type="button">
			<i class="icon-plus"></i>

			{{ 'customer.newSubscription'|translate}}

		</button>
		<!-- <button (click)="doUpdateEvent()" [disabled]="disabledUpate" [ngClass]="{'disabled':disabledUpate}" class="k-button k-button-md k-button-solid-base k-rounded-md " type="button">
        <i data-feather="refresh-cw"></i>
            <span class="k-button-text">
              {{ 'toolbar.update'|translate}}
            </span>
        </button> -->
		<!-- <button  (click)="doDeleteEvent();"  [disabled]="disabledDlete" [ngClass]="{'disabled':disabledDlete}" class="k-button k-button-md k-button-solid-base k-rounded-md" type="button">
        <i data-feather="x-square"></i>
          <span class="k-button-text">
                {{ 'toolbar.delete'|translate}}
          </span>
      </button> -->
		<div id="ex-table-div"></div>
	</div>
</section>