<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading'|translate}}... </p>
</ngx-spinner>
<section class="inner-page-wrapper inner-page-wrapper-units">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-3">
					<div class="card-body">
						<form [formGroup]="moduleForm" id="BusinessSubmit" class="tabs-form">
							<div class="main-customer">
								<div class="wrapper-title">
									<h3>{{'modules.Module-information'|translate}}</h3>
									<hr>
									<div class="row align-items-center">
										<div class="col-md-6 col-12">
											<label for="nameAr" class="label-name">
												{{'general.nameAr'|translate}}</label><span class="text-danger">*</span>
											<div class="form-group position-relative">
 												<input type="text" class="form-control icon-user"
													[ngClass]="{'is-invalid':f['nameAr'].errors || f['nameAr'].invalid}"
													name="nameAr" formControlName="nameAr" disabled>
												<div *ngIf="(f['nameAr'].touched || f['nameAr'].dirty ) && f['nameAr'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['nameAr'].errors && f['nameAr'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>
										<div class="col-md-6 col-12">
											<label for="nameEn"
												class="label-name">{{'general.nameEn'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="nameEn"
												[ngClass]="{'is-invalid':f['nameEn'].errors || f['nameEn'].invalid}"
												formControlName="nameEn" disabled>
												<div *ngIf="(f['nameEn'].touched || f['nameEn'].dirty ) && f['nameEn'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['nameEn'].errors && f['nameEn'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>
										<div class="col-md-6 col-12" *ngIf="f['monthlySubscriptionPrice'].value != null">
											<label for="monthlySubscriptionPrice"
												class="label-name">{{'modules.MonthlySubscriptionPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="monthlySubscriptionPrice"
												[ngClass]="{'is-invalid':f['monthlySubscriptionPrice'].errors || f['monthlySubscriptionPrice'].invalid}"
												formControlName="monthlySubscriptionPrice">
												<div *ngIf="(f['monthlySubscriptionPrice'].touched || f['monthlySubscriptionPrice'].dirty ) && f['monthlySubscriptionPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['monthlySubscriptionPrice'].errors && f['monthlySubscriptionPrice'].value == null"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>
										<div class="col-md-6 col-12"  *ngIf="f['yearlyQuarterSubscriptionPrice'].value != null">
											<label for="yearlyQuarterSubscriptionPrice"
												class="label-name">{{'modules.YearlyQuarterSubscriptionPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="yearlyQuarterSubscriptionPrice"
												[ngClass]="{'is-invalid':f['yearlyQuarterSubscriptionPrice'].errors || f['yearlyQuarterSubscriptionPrice'].invalid}"
													formControlName="yearlyQuarterSubscriptionPrice">
												<div *ngIf="(f['yearlyQuarterSubscriptionPrice'].touched || f['yearlyQuarterSubscriptionPrice'].dirty ) && f['yearlyQuarterSubscriptionPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['yearlyQuarterSubscriptionPrice'].errors && f['yearlyQuarterSubscriptionPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12"  *ngIf="f['yearlyHalfSubscriptionPrice'].value != null">
											<label for="yearlyHalfSubscriptionPrice"
												class="label-name">{{'modules.YearlyHalfSubscriptionPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="yearlyHalfSubscriptionPrice"
												[ngClass]="{'is-invalid':f['yearlyHalfSubscriptionPrice'].errors || f['yearlyHalfSubscriptionPrice'].invalid}"
													formControlName="yearlyHalfSubscriptionPrice">
												<div *ngIf="(f['yearlyHalfSubscriptionPrice'].touched || f['yearlyHalfSubscriptionPrice'].dirty ) && f['yearlyHalfSubscriptionPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['yearlyHalfSubscriptionPrice'].errors && f['yearlyHalfSubscriptionPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12"  *ngIf="f['yearlySubscriptionPrice'].value != null">
											<label for="yearlySubscriptionPrice"
												class="label-name">{{'modules.YearlySubscriptionPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="yearlySubscriptionPrice"
												[ngClass]="{'is-invalid':f['yearlySubscriptionPrice'].errors || f['yearlySubscriptionPrice'].invalid}"
													formControlName="yearlySubscriptionPrice">
												<div *ngIf="(f['yearlySubscriptionPrice'].touched || f['yearlySubscriptionPrice'].dirty ) && f['yearlySubscriptionPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['yearlySubscriptionPrice'].errors && f['yearlySubscriptionPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>
										<div class="col-md-6 col-12"  *ngIf="f['fullBuyingSubscriptionPrice'].value != null">
											<label for="fullBuyingSubscriptionPrice"
												class="label-name">{{'modules.FullBuyingSubscriptionPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="fullBuyingSubscriptionPrice"
												[ngClass]="{'is-invalid':f['fullBuyingSubscriptionPrice'].errors || f['fullBuyingSubscriptionPrice'].invalid}"
													formControlName="fullBuyingSubscriptionPrice">
												<div *ngIf="(f['fullBuyingSubscriptionPrice'].touched || f['fullBuyingSubscriptionPrice'].dirty )
												     && f['fullBuyingSubscriptionPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['fullBuyingSubscriptionPrice'].errors && f['fullBuyingSubscriptionPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>
										<div class="col-md-6 col-12" *ngIf="f['otherUserMonthlySubscriptionPrice'].value != null">
											<label for="otherUserMonthlySubscriptionPrice"
												class="label-name">{{'modules.OtherUserMonthlySubscriptionPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="otherUserMonthlySubscriptionPrice"
												[ngClass]="{'is-invalid':f['otherUserMonthlySubscriptionPrice'].errors || f['otherUserMonthlySubscriptionPrice'].invalid}"
													formControlName="otherUserMonthlySubscriptionPrice">
												<div *ngIf="(f['otherUserMonthlySubscriptionPrice'].touched || f['otherUserMonthlySubscriptionPrice'].dirty )
												     && f['otherUserMonthlySubscriptionPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['otherUserMonthlySubscriptionPrice'].errors && f['otherUserMonthlySubscriptionPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12"  *ngIf="f['otherUserYearlyQuarterSubscriptionPrice'].value != null">
											<label for="otherUserYearlyQuarterSubscriptionPrice"
												class="label-name">{{'modules.OtherUserYearlyQuarterSubscriptionPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">
												<input type="text" class="form-control icon-user" name="otherUserYearlyQuarterSubscriptionPrice"
												[ngClass]="{'is-invalid':f['otherUserYearlyQuarterSubscriptionPrice'].errors || f['otherUserYearlyQuarterSubscriptionPrice'].invalid}"
													formControlName="otherUserYearlyQuarterSubscriptionPrice">
												<div *ngIf="(f['otherUserYearlyQuarterSubscriptionPrice'].touched || f['otherUserYearlyQuarterSubscriptionPrice'].dirty )
												     && f['otherUserYearlyQuarterSubscriptionPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['otherUserYearlyQuarterSubscriptionPrice'].errors && f['otherUserYearlyQuarterSubscriptionPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12"  *ngIf="f['otherUserYearlyHalfSubscriptionPrice'].value != null">
											<label for="otherUserYearlyHalfSubscriptionPrice"
												class="label-name">{{'modules.otherUserYearlyHalfSubscriptionPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">
												<input type="text" class="form-control icon-user" name="otherUserYearlyHalfSubscriptionPrice"
												[ngClass]="{'is-invalid':f['otherUserYearlyHalfSubscriptionPrice'].errors || f['otherUserYearlyHalfSubscriptionPrice'].invalid}"
													formControlName="otherUserYearlyHalfSubscriptionPrice">
												<div *ngIf="(f['otherUserYearlyHalfSubscriptionPrice'].touched || f['otherUserYearlyHalfSubscriptionPrice'].dirty )
												     && f['otherUserYearlyHalfSubscriptionPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['otherUserYearlyHalfSubscriptionPrice'].errors && f['otherUserYearlyHalfSubscriptionPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>

										<div class="col-md-6 col-12"  *ngIf="f['otherUserYearlySubscriptionPrice'].value != null">
											<label for="otherUserYearlySubscriptionPrice"
												class="label-name">{{'modules.OtherUserYearlySubscriptionPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="otherUserYearlySubscriptionPrice"
												[ngClass]="{'is-invalid':f['otherUserYearlySubscriptionPrice'].errors || f['otherUserYearlySubscriptionPrice'].invalid}"
													formControlName="otherUserYearlySubscriptionPrice">
												<div *ngIf="(f['otherUserYearlySubscriptionPrice'].touched || f['otherUserYearlySubscriptionPrice'].dirty )
												     && f['otherUserYearlySubscriptionPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['otherUserYearlySubscriptionPrice'].errors && f['otherUserYearlySubscriptionPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>
										<div class="col-md-6 col-12" *ngIf="f['otherUserFullBuyingSubscriptionPrice'].value != null">
											<label for="otherUserFullBuyingSubscriptionPrice"
												class="label-name">{{'modules.OtherUserFullBuyingSubscriptionPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="text" class="form-control icon-user" name="otherUserFullBuyingSubscriptionPrice"
												[ngClass]="{'is-invalid':f['otherUserFullBuyingSubscriptionPrice'].errors || f['otherUserFullBuyingSubscriptionPrice'].invalid}"
													formControlName="otherUserFullBuyingSubscriptionPrice">
												<div *ngIf="(f['otherUserFullBuyingSubscriptionPrice'].touched || f['otherUserFullBuyingSubscriptionPrice'].dirty )
												     && f['otherUserFullBuyingSubscriptionPrice'].invalid"
													class="validation alert-danger position-absolute">
													<i class="fa fa-close "></i>
													<span *ngIf="f['otherUserFullBuyingSubscriptionPrice'].errors && f['otherUserFullBuyingSubscriptionPrice'].errors['required']"
														class="f-12 ng-star-inserted text-white">
														{{'error.required'|translate}}</span>
												</div>

											</div>
										</div>



										<div class="col-md-6 col-12"  *ngIf="f['instrumentPattrenPrice'].value != null">
											<label for="instrumentPattrenPrice"
												class="label-name">{{'modules.instrumentPattrenPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="number" class="form-control icon-user" name="instrumentPattrenPrice"
													formControlName="instrumentPattrenPrice">
											</div>
										</div>





										<div class="col-md-6 col-12"  *ngIf="f['billPattrenPrice'].value != null">
											<label for="billPattrenPrice"
												class="label-name">{{'modules.billPattrenPrice'|translate}}</label><span
												class="text-danger">*</span>
											<div class="form-group position-relative">

												<input type="number" class="form-control icon-user" name="billPattrenPrice"
													formControlName="billPattrenPrice">
											</div>
										</div>


										<div class="col-md-6 col-12 d-flex align-items-end">
											<div class="media">
												<label class="label-name"> {{'modules.IsFree'|translate}}
												</label>
												<div class="icon-state media-body mx-5">
													<label class="mx-2 switch">
														<input type="checkbox" class="form-control" name="isFree"
															formControlName="isFree">
														<span class="switch-state"></span>
													</label>
												</div>
											</div>
										</div>
										<div class="col-md-6 col-12 d-flex align-items-end">
											<div class="media">
												<label class="label-name"> {{'general.status'|translate}}
												</label>
												<div class="icon-state media-body mx-5">
													<label class="mx-2 switch">
														<input type="checkbox" class="form-control" name="isActive"
															formControlName="isActive">
														<span class="switch-state"></span>
													</label>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</form>

					</div>
				</div>
			</div>
		</div>

		<!--
        <div id="ex-table-div"></div> -->

	</div>
</section>
