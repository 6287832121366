import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from 'src/app/administration/authentication/change-password/change-password.component';
import { TechnicalSupportService } from 'src/app/administration/authentication/services/technical-support-service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
declare var google : any;

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  public userName: string;
  public profileImg: 'assets/images/dashboard/profile.jpg';

  constructor(public router: Router,public authService: TechnicalSupportService, private modelService: NgbModal) {
    console.log("12 localstorage", (localStorage.getItem('userName')))
    this.userName = localStorage.getItem('userName');
    if ((localStorage.getItem('user'))) {
      console.log("true");
    } else {
      console.log("NO ");
    }

  }


  logoutFunc() {
   // localStorage.clear();
	//google.accounts.id.disableAutoSelect()
  this.authService.logout().subscribe(
    next => {


      console.log(next);

    },
    error => {

    
      console.log(error)

    }
  )
    this.router.navigate(['/auth/login']);
    localStorage.clear();
  }

  ngOnInit() {
  }

  showModalFrom() {
    const modalRef = this.modelService.open(ChangePasswordComponent);
    modalRef.componentInstance.name = 'World';
  }
}
