<div class="card">
	<div class="card-body">
		<section class="inner-page-wrapper inner-page-auction">
			<div class="container-fluid p-0">
				<angular-slickgrid [gridOptions]="gridCutomizedOptions" [columnDefinitions]="columnCutomizedNames"
					gridId="packageCustomized" [dataset]="datasetCustomized"
					(onClick)="clickCustomized($event.detail.eventData, $event.detail.args)"
					(onAngularGridCreated)="angularGridCustomizedReady($event.detail)">
				</angular-slickgrid>
			</div>
		</section>
	</div>
</div>