<div class="col-md-6 col-12 d-flex align-items-end">
    <div class="media">
        <label [for]="inputId" class="label-name"> {{labelName|translate}}
		</label>
        <div class="icon-state media-body mx-5">
            <label class="mx-2 switch">
				<input type="{{type}}" [checked]="formControlValue" [formControl]="control" [id]="inputId"
					name="{{inputName}}" class="form-control">
				<span class="switch-state"></span>
			</label>
        </div>
    </div>
</div>
