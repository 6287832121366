
import { IPagedListMetaData } from '../../../shared/interfaces/paged-list-meta-data';
export class SettingDto {
    id: number;
    nameAr: string | undefined;
    nameEn: string | undefined;
    code: string | undefined;
    value: string | undefined;
    isActive: boolean | undefined;
    valueType :ValueTypeEnum| undefined;
   
 
}
export class CreateSettingCommand {
    inputDto:SettingDto;
}
export class Setting{
    id: number;
    nameAr: string | undefined;
    nameEn: string | undefined;
    code: string | undefined;
    value: string | undefined;
    isActive: boolean | undefined;
    valueType :ValueTypeEnum| undefined;
   
}
export class EditSettingCommand {
    setting: SettingDto[] = [];
}
export class SettingDtoPageList {
    metadata: IPagedListMetaData;
    items: SettingDto[] | undefined;
}
export class DeleteListSettingCommand {
    ids: number[] | undefined;
}
export enum ValueTypeEnum 
{

    Boolean = 1,
    Intger = 2,
    Fraction = 3,
    text = 4
}
