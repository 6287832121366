import { AddEditPackageComponent } from './packages/add-edit-package/add-edit-package.component';
import { PackagesListComponent } from './packages/packages-list/packages-list.component';
import { AddEditPomoCodeComponent } from './promo-code/add-edit-pomo-code/add-edit-pomo-code/add-edit-pomo-code.component';
import { ModulesComponent } from './modules/modules.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RolesComponent } from './roles/roles.component';
import { AddEditRoleComponent } from './roles/add-edit-role/add-edit-role.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { AddEditUserComponent } from './users/add-edit-user/add-edit-user.component';
import { CustomersListComponent } from './customers/customers-list/customers-list.component';
import { AddEditCustomerComponent } from './customers/add-edit-customer/add-edit-customer.component';
import { PermissionListComponent } from './permission-list/permission-list.component';
import { EditModulesComponent } from './modules/edit-modules/edit-modules.component';
import { PromoCodeListComponent } from './promo-code/promo-code-list/promo-code-list.component';
import { OwnersListComponent } from './owners/owners-list/owners-list.component';
import { AddEditOwnerComponent } from './owners/add-edit-owner/add-edit-owner.component';
import { CashPaymentInfoComponent } from './cash-payment-info/cash-payment-info.component';
import { SubscriptionTypeComponent } from './subscription-type/subscription-type.component';
import { SubscriptionTypeEditorComponent } from './subscription-type/subscription-type-editor/subscription-type-editor.component';
import { OrderModulesComponent } from './order-modules/order-modules.component';

const routes: Routes = [
	{
		path: '',
		children: [

			{ path: 'role', component: RolesComponent },
			{ path: 'role/add-role', component: AddEditRoleComponent },
			{ path: 'role/update-role/:id', component: AddEditRoleComponent },
			{ path: 'user', component: UsersListComponent },
			{ path: 'user/add-user', component: AddEditUserComponent },
			{ path: 'user/update-user/:id', component: AddEditUserComponent },
			// { path: 'customer', component:CustomersListComponent },
			// { path: 'customer/add-customer', component: AddEditCustomerComponent },
			// { path: 'customer/update-customer/:id', component: AddEditCustomerComponent },
			{ path: 'permission', component: PermissionListComponent },
			{ path: 'permission/add-permission', component: PermissionListComponent },
			{ path: 'permission/update-permission/:id', component: PermissionListComponent },
			{ path: 'modules', component: ModulesComponent },
			{ path: 'modules/edit-module/:id', component: EditModulesComponent },
			{ path: 'promo-code', component: PromoCodeListComponent },
			{ path: 'packages', component: PackagesListComponent },
			{ path: 'packages/add-edit-package', component: AddEditPackageComponent },
			{ path: 'packages/add-edit-package/:id', component: AddEditPackageComponent },
			{ path: 'promo-code/add-promo-code', component: AddEditPomoCodeComponent },
			{ path: 'promo-code/edit-promo-code/:id', component: AddEditPomoCodeComponent },
			{ path: 'customer', component: OwnersListComponent },
			{ path: 'customer/add-customer', component: AddEditOwnerComponent },
			{ path: 'customer/update-customer/:id', component: AddEditOwnerComponent },
			{ path: 'cash-payment-info', component: CashPaymentInfoComponent },
			{ path: 'subscription-type', component: SubscriptionTypeComponent },
			{ path: 'subscription-type/subscription-type-edit/:id', component: SubscriptionTypeEditorComponent },
			{ path: 'order-modules', component: OrderModulesComponent },
		]

	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SecurityRoutingModule { }
