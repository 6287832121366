import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

	private userInfo = new BehaviorSubject<any>(null);
	getUserInfo = this.userInfo.asObservable();

	private packageSubsribeInfo = new BehaviorSubject<any>(null);
	getPackageSubsribeInfo = this.packageSubsribeInfo.asObservable();

	private isPackageSubsribe = new BehaviorSubject<boolean>(false);
	getIsPackageSubsribe = this.isPackageSubsribe.asObservable();

	private moduleSubsribeInfo = new BehaviorSubject<any>(null);
	getModuleSubsribeInfo = this.moduleSubsribeInfo.asObservable();

	private isModuleSubsribe = new BehaviorSubject<boolean>(false);
	getIsModuleSubsribe = this.isModuleSubsribe.asObservable();

	private companyListNames = new BehaviorSubject<string[]>([]);
	getCompanyListNames = this.moduleSubsribeInfo.asObservable();

	private isLoggedIn = new BehaviorSubject<boolean>(false);
	getIsLoggedIn = this.isLoggedIn.asObservable();

  constructor() { }

  setIsLoggedIn(islogged : any)
  {
	this.isLoggedIn.next(islogged);
  }


  setUserInfo(userInfo : any)
  {
	this.userInfo.next(userInfo);
  }


  setIsPackageSubsribe(isPackageSubsribe : boolean)
  {
	this.isPackageSubsribe.next(isPackageSubsribe);
  }

  setPackageSubsribeInfo(packageSubsribeInfo : any)
  {
	this.packageSubsribeInfo.next(packageSubsribeInfo);
  }


  setIsModuleSubsribe(isModuleSubsribe : boolean)
  {
	this.isModuleSubsribe.next(isModuleSubsribe);
  }

  setModuleSubsribeInfo(moduleSubsribeInfo : any)
  {
	this.moduleSubsribeInfo.next(moduleSubsribeInfo);
  }

  setCompanyListNames(companyListNames : any)
  {
	this.companyListNames.next(companyListNames);
  }

}
