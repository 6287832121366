import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TechnicalSupportService } from './administration/authentication/services/technical-support-service';
import { UserService } from './shared/common-services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
// import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

	// For Progressbar
	loaders = this.loader.progress$.pipe(
		delay(1000),
		withLatestFrom(this.loader.progress$),
		map(v => v[1]),
	);

	isLogin : boolean= false;

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		private userService: UserService,
		private router: Router, private authService: TechnicalSupportService,
		private loader: LoadingBarService, private translate: TranslateService) {

			translate.setDefaultLang('ar');
			 const storedLang = localStorage.getItem('language');
			 const defaultLang = storedLang || 'ar';
			 translate.setDefaultLang(defaultLang);
			 translate.use(defaultLang);
			 localStorage.setItem('language', defaultLang);


		}
	ngOnInit(): void {

	}




}
