import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CustomerServiceProxy } from '../../services/customer-service'
import { CreateCustomerSubscriptionCommand, EditCustomerSubscriptionCommand } from '../../models/customer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-add-edit-subscriptions',
  templateUrl: './add-edit-subscriptions.component.html',
  styleUrls: ['./add-edit-subscriptions.component.scss']
})
export class AddEditSubscriptionsComponent implements OnInit {
  //#region Main Declarations
  customerForm!: FormGroup;
  currnetUrl;
  public show: boolean = false;
  lang = localStorage.getItem("language")
  addUrl: string = '/security/customer/add-customer';
  updateUrl: string = '/security/customer/update-customer/';
  listUrl: string = '/security/customer';
  response: any;
  errorMessage = '';
  errorClass = '';
  submited: boolean = false;
  checksApplications: { descriptionAr: string; descriptionEn: string; value: string; check: boolean; }[];
  @Input() public customerId;
  @Input() public id: any = 0;
  constructor(
    private customerService: CustomerServiceProxy,
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal
  ) {
    this.definecustomerForm();
  }
  //#endregion

  //#region ngOnInit
  ngOnInit(): void {
    
    this.getApplications();
    this.currnetUrl = this.router.url;
    if (this.id != 0) {
      this.getCustomerSubscription(this.id)
    }
    // this.id = this.customerId
  }

  //#endregion

  //#region ngOnDestroy
  ngOnDestroy() {
    this.subsList.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }
  //#endregion

  //#region Authentications

  //#endregion

  //#region Permissions

  //#endregion

  //#region  State Management
  //#endregion

  //#region Basic Data
  ///Geting form dropdown list data
  definecustomerForm() {
    this.customerForm = this.fb.group({
      id: 0,
      contractStartDate: null,
      contractEndDate: null,
      customerId: this.customerId,
      applications: ""
    });
  }
  getApplications() {
    this.checksApplications = [
      { descriptionAr: 'مبيعات', descriptionEn: 'Sales', value: '1', check: false },
      { descriptionAr: "إدارة علاقات العملاء", descriptionEn: 'CRM', value: '2', check: false },
      { descriptionAr: "رواتب", descriptionEn: 'Payroll', value: '3', check: false },
      { descriptionAr: "مشتريات", descriptionEn: 'Purchase', value: '4', check: false },
      { descriptionAr: "محاسبة", descriptionEn: 'Accounting', value: '5', check: false }
    ];
  }
  applications = [];

  onCheckChange(value) {

    if (!this.applications.includes(value)) {
      this.applications.push(value);
    } else {
      var index = this.applications.indexOf(value);
      if (index > -1) {
        this.applications.splice(index, 1);
      }
    }

  }
  //#endregion
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  //#region CRUD Operations
  getCustomerSubscription(id: any) {
    return new Promise<void>((resolve, reject) => {
    let sub =  this.customerService.getCustomerSubscription(id).subscribe({
        next: (res: any) => {
          resolve();     
          this.customerForm.setValue({
            id: res.response?.id,
            contractStartDate: this.formatDate(Date.parse(res.response.contractStartDate)),
            contractEndDate: this.formatDate(Date.parse(res.response.contractEndDate)),
            customerId: this.customerId,
            applications: res.response?.applications,

          });

          var applicationsEdit = null
          if (res.response?.applications != null && res.response?.applications != undefined) {


            applicationsEdit = [...res.response?.applications.split(",")]

            for (var i = 0; i < this.checksApplications.length; i++) {

              var find = applicationsEdit.includes(this.checksApplications[i].value);

              if (find) {

                this.checksApplications[i].check = true;
                this.onCheckChange(this.checksApplications[i].value)
              }

            }

          }
          console.log(
            'this.customerForm.value set value',
            this.customerForm.value
          );
        },
        error: (err: any) => {
          reject(err);
        },
        complete: () => {
          console.log('complete');
        },
      });
      this.subsList.push(sub);

    });
  }


  //#endregion

  //#region Helper Functions

  get f(): { [key: string]: AbstractControl } {
    return this.customerForm.controls;
  }


  //#endregion
  //#region Tabulator
  subsList: Subscription[] = [];
  currentBtnResult;
  save() {
    
    if (this.customerForm.value.id == 0) {
      this.onInsert();
    }
    else {
      this.onUpdate();
    }
  }
  onInsert() {
    
    var inputDto = new CreateCustomerSubscriptionCommand()
    if (this.customerForm.valid) {
      const promise = new Promise<void>((resolve, reject) => {
        if (this.applications.length > 0)
          this.customerForm.value.applications = this.applications.toString();
        inputDto.inputDto = this.customerForm.value;
        inputDto.inputDto.customerId = this.customerId;
        this.customerService.createCustomerSubscription(inputDto).subscribe({
          next: (result: any) => {
            this.spinner.show();
            console.log('result dataaddData ', result);
            this.response = { ...result.response };
            this.definecustomerForm();

            this.submited = false;
            setTimeout(() => {
              this.spinner.hide();
              this.activeModal.close(this.customerId);
              //  navigateUrl(this.listUrl, this.router);
            }, 1000);
          },
          error: (err: any) => {
            reject(err);
          },
          complete: () => {
            console.log('complete');
          },
        });
      });
      return promise;

    } else {

      //  return this.customerForm.markAllAsTouched();
    }
  }


  onUpdate() {
    
    if (this.customerForm.valid) {
      var inputDto = new EditCustomerSubscriptionCommand()
      // this.customerForm.value.id = this.id;
      console.log("this.VendorCommissionsForm.value", this.customerForm.value)
      const promise = new Promise<void>((resolve, reject) => {
        this.customerForm.value.applications = this.applications.toString()
        inputDto.inputDto = this.customerForm.value;
        inputDto.inputDto.customerId = this.customerId;
        inputDto.inputDto.id = this.id;
        this.customerService.updateCustomerSubscription(inputDto).subscribe({
          next: (result: any) => {
            this.spinner.show();
            console.log('result update ', result);
            this.response = { ...result.response };
            this.definecustomerForm();
            this.submited = false;
            setTimeout(() => {
              this.spinner.hide();
              this.activeModal.close(this.customerId);
             
              // navigateUrl(this.updateUrl, this.router);
            }, 1000);
          },
          error: (err: any) => {
            reject(err);
          },
          complete: () => {
            console.log('complete');
          },
        });
      });
      return promise;
    }

    else {

      // return this.customerForm.markAllAsTouched();
    }
  }

  //#endregion
}

