import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {

	private _isLoading = new BehaviorSubject<boolean>(true);

	getLoader = this._isLoading.asObservable();
	loading() {
	  this._isLoading.next(true);
	}

	loaded() {
	  this._isLoading.next(false);
	}

	get isLoading() {
	  return this._isLoading;
	}

}
