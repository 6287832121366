import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppLoaderService } from '../../services/AppLoader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

public show: boolean = true;

  constructor(public appLoaderService: AppLoaderService) {
	 appLoaderService.isLoading.subscribe(
		(element : boolean)=>{
			debugger;
			this.show = element;
			if(this.show)
				{
					setTimeout(() => {
						this.show = false;
					  }, 3000);
				}
		}
	)

  }

  ngOnInit() {  }

  ngOnDestroy() { }

}
