import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExcelExportService } from '@slickgrid-universal/excel-export';
import { AngularGridInstance, GridOption, Column, FieldType, Formatters } from 'angular-slickgrid';

@Component({
  selector: 'app-custompackages',
  templateUrl: './custompackages.component.html',
  styleUrls: ['./custompackages.component.scss']
})
export class CustompackagesComponent implements OnInit {
	datasetCustomized : any[] = [];
	angularCustomizedGrid : AngularGridInstance;
	gridCutomizedOptions : GridOption;
	isAutoEdit : boolean  = true;
	columnCutomizedNames : Column []= [];
	lang = localStorage.getItem("language");
  constructor(	private translate : TranslateService,) { }

  ngOnInit(): void {

	this.prepareCustomizedGrid();
  }


  defineCustomzedGrid()
  {
	this.columnCutomizedNames = [];
	this.gridCutomizedOptions = {
		asyncEditorLoading: true,
		autoEdit: this.isAutoEdit,
		autoCommitEdit: true,
		autoResize: {
		  container: '#grid-container',
		  rightPadding: 25
		},
		createPreHeaderPanel: true,
		showPreHeaderPanel: true,
		preHeaderPanelHeight: 40,
		cellHighlightCssClass: 'changed',
		gridWidth: "auto",
		editable: true,
		enableCellNavigation: true,
		enableColumnPicker: true,
		enableFiltering: true,
		enableExcelExport: true,
		enableExcelCopyBuffer: true,
		excelExportOptions: {
				  exportWithFormatter: true,
				  customColumnWidth: 20,
				  // you can customize how the header titles will be styled (defaults to Bold)
				  columnHeaderStyle: { font: { bold: true, italic: true } }
			  },

		registerExternalResources: [new ExcelExportService()],
		enableTranslate: true,
		i18n:this.translate,

	  };

  }

  prepareCustomizedGrid()
  {
	  this.defineCustomzedGrid();
	  let packageName = this.translate.instant("packages.package-name");
	  let monthly = this.translate.instant("packages.monthly-price");
	  let yearly = this.translate.instant("packages.yearly-price");
	  let fullprice = this.translate.instant("packages.full-price");
	  let deletebtn = this.translate.instant("buttons.delete");
	  let edit = this.translate.instant("buttons.edit");
		this.columnCutomizedNames = [
		  {
			 id: "id",
			 name: "#",
			 field:"id",
			 filterable: false,
			 sortable: false,
			 type: FieldType.string,
			 formatter : Formatters.center,
			 minWidth: 15,
		   },
		   {
			  id: this.lang == 'ar' ? "nameAr" : "nameEn",
			  name: packageName,
			  field:this.lang == 'ar' ? "nameAr" : "nameEn",
			  filterable: true,
			  sortable: true,
			  type: FieldType.string,
			  formatter : Formatters.center,
			  minWidth: 175,
			},
			{
			  id: "monthlyPrice",
			  name: monthly,
			  field:"monthlyPrice",
			  filterable: true,
			  sortable: true,
			  type: FieldType.number,
			  formatter : Formatters.center,
			  minWidth: 175,
			},
			{
			  id: "yearlyPrice",
			  name: yearly,
			  field:"yearlyPrice",
			  filterable: true,
			  sortable: true,
			  type: FieldType.number,
			  formatter : Formatters.center,
			  minWidth: 175,
			},
			{
			  id: "fullBuyPrice",
			  name: fullprice,
			  field:"fullBuyPrice",
			  filterable: true,
			  sortable: true,
			  type: FieldType.number,
			  formatter : Formatters.center,
			  minWidth: 175,
			},
			{
			  id: "edit",
			  name: edit,
			  field:"id",
			  filterable: false,
			  sortable: false,
			  type: FieldType.string,
			  formatter : Formatters.editIcon,
			  minWidth: 15,
			},
			{
			  id: "delete",
			  name: deletebtn,
			  field:"id",
			  filterable: false,
			  sortable: false,
			  type: FieldType.string,
			  formatter : Formatters.deleteIcon,
			  minWidth: 15,
			},
	   ]
  }
  clickCustomized(e:any , args : any)
  {

  }

  angularGridCustomizedReady(angularGrid: AngularGridInstance) {
	this.angularCustomizedGrid = angularGrid;
  }
}
