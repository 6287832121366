<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
	<p style="color: white ;font-size: 20px;"> {{'general.loading' | translate}}... </p>
  </ngx-spinner>
  
  <section class="inner-page-wrapper inner-page-wrapper-units">
	<div class="container-fluid">
	  <div class="row">
		<div class="col-md-12">
		  <div class="card mb-3">
			<div class="card-body">
			  <mat-tab-group>
				<mat-tab label="{{ 'component-names.cash-payment-info' | translate }}">
				  <form [formGroup]="cashPaymentInfoForm">
					<div class="main-customer">
					  <div class="wrapper-title">
						<hr>
						<div class="row align-items-center">
						  <div class="col-md-6 col-12">
							<label for="" class="label-name">{{ 'payment-info.phoneNumber' | translate }}</label>
							
							<div class="form-group position-relative">
							  <input type="text" formControlName="phoneNumber" class="form-control" />
							</div>
						  </div>
						  <div class="col-md-6 col-12">
							<label for="" class="label-name">{{ 'payment-info.mobileNumber' | translate }}</label>
							
							<div class="form-group">
							  <input class="form-control" type="text" formControlName="mobileNumber" />
							</div>
						  </div>
						  <div class="col-md-6 col-12">
							<label for="" class="label-name">{{ 'payment-info.companyAddress' | translate }}</label>
							
							<div class="form-group">
							  <input class="form-control" type="text" formControlName="companyAddress" />
							</div>
						  </div>
						  <div class="col-md-6 col-12">
							<label for="" class="label-name">{{ 'payment-info.email' | translate }}</label>
							
							<div class="form-group">
							  <input class="form-control" type="text" formControlName="email" />
							</div>
						  </div>
						</div>
					  </div>
					</div>
				  </form>
				</mat-tab>
				<mat-tab label="{{ 'payment-info.transferAccountInfo' | translate }}">
				  <form [formGroup]="cashPaymentInfoForm">
					<div class="main-customer">
					  <div class="wrapper-title">
						<hr>
						<div class="row align-items-center">
						  <div class="col-md-6 col-12">
							<label for="" class="label-name">{{ 'payment-info.bankNameEn' | translate }}</label>
							
							<div class="form-group">
							  <input class="form-control" type="text" formControlName="bankNameEn" />
							</div>
						  </div>
						  <div class="col-md-6 col-12">
							<label for="" class="label-name">{{ 'payment-info.bankNameAr' | translate }}</label>
							
							<div class="form-group">
							  <input class="form-control" type="text" formControlName="bankNameAr" />
							</div>
						  </div>
						  <div class="col-md-6 col-12">
							<label for="" class="label-name">{{ 'payment-info.iban' | translate }}</label>
							
							<div class="form-group">
							  <input class="form-control" type="text" formControlName="iban" />
							</div>
						  </div>
						  <div class="col-md-6 col-12">
							<label for="" class="label-name">{{ 'payment-info.bankAccountNumber' | translate }}</label>
							
							<div class="form-group">
							  <input class="form-control" type="text" formControlName="bankAccountNumber" />
							</div>
						  </div>
						  <div class="col-md-6 col-12">
							<label for="" class="label-name">{{ 'payment-info.bankAccountNameAr' | translate }}</label>
							
							<div class="form-group">
							  <input class="form-control" type="text" formControlName="bankAccountNameAr" />
							</div>
						  </div>
						  <div class="col-md-6 col-12">
							<label for="" class="label-name">{{ 'payment-info.bankAccountNameEn' | translate }}</label>
							
							<div class="form-group">
							  <input class="form-control" type="text" formControlName="bankAccountNameEn" />
							</div>
						  </div>
						</div>
					  </div>
					</div>
				  </form>
				</mat-tab>
			  </mat-tab-group>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </section>
  