import { SubscriptionTypeService } from './../services/subscription-type.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AngularGridInstance, Column, FieldType, Formatters, GridOption } from 'angular-slickgrid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/common-services/shared-service';
import { SettingMenuShowOptions } from 'src/app/shared/components/models/setting-menu-show-options';
import { ToolbarActions } from 'src/app/shared/enum/toolbar-actions';
import { ToolbarData } from 'src/app/shared/interfaces/toolbar-data';
import { ToolbarPath } from 'src/app/shared/interfaces/toolbar-path';

@Component({
  selector: 'app-subscription-type',
  templateUrl: './subscription-type.component.html',
  styleUrls: ['./subscription-type.component.scss']
})
export class SubscriptionTypeComponent implements OnInit {
	currnetUrl: any;
	updateUrl: string = '/security/subscription-type/update-module/';
	listUrl: string = '/security/subscription-type';
	toolbarPathData: ToolbarPath = {
	  listPath: '',
	  addPath : '',
	  updatePath: this.updateUrl,
	  componentList:this.translate.instant("component-names.subscription-type"),
	  componentAdd: '',

	};
	sortByCols: any[] = [];
	groupByCols: string[] = [];
	direction: string = 'ltr';
	lang = localStorage.getItem("language");
	menuOptions: SettingMenuShowOptions = {
		showDelete: false,
		showEdit: true,};
	panelId: number = 1;
	searchFilters: any;
	currentBtn!: string;
    subsList: Subscription[] = [];
     //for slick grid prepartion
     columnNames : Column []= [];
	 gridOptions!: GridOption;
	 isAutoEdit : boolean  = true;
	 updatedObject: any;
	 angularGrid!: AngularGridInstance;
	 subscriptionType: any[] = [];

 constructor(
	private translate : TranslateService,
	private router: Router,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
	private subscriptionTypeService : SubscriptionTypeService ,
	private appToaster : ToastrService
  ) { }

  ngOnDestroy() {
    this.subsList.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  ngAfterViewInit(): void {

  }


  ngOnInit(): void {
	this.spinner.show();
	this.sharedService.changeButton({ action: 'ListOnly' } as ToolbarData);
	this.sharedService.changeToolbarPath(this.toolbarPathData);
	this.listenToClickedButton();
	Promise.all([this.prepareGird()])
      .then(a => {
		  this.spinner.hide();
		 }).catch(err => {
        this.spinner.hide();
      })
  }

  prepareGird()
  {
	this.gridOptions = {
		asyncEditorLoading: true,
		autoEdit: this.isAutoEdit,
		autoCommitEdit: true,
		autoResize: {
		  container: '#subscriptionPriceGrid',
		  rightPadding: 25
		},
		createPreHeaderPanel: true,
		showPreHeaderPanel: true,
		preHeaderPanelHeight: 40,
		cellHighlightCssClass: 'changed',
		gridWidth: "auto",
		editable: true,
		enableCellNavigation: true,
		enableColumnPicker: true,
		enableFiltering: true,
		enableExcelExport: true,
		enableExcelCopyBuffer: true,
		excelExportOptions: {
				  exportWithFormatter: true,
				  customColumnWidth: 20,
				  // you can customize how the header titles will be styled (defaults to Bold)
				  columnHeaderStyle: { font: { bold: true, italic: true } }
			  },
		enableTranslate: true,
		i18n:this.translate,

	  };
	 this.getSubscriptionType()

  }


  getSubscriptionType()
  {
	return new Promise<void>((resolve, reject) => {
		let sub = this.subscriptionTypeService.getAllSubscriptionTypes().subscribe({
		  next: (res) => {

			this.toolbarPathData.componentList =this.translate.instant("component-names.subscription-type");
			if (res.success) {
			  this.subscriptionType = res.response;
			  let activate = this.translate.instant("modules.activate")
			  let active = this.translate.instant("modules.active")
			  let nonactive = this.translate.instant("modules.nonactive")
			  let nameAr = this.translate.instant("subscription-type.nameAr")
			  let nameEn = this.translate.instant("subscription-type.nameAr")
			  let period = this.translate.instant("subscription-type.period")
			  let isActive = this.translate.instant("subscription-type.isActive")
			  this.columnNames = [
				  {
					 id: "id",
					 name: "#",
					 field:"code",
					 filterable: false,
					 sortable: false,
					 type: FieldType.string,
					 formatter : Formatters.center,
					 minWidth: 15,
					 maxWidth:50
				   },
				   {
					  id: this.lang == 'ar' ? "nameAr" : "nameEn",
					  name: this.lang == 'ar' ? nameAr : nameEn,
					  field:this.lang == 'ar' ? "nameAr" : "nameEn",
					  filterable: true,
					  sortable: true,
					  type: FieldType.string,
					  formatter : Formatters.center,
					  minWidth: 200,
					  maxWidth :250
					},
					{
						id: "isActive",
						name: isActive,
						field: "isActiveText",
						filterable: false,
						sortable: false,
						type: FieldType.string,
						cssClass:'text-center',
						minWidth: 200,
						maxWidth:250
					},
					{
						id: "edit",
						name: activate,
						field: "id",
						filterable: false,
						sortable: false,
						formatter : ()=> `<span><li class="fa fa-refresh"></li></span>` ,
						type: FieldType.string,
						cssClass:'text-center',
						minWidth: 100,
						maxWidth:100
					  }

			   ]

			  for (let index = 0; index < this.subscriptionType.length; index++) {
				this.subscriptionType[index].code= index+1;
				debugger;
				if(this.subscriptionType[index].isActive == true)
					{
						this.subscriptionType[index].isActiveText  = active


					}
				else
					{
						this.subscriptionType[index].isActiveText =  nonactive

					}

			  }

			}
			resolve();
		  },
		  error: (err: any) => {
			reject(err);
		  },
		  complete: () => {
			console.log('complete');
		  },
		});
		this.subsList.push(sub);
	  });

  }


  listenToClickedButton()
  {

	let sub = this.sharedService.getClickedbutton().subscribe({
		next: (currentBtn: ToolbarData) => {
		  //currentBtn;
		  if (currentBtn != null) {
			if (currentBtn.action == ToolbarActions.List) {

			} else if (currentBtn.action == ToolbarActions.Update) {
			  this.router.navigate([this.updateUrl]);
			}
		  }
		},
	  });
	  this.subsList.push(sub);
  }


  onEdit(event : any)
  {

    if (event != undefined) {
		this.edit(event);
		this.sharedService.changeButton({
		  action: 'Update',
		  componentName: 'List',
		  submitMode: false
		} as ToolbarData);

		this.sharedService.changeToolbarPath(this.toolbarPathData);
		this.router.navigate(['/security/subscription-type/subscription-type-edit' + event]
		)
	  }
  }

  edit(id: string) {
    this.router.navigate([
      '/security/subscription-type/subscription-type-edit',
      id,
    ]);
  }

  editRow(e:any , args : any)
  {
	let cellType = this.columnNames[args.cell].id + "" ?? "";
	    if (cellType == "edit") {
			let id = this.subscriptionType[args.row].id+'' as string;
			let sub = this.subscriptionTypeService.updateSubscriptionType(Number.parseInt(id)).subscribe({
				next: (result: any) => {
				 this.appToaster.success(this.translate.instant('editSuccessfully'))
				 this.getSubscriptionType();
				},
				error: (err: any) => {
				},
				complete: () => {
				  console.log('complete');
				},
			  });
			  this.subsList.push(sub);

		}
  }

  onSearchTextChange(searchTxt: string) {
    this.searchFilters = [
      [
        { field: 'nameEn', type: 'like', value: searchTxt },
        { field: 'nameAr', type: 'like', value: searchTxt },
        { field: 'code', type: 'like', value: searchTxt },
        ,
      ],
    ];
  }

  angularGridReady(angularGrid: AngularGridInstance) {
	this.angularGrid = angularGrid;
	//this.gridObj = angularGrid.slickGrid;
  }

}
