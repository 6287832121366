<div class="container-fluid">
	<div class="row">
		<div class="col-sm-12 col-xl-12">

			<div class="">

				<!-- <ng-template #content let-modal> -->
				<div class="modal-header">
					<h4 class="modal-title" id="modal-basic-title">{{'login.change-password'|translate}}</h4>
					<!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
				</div>
				<div class="modal-body">
					<form [formGroup]="passForm" class="custom-datepicker custom-datepicker-dropdown">
						<!-- <p class="">Your new password must be different from your previous passwords.</p> -->
						<div class="form-group">
							<label for="newPassword" class="label-name">{{'login.old-password'|translate}}</label><span
								_ngcontent-hmt-c223="" class="text-danger">*</span>
							<div class="input-group">
								<input type="password" class="form-control" id="oldPassword"
									formControlName="oldPassword" name="oldPassword" placeholder=""
									[ngClass]="{'error' : (f.newPassword.touched) && (f.oldPassword.errors)}">
								<div *ngIf="(f.oldPassword.touched) && (f.oldPassword.errors)"
									class="validation alert-danger position-absolute">
									<i class="fa fa-close "></i>
									<span
										*ngIf="(f.oldPassword.errors?.required)">{{'validation.required'|translate}}</span>
									<span
										*ngIf="(f.oldPassword.errors.minlength)">{{'validation.invalidPassword'|translate}}</span>

								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="newPassword" class="label-name">{{'login.password'|translate}}</label><span
								_ngcontent-hmt-c223="" class="text-danger">*</span>
							<div class="input-group">
								<input type="password" class="form-control" id="newPassword"
									formControlName="newPassword" name="newPassword" placeholder=""
									[ngClass]="{'error' : (f.newPassword.touched) && (f.newPassword.errors)}">
								<div *ngIf="(f.newPassword.touched) && (f.newPassword.errors)"
									class="validation alert-danger position-absolute">
									<i class="fa fa-close "></i>
									<span
										*ngIf="(f.newPassword.errors?.required)">{{'validation.required'|translate}}</span>
									<span
										*ngIf="(f.newPassword.errors.minlength)">{{'validation.invalidPassword'|translate}}</span>
									<span
										*ngIf="(f.newPassword.errors?.notEquivalent)">{{'validation.password-no-match'|translate}}</span>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="newPassword"
								class="label-name">{{'login.confirm-password'|translate}}</label><span
								_ngcontent-hmt-c223="" class="text-danger">*</span>
							<div class="input-group">
								<input type="password" class="form-control" id="confirmNewPassword" placeholder=""
									formControlName="confirmNewPassword" name="confirmNewPassword"
									[ngClass]="{'error' : (f.confirmNewPassword.touched) && (f.confirmNewPassword.errors)}">
								<div *ngIf="(f.confirmNewPassword.touched) && (f.confirmNewPassword.errors)"
									class="validation alert-danger position-absolute">
									<i class="fa fa-close "></i>
									<span
										*ngIf="(f.confirmNewPassword.errors?.required)">{{'validation.required'|translate}}</span>
									<span
										*ngIf="(f.confirmNewPassword.errors.minlength)">{{'validation.invalidPassword'|translate}}</span>
									<span
										*ngIf="(f.confirmNewPassword.errors?.notEquivalent)">{{'validation.password-no-match'|translate}}</span>
								</div>

							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-outline-dark"> {{'buttons.cancel'|translate}}</button>
					<button type="button" class="btn btn-primary"
						(click)="onSubmit()">{{'buttons.save'|translate}}</button>
				</div>
				<!-- </ng-template> -->


				<!-- <h5 class="p-15">Your new password must be different from your previous passwords</h5>
        <button class="btn btn-lg btn-outline-primary" (click)="open(content)">{{'login.change-password'|translate}}</button> -->

			</div>


		</div>

	</div>
</div>