export enum ToolbarActions {
    List = "List",
    Save = "Save",
	Edit = "Edit" ,
    New = "New",
    Update = "Update",
    Delete = "Delete",
    Copy = "Copy",
    Print = "Print",
    Expory = "Expory",
    Reset = "Reset",
    Cancel = "Cancel",
    DeleteCheckList = "DeleteCheckList"
  }
