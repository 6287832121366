<!--page-wrapper Start-->
<body class="landing-page">

	<div class="page-wrapper">
		<div class="tap-top"><i data-feather="chevrons-up"></i></div>
		<!-- tap on tap ends-->
		<!-- page-wrapper Start-->
		<div class="page-wrapper landing-page">
			<!-- Page Body Start            -->
			<div class="landing-home">

				<div class="container-fluid">
					<app-landing-page-header
					*ngIf="!isLogin"
				   ></app-landing-page-header>
					<router-outlet></router-outlet>
				</div>
			</div>


		</div>


	</div>
</body>
<!--page-wrapper Ends-->
